import React from 'react';
import { LoadingWrapper, Spinner } from './styledComponents';

 const PageSpinner = ({ ...props }) => (
  <LoadingWrapper>
    <Spinner {...props}>
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </Spinner>
  </LoadingWrapper>
);

export default PageSpinner;