import './style.scss';

interface IPreviewButton {
    cssButtonProperty: any;
    isShow : any;
}

const PreviewDesign = (prop: IPreviewButton) => {
    let previewButton = {
        button: {},
    };
 
    let displayShow : string =  "block";
    if(prop.isShow){
        displayShow = "block";
    }else{
        displayShow = "none";
    }

    if (prop.cssButtonProperty) {
        previewButton = {
            button: {},
        };
    } else {
        previewButton = {
            button: {
                width: '30px',
                height: '30px',
                background: '#6259CE no-repeat padding-box',
                boxShadow: '0px 3px 6px #00000029',
                opacity: 1,
                borderRadius: '50%',
                position: 'absolute' as 'absolute',
                bottom: '10px',
                right: '10px',
                cursor: 'pointer',
                display: displayShow
            },
        };
    }

    return (
        <>
            <div className="container-preview">
                <div className="area-preview">
                    <div style={previewButton.button}></div>
                </div>
            </div>
        </>
    );
};
export default PreviewDesign;
