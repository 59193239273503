import { axiosPrivate } from 'services/axios.private';

export class UserApi {
    getUsers = async (page: any, rowsPerPage: any, order: any, orderBy: any, searchContact: string) => {
        const res: any = await axiosPrivate.get(`/api/v1/user/query/users`, {
            params: {
                Page: page,
                SortDirection: order,
                SortColumn: orderBy,
                PageSize: rowsPerPage,
                Search: searchContact
            }
        });

        return res.data;
    };
    getUser = async (id: string | number) => {
        if (!id) return null;
        const res: any = await axiosPrivate.get(`/api/v1/user/query/user/${id}`) 
        .catch(err => {
            return err;
        });
        return res.data;
      };

    createUser = async (formData: any, params: any) => {
        const res: any = await axiosPrivate.post('/api/v1/user/command/create', formData, params) 
        .catch(err => {
            return err;
        });
        return res.data;
      };

    updateUser = async (formData: any, params: any) => {
        const res: any = axiosPrivate.post('/api/v1/user/command/update', formData, params)
        .catch(err => {
            return err;
        });
        return res.data;
      };

    deleteUser = async (id: any) => {
        const res: any = axiosPrivate.delete('/api/v1/user/command/delete/' + id)
        .catch(err => {
            return err;
        });
        return res.data;
      };
}
