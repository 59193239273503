import moment from 'moment';
import { useMemo } from 'react';

export const randomNum = (max = 100): number => Math.floor(Math.random() * max);

export const randomStr = (len: number = 16) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charsLength = chars.length;
    for (let i = 0; i < len; i++) {
        result += chars.charAt(Math.floor(Math.random() * charsLength));
    }
    return result;
};

export const formatDateTime = (date: Date, format: string = 'D MMM YYYY') => {
    return moment(date).format(format);
};

export const generateUUID: any = () => {
    // Public Domain/MIT
    var d: any = new Date().getTime(); //Timestamp
    var d2: any = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16; //random number between 0 and 16
        if (d > 0) {
            //Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            //Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
};

export const toastProperty: any = (
    position: string,
    autoClose: number,
    hideProgressBar: boolean,
    closeOnClick: boolean,
    pauseOnHover: boolean,
    draggable: boolean,
    progress: any,
    theme: string
) => {
    return {
        position: position,
        autoClose: autoClose,
        hideProgressBar: hideProgressBar,
        closeOnClick: closeOnClick,
        pauseOnHover: pauseOnHover,
        draggable: draggable,
        progress: progress,
        theme: theme
    };
};

export const isImageType: any = (imageUrl: any) => {
    if (imageUrl.match(/\.(jpg|jpeg|png|gif)$/)) {
        return true;
    }
    return false;
};

export const replaceMessages = (msg: string, name: string) => {
    var replaceMsg = msg.replace('{name}', name);
    return replaceMsg;
};

export const formDataToJson = (formData: FormData) => {
    var object = {};
    formData.forEach(function (value, key) {
        switch (value) {
            case 'true':
                object[key] = true;
                break;
            case 'false':
                object[key] = false;
                break;
            default:
                object[key] = value;
                break;
        }

        const reg = new RegExp('^[0-9]+$');
        if (reg.test(value.toString())) {
            object[key] = Number(value);
        }
    });

    return object;
};

export const headersContentType: any = () => {
    return { 'Content-Type': 'multipart/form-data' };
};

export const getScoreRecord: any = (sentimentAnalysis: any) => {
    let valSentimentResult: any = '';
    let valSentimentResultScore: any = 0;
    let valSentimentResultPercent: any = 0;
    if (sentimentAnalysis?.Sentiment == 'Positive') {
        valSentimentResult = 'Positive';

        let resultSentimentPositive: any = sentimentAnalysis.SentimentPositive * 5;
        valSentimentResultScore = parseFloat(resultSentimentPositive).toFixed(2);

        valSentimentResultPercent = (valSentimentResultScore * 100) / 5;
    }
    if (sentimentAnalysis?.Sentiment == 'Neutral') {
        valSentimentResult = 'Neutral';
        let resultSentimentNeutral: any = sentimentAnalysis.SentimentNeutral * 3.33;
        valSentimentResultScore = parseFloat(resultSentimentNeutral).toFixed(2);
        valSentimentResultPercent = (valSentimentResultScore * 100) / 5;
    }
    if (sentimentAnalysis?.Sentiment == 'Negative') {
        valSentimentResult = 'Negative';
        let resultSentimentNegative: any = sentimentAnalysis.SentimentNeutral * 1.67;
        valSentimentResultScore = parseFloat(resultSentimentNegative).toFixed(2);
        valSentimentResultPercent = (valSentimentResultScore * 100) / 5;
    }
    if (sentimentAnalysis?.Sentiment == 'Mixed') {
        if (
            sentimentAnalysis.SentimentPositive > sentimentAnalysis.SentimentNeutral &&
            sentimentAnalysis.SentimentPositive > sentimentAnalysis.SentimentNegative
        ) {
            valSentimentResult = 'Positive';
            let resultSentimentPositive: any = sentimentAnalysis.SentimentPositive * 5;
            valSentimentResultScore = parseFloat(resultSentimentPositive).toFixed(2);
            valSentimentResultPercent = (valSentimentResultScore * 100) / 5;
        }
        if (
            sentimentAnalysis.SentimentNeutral > sentimentAnalysis.SentimentPositive &&
            sentimentAnalysis.SentimentNeutral > sentimentAnalysis.SentimentNegative
        ) {
            valSentimentResult = 'Neutral';
            let resultSentimentNegative: any = sentimentAnalysis.SentimentNeutral * 1.67;
            valSentimentResultScore = parseFloat(resultSentimentNegative).toFixed(2);
            valSentimentResultPercent = (valSentimentResultScore * 100) / 5;
        }
        if (
            sentimentAnalysis.SentimentNegative > sentimentAnalysis.SentimentPositive &&
            sentimentAnalysis.SentimentNegative > sentimentAnalysis.SentimentNeutral
        ) {
            valSentimentResult = 'Negative';
            let resultSentimentNegative: any = sentimentAnalysis.SentimentNeutral * 1.67;
            valSentimentResultScore = parseFloat(resultSentimentNegative).toFixed(2);
            valSentimentResultPercent = (valSentimentResultScore * 100) / 5;
        }
    }
    let newR = {
        sentimentResult: valSentimentResult,
        sentimentResultScore: valSentimentResultScore,
        sentimentResultPercent: valSentimentResultPercent
    };
    return newR;
};

export const languageSetting = () => {
    let language: any = [
        { id: 1, value: 'sv-SE', text: 'Swedish (Sweden)', defaultValue: false },
        { id: 2, value: 'en-US', text: 'English (US)', defaultValue: false }
    ];
    return language;
};

export const typeMessagesSetting = () => {
    let typeMessages: any = [
        { id: 0, value: 0, text: 'E-mail', defaultValue: false },
        { id: 1, value: 1, text: 'SMS', defaultValue: false }
    ];
    return typeMessages;
};

export const rolesUser = () => {
    let roles: any = [
        { id: 0, value: 0, text: 'Super Administrator', defaultValue: false },
        { id: 1, value: 1, text: 'Administrator', defaultValue: false },
        { id: 2, value: 2, text: 'Sales Agent', defaultValue: false }
    ];
    return roles;
};

export const columnsUser = () => {
    const columns = useMemo(
        () => [
            {
                header: '',
                accessor: ''
            },
            {
                header: 'Name',
                accessor: 'Firstname'
            },
            {
                header: 'Role',
                accessor: 'UserRole'
            },
            {
                header: 'Phone',
                accessor: 'PhoneNumber'
            },
            {
                header: 'Email',
                accessor: 'Email'
            },
            {
                header: 'Created',
                accessor: 'CreatedDate'
            },
            {
                header: 'Edit',
                accessor: ''
            },
            {
                header: 'Delete',
                accessor: ''
            }
        ],
        []
    );
    return columns;
};

export const insightColumns = () => {
    let header = [
        {
            header: '',
            accessor: '',
            cssClass: ''
        },
        {
            header: 'Name',
            accessor: 'SaleAgentName',
            cssClass: ''
        },
        {
            header: 'Date',
            accessor: 'DateCallRecord',
            cssClass: 'text-center'
        },
        {
            header: 'Start Time',
            accessor: 'StartTime',
            cssClass: 'text-center'
        },
        {
            header: 'End Time',
            accessor: 'EndTime',
            cssClass: 'text-center'
        },
        {
            header: 'Client Name',
            accessor: 'ClientName',
            cssClass: 'text-center'
        },
        {
            header: 'Score Record',
            accessor: '',
            cssClass: 'text-center'
        },
        {
            header: 'Analysis Complete',
            accessor: '',
            cssClass: 'text-center'
        },
        {
            header: 'Details',
            accessor: '',
            cssClass: 'text-center'
        },
        {
            header: 'Delete',
            accessor: '',
            cssClass: 'text-center'
        }
    ];
    return header;
};
