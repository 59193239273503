import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import './style.scss';
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css'
import 'react-datepicker/dist/react-datepicker.css';
import {Icon} from 'components';
import React from 'react';


interface IdatePicker {
    isUpdate: boolean;
    startTime: string;
    endTime: string;
    dateActive: string;
}

const DatePickerOption = (prop: IdatePicker) => {
    const [endDate, setEndDate] =  useState<any>(new Date(prop.dateActive.split("T")[0]));
    const [sTime, setSTime] =  React.useState<any>(moment().add(5,'minutes'));
    const [eTime, setETime] =  React.useState<any>(moment().add(10,'minutes'));
    
    const openCalendarEndDate = () =>{
        let res:any = document.getElementsByClassName('input-end-date');
        res[0].click();
    }

    useEffect(()=>{
        if(prop.isUpdate){
            setSTime(moment(prop.startTime));
            setETime(moment(prop.endTime));
        }
       
    },[prop]);

    useEffect(()=>{
          let st = moment(sTime).add(5,'minutes');
          setETime(st);
    },[sTime]);

    return (
        <>
            <div className="container-date-picker">
            <div className="container-date-picker-option-starttime">
                <div className="start-time">
                    <TimePicker
                        className='start-time'  
                        showSecond={false}
                        value={sTime}
                        defaultValue={sTime}
                        onChange={setSTime}
                        inputReadOnly = {false}
                    />
                </div>
            </div>
               <div className='dash'>-</div>
                <div className="container-date-picker-option-endtime">
                    <div className="end-time">
                        <TimePicker
                        className='end-time'
                        showSecond={false}
                        defaultValue={eTime}
                        value={eTime}
                        onChange={setETime}
                        inputReadOnly= {false}
                    />
                    </div>
                    <div className="end-date">
                        <DatePicker
                            selected={endDate}
                            onChange={(date: any) => setEndDate(date)}
                            dateFormat="dd-MM-yyyy"
                            className={`input-end-date`}
                            calendarStartDay={1}
                        />
                        <div className="icon-calendar" onClick={openCalendarEndDate}><Icon icon='calendar-icon' size={20} color={`#FFF`}/></div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default DatePickerOption;
