import axios from "axios";
import { API_URL } from "./api.service";

class authService {
  constructor(props) {
    // this.isAuth = false;
  }

  login = (username, password) => {
    return axios
      .post(`${API_URL}/api/v1/authentication/login`, {
        grant_type: "password",
        username: username,
        password: password,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: username,
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: username,
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  loginFacebook = (userID, accessToken) => {
    return axios
      .post(`${API_URL}/api/v1/authentication/login-facebook`, {
        userID: userID,
        accessToken: accessToken,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: userID,
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: userID,
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  loginLinkedIn = (accessToken) => {
    return axios
      .post(`${API_URL}/api/v1/authentication/login-linkedin`, {
        accessToken: accessToken,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: " ",
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: "",
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  loginGoogle = (userID, accessToken) => {
    return axios
      .post(`${API_URL}/api/v1/authentication/login-google`, {
        userID: userID,
        accessToken: accessToken,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: " ",
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: " ",
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  signUpFacebook = (userID, accessToken) => {
    return axios
      .post(`${API_URL}/api/v1/account/signup-facebook`, {
        userID: userID,
        accessToken: accessToken,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: userID,
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: userID,
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  signUpLinkedIn = (accessToken) => {
    return axios
      .post(`${API_URL}/api/v1/account/signup-linkedin`, {
        accessToken: accessToken,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: " ",
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: " ",
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  signUpGoogle = (userID, accessToken) => {
    return axios
      .post(`${API_URL}/api/v1/account/signup-google`, {
        userID: userID,
        accessToken: accessToken,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: " ",
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );

          Promise.resolve({
            user: " ",
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
        } else {
          throw new Error("failed to login");
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  loginWithRefreshToken = (refreshToken) => {
    let storedUser = JSON.parse(sessionStorage.getItem("user"));
    return axios
      .post(`${API_URL}/api/v1/authentication/login`, {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        username: storedUser.user,
      })
      .then((res) => {
        if (res.data.access_token) {
          sessionStorage.setItem(
            "user",
            JSON.stringify({
              user: storedUser.user,
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
            })
          );
        } else Promise.reject("Request Not Authenticated!");
      })
      .catch((error) => {
        Promise.reject("Failed to login with request token");
      });
  };

  logout = () => {
    sessionStorage.clear();
    return true;
  };

  getAuthenticationContext = () => {
    return JSON.parse(sessionStorage.getItem("auth"));
  };
}

export default new authService();
