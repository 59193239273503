import { faCalendar, faPaperclip, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { Badge, ButtonGroup, ButtonViewAll, Icon, TimerVisitorCurrent } from 'components';
import { Ivisitor } from 'interfaces/Ivisitor';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useServices } from 'services';
import { BsCloudSlash, BsSend } from 'react-icons/bs';
import './style.scss';
import { ConnectionHubContext, LoginContext, SettingContext, UserContext } from 'contexts';
import { useContactVisitor } from 'hooks/useContactVisitor';
import { BeatLoader, BounceLoader, FadeLoader, PulseLoader, SyncLoader } from 'react-spinners';
import Peer from 'simple-peer';
import { HiOutlinePhoneMissedCall, HiPhoneMissedCall } from 'react-icons/hi';
import { TbUsers } from 'react-icons/tb';
import ReactQuill from 'react-quill';
import useTextEditor from 'hooks/useTextEditor';
import { IoCall, IoClose } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { COLORS, MessageType, SESSIONSTORAGE_NAME } from 'utils/constants';
import { BiBot, BiSupport, BiSolidPhoneCall } from 'react-icons/bi';
import { FaPaperPlane, FaRobot, FaUser, FaUserAlt, FaVideo } from 'react-icons/fa';
import { useSessionStorage } from 'usehooks-ts';

const verticalLineImage = `${process.env.PUBLIC_URL}/assets/images/line-vertical.png`;

const CurrentVisitors = (props) => {
    const { setSelectedClient, setLoading } = props;
    const { api } = useServices();
    const [userContext] = useContext(UserContext);
    const [settingContext] = useContext(SettingContext);

    const [connectionHubContext] = useContext(ConnectionHubContext);
    const { connectionHub, connectionHubId } = connectionHubContext;

    const navigate = useNavigate();

    const [sessionId, setSessionId] = useSessionStorage(SESSIONSTORAGE_NAME.CALL_SESSION_ID, null);
    const [sortItem, setSortItem] = useSessionStorage("sortItem", 0);

    const { modules, formats } = useTextEditor();

    const SortingOption = {
        Trigger: "Trigger",
        Latest: "Latest",
        Longest: "Longest",
    };
    const visitorSortItems = [
        { text: 'Trigger', value: SortingOption.Trigger },
        { text: 'Latest', value: SortingOption.Latest },
        { text: 'Longest', value: SortingOption.Longest },
    ];

    const [currentVisitors, setCurrentVisitors] = useState([]);
    const [selectedVisitor, setSelectedVisitor] = useState(null);
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [lastSeen, setLastSeen] = useState('');
    const [timer, setTimer] = useState('00:00');

    // const [messages, setMessages] = useState([]);
    const [messageBody, setMessageBody] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [uploading, setUploading] = useState(false);

    const {
        contactType,
        visitorHubId,
        handleVideoCall,
        handleChat,
        calling,
        setCalling,
        chatRequest,
        setChatRequest,
        handleCancelRequest
    } = useContactVisitor();

    const localStream = useRef(null);
    const peerAgent = useRef();
    const localVideoRef = useRef();

    const inputFileRef = useRef(null);
    const messageContainerRef = useRef(null);

    const [waitingContactVisitor, setWaitingContactVisitor] = useState(false);
    const [messageStatusVisitor, setMessageStatusVisitor] = useState();
    const [showCloseButton, setShowCloseButton] = useState(false);
    const [sendingMessage, setSendingMessage] = useState(false);

    const slideAnime = async (setOptions) => {
        const typeSlideDown = 'slideDown';
        const typeSlideUp = 'slideUp';
        const typeSlideToggle = 'slideToggle';

        const defaultOptions = {
            target: false,
            animeType: typeSlideToggle,
            duration: 1000,
            easing: 'ease',
            displayStyle: 'flex',
            callBack: null
        };

        const options = Object.assign({}, defaultOptions, setOptions);
        const target = options.target;

        if (!target) {
            return;
        }

        let animeType = options.animeType;

        const styles = getComputedStyle(target);
        const textNone = 'none';
        const isDisplayNone = styles.display === textNone;
        if (animeType === typeSlideToggle) {
            animeType = isDisplayNone ? typeSlideDown : typeSlideUp;
        }

        const busyClass = 'is-slide-busy';
        const targetClassList = target.classList;
        const isSlideDown = animeType === typeSlideDown;
        const isSlideUp = animeType === typeSlideUp;
        const isBusy = targetClassList.contains(busyClass);

        if (
            (isSlideUp && (isDisplayNone || isBusy)) ||
            (isSlideDown && (!isDisplayNone || isBusy)) ||
            (!isSlideUp && !isSlideDown)
        ) {
            return;
        }

        targetClassList.add(busyClass);

        const targetStyle = target.style;
        targetStyle.overflow = 'hidden';

        const displayStyle = options.displayStyle;
        if (isSlideDown) {
            targetStyle.display = displayStyle;
        }

        const heightVal = {
            height: target.getBoundingClientRect().height + 'px',
            marginTop: styles.marginTop,
            marginBottom: styles.marginBottom,
            paddingTop: styles.paddingTop,
            paddingBottom: styles.paddingBottom
        };
        const styleKeys = Object.keys(heightVal);

        styleKeys.forEach((key) => {
            if (parseFloat(heightVal[key]) === 0) {
                delete heightVal[key];
            }
        });

        if (styleKeys.length === 0) {
            return;
        }

        if (isSlideDown) {
            styleKeys.forEach((key) => {
                targetStyle[key] = 0;
            });
        } else {
            styleKeys.forEach((key) => {
                targetStyle[key] = heightVal[key];
                heightVal[key] = 0;
            });
        }
        await target.animate(heightVal, {
            duration: options.duration,
            easing: options.easing
        }).finished;

        targetClassList.remove(busyClass);

        targetStyle.overflow = '';
        styleKeys.forEach((key) => {
            targetStyle[key] = '';
        });

        if (isSlideUp) {
            targetStyle.display = textNone;
        }

        const callBack = options.callBack;
        if (typeof callBack === 'function') {
            callBack();
        }
    };

    // const openVisitorPanel = (visitor, toggleElm) => {
    //     const slideToggleElm = document.querySelector('.session-view-container');
    //     slideAnime({
    //         target: slideToggleElm,
    //         animeType: 'slideToggle',
    //         callBack: () => {
    //             // console.log('slideToggle終了');
    //             setSelectedClient(visitor);
    //         }
    //     });
    // };


    const handleVisitorSorting = (sortOptions, userList) => {
        console.log("sortOptions in handler ", sortOptions)
        setSortItem(sortOptions)
        const sortValueInSession = sessionStorage.getItem("sortItem").replace(/"/g, '');
        const sortedUsers = [...userList];
        sortedUsers.sort((a, b) => {
            if (sortValueInSession === SortingOption.Latest) {
                return new Date(b.lastSeen) - new Date(a.lastSeen);
            } else if (sortValueInSession === SortingOption.Longest) {
                return new Date(a.lastSeen) - new Date(b.lastSeen);
            } else {
                if (a.isTrigger && !b.isTrigger) {
                    return -1;
                } else if (!a.isTrigger && b.isTrigger) {
                    return 1;
                } else {
                    return 0;
                }
            }
        });
        setCurrentVisitors(sortedUsers);
    };

    const ReloadTimer = () => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
        }, 500);
    }

    const renderTimer = (time) => {
        return moment(time).format('HH:mm:ss');
    };

    /**
     * Start
     */

    useEffect(() => {
        if (visitorHubId) {
            if (contactType == 'video-call') {
                connectionHub.send(
                    'SendInvitationContactVisitor',
                    visitorHubId,
                    userContext.userId?.toString(),
                    contactType,
                    true
                );
                openVideoAgentToClient(visitorHubId);
                setWaitingContactVisitor(true);
                // setMessageStatusVisitor('Waiting for the visitor to accept the invitation.');
            }
        }

        return () => {
            if (connectionHub) {
                connectionHub.off('ContactVisitorSignalData');
                connectionHub.off('onCloseContactVisitor');
            }
        }
    }, [contactType, visitorHubId]);

    useEffect(() => {
        setTimer('00:01');
        // setInterval(()=> {
        let now = new Date();
        var days = moment(now).diff(lastSeen, 'days');
        var hours = moment(now).diff(lastSeen, 'hours') % 24;
        var minutes = moment(now).diff(lastSeen, 'minutes') % 60;
        var second = moment(now).diff(lastSeen, 'second') % 60;
        var duration =
            (days > 0 ? days + 'd ' : '') +
            (hours > 0 ? hours + 'h ' : '') +
            (minutes > 0 ? minutes + 'm ' : '') +
            (second > 0 ? second + 's ' : '');
        setTimer(duration);
        // },1000)
    }, [lastSeen, timer]);

    useEffect(() => {
        api.dashboard.getCurrentVisitor().then((res) => {
            setCurrentVisitors(res.data);
        });
        setSortItem("Trigger")

    }, []);

    useEffect(() => {
        if (connectionHub) {
            handleAppendVisitor();
            handleChatSessionId();
            handleReceiveMessage();
        }
        return () => {
            if (connectionHub) {
                connectionHub.off('onGeoLocation');
                connectionHub.off('onChatMessageRequest');
                connectionHub.off('onReceiveMessage');
            }
        };
    }, [connectionHub]);

    /**
     * Methods
     */

    const triggetChat = async (e, visitor) => {
        debugger
        setLoading(true);
        const res = await api.chat.getVisitorMessages(visitor.connectionHubId);
        const messages = res.data;
        setLoading(false);

        setSelectedVisitor(visitor);
        setMessages(messages);
        handleChat(e);

        setTimeout(() => {
            messageContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);

        if (messages.length) setSessionId(messages[0].sessionId);
        // sessionStorage.setItem(SESSIONSTORAGE_NAME.CALL_SESSION_ID, message[0].sessionId);
    };

    const openVideoAgentToClient = async (connectionHubIdOfVisitor) => {
        const iceserverdata = await api.calling.getStaticIceServer();
        const iceServers = [{ urls: iceserverdata.data.stunURI }];

        iceserverdata.data.turnServers.forEach((item) => {
            iceServers.push({
                urls: item.uris,
                username: item.username,
                credential: item.password
            });
        });

        api.calling
            .initiateUserMediaLocalStream(false, true, false)
            .then(async (locStream) => {
                localStream.current = locStream;
                const peer = new Peer({
                    initiator: true,
                    trickle: false,
                    config: {
                        iceServers: iceServers
                    }
                });
                peerAgent.current = peer;
                peerAgent.current.addStream(localStream.current);

                let video = localVideoRef.current;
                let i = 0;

                if (video != null) {
                    if ('srcObject' in video) {
                        video.srcObject = localStream.current;
                    } else {
                        video.src = window.URL.createObjectURL(localStream.current);
                    }
                } else {
                    // console.log("Video of a agent cannot use 'in' operator to search for 'srcObject' in null");
                }

                peerAgent.current.on('signal', async (signalData) => {
                    if (connectionHub) {
                        connectionHub.send(
                            'ContactVisitorSignalData',
                            connectionHubIdOfVisitor,
                            connectionHubId,
                            JSON.stringify(signalData)
                        );
                    }
                });

                connectionHub.on('ContactVisitorSignalData', (data) => {
                    if (i == 0) peerAgent.current.signal(data.signalData);
                    i++;
                });

                connectionHub.on('onCloseContactVisitor', (message) => {
                    console.log('on close contact visitor message >> ', message);
                    localStream.current.getTracks().forEach(async (track) => {
                        await track.stop();
                    });

                    peerAgent.current?.destroy();

                    if (message.closeType == 'clientDeclined') {
                        setMessageStatusVisitor('The visitor declined the call.');
                        setShowCloseButton(true);
                    } else if (message.closeType == 'autoPlayRejected') {
                        setMessageStatusVisitor(
                            'The connection failed due to client browser configuration/policy. Please try again later.'
                        );
                        setShowCloseButton(true);
                    } else {
                        setWaitingContactVisitor(false);
                        setShowCloseButton(false);
                    }

                    setCalling(false);
                    handleCancelRequest();

                    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
                        mediaStream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    });
                });

                peerAgent.current.on('close', () => {
                    if (peerAgent.current != undefined) {
                        peerAgent.current.destroy();
                    }
                });

                peerAgent.current.on('error', (err) => {
                    console.error('error', err);
                });
            })
            .catch((error) => {
                console.error('error', error);
            });
    };

    const closeVideoAgent = (closeType) => {
        setCalling(false);

        if (connectionHub) {
            console.log('connectionHub', connectionHub);
            connectionHub.send('SendCloseContactVisitor', connectionHubId, closeType);
        }

        setTimeout(() => {
            setWaitingContactVisitor(false);
            setShowCloseButton(false);
            handleCancelRequest();
        }, 250);
    };

    const handleChatSessionId = async () => {
        connectionHub.on('onChatMessageRequest', (res) => {
            console.log('onChatMessageRequest', res);
            debugger;
            const visitors = res.listOfUsers;

            if (visitors) {
                let index = visitors.findIndex((v) => v.connectionHubId === res.senderId);

                if (index > -1) {
                    visitors[index].notification = true;
                    const sortValue = sessionStorage.getItem("sortItem").replace(/"/g, '');
                    handleVisitorSorting(sortValue, visitors);
                }
    
            }
        
            // set session id after sent message requested
            setSessionId(res.sessionId);
        });
    };

    const handleAppendVisitor = () => {
        connectionHub.on('onGeoLocation', ({ listOfUsers }) => {
            const sortValue = sessionStorage.getItem("sortItem").replace(/"/g, '');
            ReloadTimer();
            handleVisitorSorting(sortValue, listOfUsers);
        });
    };

    const handleReceiveMessage = () => {
        connectionHub.on('onReceiveMessage', (res) => {
            console.log('receive msg', res);

            if (res.message?.agent === 2) {
                appendMessageBody(res.message);
                return;
            }

            debugger;
            const visitors = res.listOfUsers;
            let index = visitors.findIndex((v) => v.connectionHubId === res.message.senderId);
            const visitor = visitors[index];

            visitors[index].notification = true;
            const sortValue = sessionStorage.getItem("sortItem").replace(/"/g, '');
            handleVisitorSorting(sortValue, visitors);
            const callSessionId = JSON.parse(sessionStorage.getItem(SESSIONSTORAGE_NAME.CALL_SESSION_ID));

            if (callSessionId == res.message.sessionId) {
                setMessages((messages) => [...messages, res.message]);
                setTimeout(() => {
                    messageContainerRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest'
                    });
                }, 100);
            }
        });
    };

    const handleCancelSendMessage = () => {
        setChatRequest(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!messageBody.trim().length) return;

        try {
            appendMessage(messageBody, MessageType.TEXT);
        } catch (err) {
            console.log('error :', err);
        }

        setMessageBody('');
    };

    const appendMessageBody = (message) => {
        setMessageBody(message.messageBody);
        setTimeout(() => {
            messageContainerRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            });
        }, 100);
    };

    function isImage(extension) {
        const result = /(jpg|jpeg|png|webp|avif|gif|svg)$/.test(extension);
        return result;
    }

    const onFileChangeCapture = (e) => {
        if (e.target.files) {
            setUploading(true);
            const file = e.target.files[0];

            const formData = new FormData();
            formData.append('file', file);

            api.calling
                .uploadFile(formData)
                .then((res) => {
                    const data = res;
                    const extension = data.extension;
                    const isImageType = isImage(extension);
                    const type = isImageType ? MessageType.IMAGE : MessageType.FILE;
                    if (type === MessageType.IMAGE) {
                        appendMessage(data.uri, type);
                    } else {
                        let msgBody = `${data.fileName}<span class='size'>${data.fileSize}</span>`;
                        appendMessage(msgBody, type, data.uri);
                    }
                    setUploading(false);
                })
                .catch((err) => {
                    console.log('upload error', err);
                })
                .finally(() => {
                    inputFileRef.current.value = '';
                });
        }
    };

    const appendMessage = async (messageBody, messageType, downloadUrl = null) => {
        // const sessionId = sessionStorage.getItem(SESSIONSTORAGE_NAME.CALL_SESSION_ID);
        const msg = {
            messageBody: messageBody,
            messageType: messageType,
            downloadUrl: downloadUrl,
            senderId: connectionHubId,
            sessionId: sessionId,
            receiverId: visitorHubId,
            agent: 1
        };
        if (!messages.length)
            await connectionHub.send(
                'ChatMessageRequest',
                connectionHubId,
                visitorHubId,
                messageBody,
                userContext.name
            );
        else await connectionHub.send('SendChatMessage', msg);

        setMessages((messages) => [...messages, msg]);

        clearNotificationBadge();

        setTimeout(() => {
            messageContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    const handleSetMessageBody = (e) => {
        const newState = e.replace('<p><br></p>', '');
        setMessageBody(newState);
    };

    const clearNotificationBadge = () => {
        const visitorIndex = currentVisitors.findIndex((v) => v.connectionHubId === visitorHubId);
        currentVisitors[visitorIndex].notification = false;
        const sortValue = sessionStorage.getItem("sortItem").replace(/"/g, '');
        handleVisitorSorting(sortValue, currentVisitors);
    };

    const validateMessageBody = () => {
        const text = messageBody.replace('<p><br></p>', '');
        return text.trim().length;
    };

    const triggerAI = () => {
        try {
            appendMessage('AI what do you think?', MessageType.TEXT);
        } catch (err) {
            console.log('error :', err);
        }
    };

    /**
     * Renderers
     */

    const renderRow = (visitor, i) => {
        const toggleElm = document.getElementById(visitor.connectionHubId);

        return (
            <tr
                key={i}
                id={`${visitor.connectionHubId}`}
                className={`${visitor.isTrigger ? 'highlight' : ''}`}
            // onClick={() => openVisitorPanel(visitor, toggleElm)}
            >
                <td>
                    <span className="online-icon"></span>
                </td>
                {/* <td>{visitor.ipAddress}</td> */}
                <td>{visitor.connectionHubId}</td>
                <td>{visitor.cityName}</td>
                <td>{visitor.countryName}</td>
                <td><a href={settingContext.siteURL + visitor.pathName} target="_blank">{visitor.pathName}</a></td>
                <td className="text-center">
                    {isLoading ? (
                        <Badge
                            title={<BeatLoader size={4} color={`#EC5453`} />}
                            bgColor={'#EC545326'}
                            textColor={'#EC5453'}
                        />
                    ) : (
                        <Badge
                            title={<TimerVisitorCurrent lastSeenTime={visitor.lastSeen} />}
                            bgColor={'#EC545326'}
                            textColor={'#EC5453'}
                        />
                    )}
                </td>
                <td className="text-center">
                    <Badge title={visitor.platform} bgColor={'#dcdaf1'} textColor={'#6d65d1'} />
                </td>
                <td className="text-center">
                    <Badge title={visitor.browser} bgColor={'#d0f0de'} textColor={'#48ce85'} />
                </td>
                <td className="text-center">
                    <div className="line-vertical" style={{ backgroundImage: `url(${verticalLineImage})` }}></div>
                </td>
                <td className="text-center">
                    <button
                        type="button"
                        data-hubid={visitor.connectionHubId}
                        className="button-container btn-video-call"
                        style={{ background: '#6259CE26', cursor: 'pointer' }}
                        onClick={handleVideoCall}>
                        <Icon
                            color={`#6259CE`}
                            size={28}
                            icon={`video`}
                            style={{ marginTop: -10, opacity: calling ? 0.35 : 1 }}
                        />
                    </button>
                </td>
                <td className="text-center">
                    <button
                        type="button"
                        data-hubid={visitor.connectionHubId}
                        className="button-container btn-chat"
                        style={{ background: '#28C66F26' }}
                        onClick={(e) => triggetChat(e, visitor)}>
                        <Icon color={`#28C66F`} size={24} icon={`chat`} style={{ marginTop: -5 }} />
                        {visitor.notification && <span className="badge-icon"></span>}
                    </button>
                </td>
            </tr>
        );
    };

    const renderMessage = (msg, i) => {
        const isSameUserPrevMsg = messages[i - 1]?.senderId == msg.senderId;
        return (
            <div key={`msg_${i}`} className={`message-wrapper ${msg.agent != 0 ? 'agent' : ''}`}>
                <div
                    className={`message`}
                    key={`msg_${i}_${msg.id}`}
                    style={{ marginBottom: isSameUserPrevMsg || i == 0 ? 0 : 5 }}>
                    <span className="avatar">
                        {msg.agent == 1 ? (
                            <BiSupport color={'#aaa'} />
                        ) : msg.agent == 2 ? (
                            <FaRobot color={'#aaa'} />
                        ) : (
                            <FaUserAlt color={'#aaa'} size={14} />
                        )}
                    </span>
                    {renderMessageBody(msg)}
                </div>
            </div>
        );
    };

    const renderMessageBody = (msg) => {
        switch (msg.messageType) {
            case MessageType.IMAGE:
                return <img src={msg.messageBody} style={{ width: 120, marginLeft: 40, marginBottom: 10 }} />;
                break;
            case MessageType.FILE:
                return (
                    <a href={msg.downloadUrl} className="file" download target={'_blank'}>
                        <BsFileEarmarkText color={`#6259ce`} size={20} />
                        <div dangerouslySetInnerHTML={{ __html: msg.messageBody }}></div>
                    </a>
                );
                break;
            default:
                return <span className="text" dangerouslySetInnerHTML={{ __html: msg.messageBody }}></span>;
                break;
        }
    };

    return (
        <>
            <div className="card current-visitor">
                <div className="card-body">
                    <div className="weface-title-table">
                        <p className="">
                            Current Customers{' '}
                            {currentVisitors && currentVisitors.length ? (
                                <small>({currentVisitors.length})</small>
                            ) : (
                                <></>
                            )}
                        </p>
                        <div className="toolbar">
                            {currentVisitors && currentVisitors.length ? (
                                <>
                                    <ButtonGroup
                                        items={visitorSortItems}
                                        onChange={(sortOption) => {
                                            handleVisitorSorting(sortOption.value, currentVisitors);
                                            ReloadTimer();
                                        }}
                                        extraPadding={true}
                                    />
                                    {calling ? (
                                        <div className="badge badge-calling mb-0">
                                            <span className="d-flex align-end">
                                                Calling <BeatLoader size={4} color={`#28c66f`} />{' '}
                                            </span>
                                        </div>
                                    ) : messageStatusVisitor ? (
                                        <div
                                            className={`badge badge-danger mb-0 ${showCloseButton ? 'auto-hide' : ''}`}>
                                            <span>{messageStatusVisitor}</span>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>

                    <div className="table-responsive rounded">
                        {currentVisitors && currentVisitors.length ? (
                            <table className="current-visitor">
                                <thead>
                                    <tr className="ligth ligth-data">
                                        <th></th>
                                        {/* <th>Ip Address</th> */}
                                        <th>Connection Id</th>
                                        <th>City</th>
                                        <th>Country</th>
                                        <th>Pages</th>
                                        <th
                                            className="text-center"
                                            style={{
                                                color: '#EC5453'
                                            }}>
                                            Timer
                                        </th>
                                        <th
                                            className="text-center"
                                            style={{
                                                color: '#6259CE'
                                            }}>
                                            Platform
                                        </th>
                                        <th
                                            className="text-center"
                                            style={{
                                                color: '#28C66F'
                                            }}>
                                            Browser
                                        </th>
                                        <th></th>
                                        <th className="text-center" style={{ width: 60 }}>
                                            Video Call
                                        </th>
                                        <th className="text-center" style={{ width: 60 }}>
                                            Chat
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="ligth-body">{currentVisitors.map((e, i) => renderRow(e, i))}</tbody>
                            </table>
                        ) : (
                            <div className="empty-placeholder">
                                <TbUsers color="#ddd" size={64} />
                                <h5>Currently no active customers</h5>
                            </div>
                        )}
                    </div>
                    {/* <div className="view-all-container">
                    <ButtonViewAll />
                </div> */}
                </div>
            </div>

            {/* Video Call Preview */}
            <div className={`contact-visitor-video-container ${calling ? 'show' : ''} `}>
                <video ref={localVideoRef} autoPlay playsInline></video>
                <div className="button-group">
                    <button type="button">
                        <span>
                            Calling <BeatLoader size={4} color={`#fff`} />
                        </span>
                    </button>
                    <button type="button" onClick={() => closeVideoAgent('agentClose')}>
                        <HiPhoneMissedCall color="#fff" size={20} />
                        <span>Cancel</span>
                    </button>
                </div>
            </div>

            {/* Chat Reqeust Dialog */}
            {selectedVisitor && (
                <div
                    className={`chat-request-dialog ${chatRequest ? 'show' : ''} ${chatRequest && messages.length ? 'has-messages' : ''
                        }`}>
                    <div className="card-header">
                        <h4>Send Message To Customer</h4>
                        <button type="button" onClick={handleCancelSendMessage}>
                            <IoClose color="#fff" size={20} />
                        </button>
                    </div>
                    <div className="card-body">
                        <div className="form-group">
                            <label>
                                Connection Id:
                                <span>{visitorHubId}</span>
                            </label>

                            <div className={`message-container ${messages.length ? 'show' : ''}`}>
                                <div className={`message-box`}>
                                    {messages && messages.length ? messages.map((e, i) => renderMessage(e, i)) : <></>}
                                    {uploading ? (
                                        <div className={`message-wrapper agent`}>
                                            <div className={`message`} style={{ marginBottom: 5 }}>
                                                <span className="text">Uploading ...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div ref={messageContainerRef} />
                                </div>
                            </div>

                            <ReactQuill
                                placeholder="Write your message here..."
                                value={messageBody}
                                modules={modules}
                                formats={formats}
                                onChange={(e) => handleSetMessageBody(e)}
                                className="rte"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleSubmit(e);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="card-footer">
                        <div>
                            <button
                                type="button"
                                className="btn btn-video-call mr-1"
                                data-hubid={visitorHubId}
                                onClick={handleVideoCall}>
                                <FaVideo color={COLORS.primary} size={16} />
                            </button>
                            <button
                                type="button"
                                className="btn btn-chat-gpt"
                                onClick={triggerAI}
                                disabled={!messages.length}>
                                <BiBot color={COLORS.primary} size={16} />
                            </button>
                        </div>
                        <button
                            type="button"
                            className="btn btn-primary"
                            disabled={!validateMessageBody() || sendingMessage}
                            onClick={(e) => handleSubmit(e)}>
                            <BsSend color="#fff" size={12} />
                            <span>Send</span>
                            {submitting && <PulseLoader color="#fff" size={4} />}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default CurrentVisitors;
