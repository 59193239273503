import styled from 'styled-components';

export const PlayerToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 7px;
    width: 190px;
    opacity: 0.7;
    transition: all 0.1s linear;
    z-index: 1;
    &:hover {
        opacity: 1;
    }
`;

export const ButtonCall = styled.div`
    background-color: #e4e2f4;
`;

export const ButtonChat = styled.div`
    background-color: #dbf3e6;
`;

export const ButtonClose = styled.div`
    background-color: #f8e2e1;
`;

export const Loading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px;
    width: 100%;
`;

export default { PlayerToolbar, ButtonCall, ButtonChat, ButtonClose, Loading };
