import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';

function InteractisVideoInterface(props) {
    const { VideoRef, CanvasRef, videoSalesAgent, muted, typeUser } = props;
    const [currentTime, setCurrentTime] = useState(null);
    const [spaceCanvas, setSpaceCanvas] = useState();
    const { isMaximizeWidgetVideo, blurBackgroundSaleAgent } = props;
    const videoRefWidth = useRef();
    const videoRefHeight = useRef();
    const { isWaitData, isPlayer } = props;
    const [visibilityMaximizeWidgetVideo, setVisibilityMaximizeWidgetVideo] = useState('unset');
    const [transformMaximizeWidgetVideo, setTransformMaximizeWidgetVideo] = useState();

    const [styleTransformVideo, setStyleTransformVideo] = useState();
    const [styleBorderRadiusVideo, setStyleBorderRadiusVideo] = useState();
    const [stylePositionVideo, setStylePositionVideo] = useState();
    const [styleWidthVideo, setStyleWidthVideo] = useState();
    const [styleHeightVideo, setStyleHeightVideo] = useState();

    const [styleMarginSpaceVideo, setStyleMarginSpaceVideo] = useState();
    const [styleMaxHeightSpaceVideo, setStyleMaxHeightSpaceVideo] = useState();
    const [styleHeightSpaceVideo, setStyleHeightSpaceVideo] = useState();

    useEffect(() => {
        setInterval(() => {
            let time = moment(new Date()).format('H:mm');
            setCurrentTime(time);
        }, 6000);
    }, [currentTime]);

    useEffect(() => {
        if (isWaitData == true && isPlayer == true) {
            videoRefWidth.current = document.getElementById('videoSalesAgent').videoWidth;
            videoRefHeight.current = document.getElementById('videoSalesAgent').videoHeight;
            let widthCanvas = '-' + document.getElementById('videoSalesAgent').videoWidth + 'px';
            setSpaceCanvas(widthCanvas);
        }
    }, [isWaitData, isPlayer]);

    // useEffect(() => {
    //     maximizedStyle();
    // }, [isMaximizeWidgetVideo]);

    // useEffect(() => {
    //     if (innerWidth <= 640) {
    //         if (typeUser == "client") {
    //             setStyleBorderRadiusVideo('unset');
    //             setStyleTransformVideo('unset');
    //             if (isMaximizeWidgetVideo == true) {
    //                 setStyleMarginSpaceVideo('0 -200px 0 0px');
    //                 setStyleMaxHeightSpaceVideo('500px');
    //                 setStyleHeightSpaceVideo('500px');
    //                 setStylePositionVideo('unset');
    //                 setStyleWidthVideo('100%');
    //                 setStyleHeightVideo('100%');
    //             } else {
    //                 setStyleTransformVideo('');
    //                 setStyleBorderRadiusVideo('');
    //                 setStylePositionVideo('');
    //                 setStyleMarginSpaceVideo('');
    //                 setStyleWidthVideo('');
    //                 setStyleHeightVideo('');
    //                 setStyleMaxHeightSpaceVideo('');
    //                 setStyleHeightSpaceVideo('');
    //             }
    //         }
    //     } else {
    //         if (typeUser == "client") {
    //             if (isMaximizeWidgetVideo == true) {
    //                 setStyleTransformVideo('scale(50%)');
    //                 setStyleBorderRadiusVideo('30px');
    //                 setStylePositionVideo('fixed');
    //                 setStyleMarginSpaceVideo('unset');
    //                 setStyleMaxHeightSpaceVideo('170px');
    //                 setStyleHeightSpaceVideo('170px');
    //             } else {
    //                 setStyleTransformVideo('');
    //                 setStyleBorderRadiusVideo('');
    //                 setStylePositionVideo('');
    //                 setStyleMarginSpaceVideo('');
    //                 setStyleWidthVideo('');
    //                 setStyleHeightVideo('');
    //                 setStyleMaxHeightSpaceVideo('');
    //                 setStyleHeightSpaceVideo('');
    //             }
    //         }
    //     }
    // }, [innerWidth, isMaximizeWidgetVideo]);

    // const maximizedStyle = () => {
    //     if (typeUser == "salesAgent") {
    //         if (isMaximizeWidgetVideo == true) {
    //             setVisibilityMaximizeWidgetVideo('hidden');
    //         } else {
    //             setVisibilityMaximizeWidgetVideo('unset');
    //         }
    //     } else {
    //         if (isMaximizeWidgetVideo == true) {
    //             setTransformMaximizeWidgetVideo('unset');
    //         } else {
    //             setTransformMaximizeWidgetVideo('scale(1.08)');
    //         }
    //     }
    // }

    return videoSalesAgent ? (
        <>
            {/* SaleaAgent */}
            <video
                id="videoSalesAgent"
                className="video-agent"
                ref={VideoRef}
                autoPlay
                muted={muted}
                playsInline
                width={videoRefWidth.current}
                height={videoRefHeight.current}
                style={{
                    visibility: 'visible',
                    position: 'absolute',
                    display: blurBackgroundSaleAgent ? 'none' : 'block'
                }}
                // style={{ visibility: blurBackgroundSaleAgent ? 'hidden' : 'visible', position: 'absolute' }}
            />

            {VideoRef.current != null ? (
                <>
                    <canvas
                        className="inWidgetVideo_2_salesagent"
                        ref={CanvasRef}
                        style={{
                            width: `100px`,
                            height: `75px`,
                            marginTop: -5,
                            display: blurBackgroundSaleAgent ? 'block' : 'none'
                        }}
                        width={100} height={75}
                    ></canvas>
                </>
            ) : (
                <></>
            )}
        </>
    ) : (
        <>
            {/* Client */}
            <video
                className="video-client"
                ref={VideoRef}
                autoPlay
                muted={muted}
                playsInline
                width={videoRefWidth.current}
                height={videoRefHeight.current}
            />

            {/* {VideoRef.current != null ? (
                <>
                    <canvas
                        className="inWidgetVideo_2_client spaceCanvas"
                        ref={CanvasRef}
                        style={{ marginLeft: spaceCanvas, visibility: 'hidden', display: 'none' }}
                        width={videoRefWidth.current}
                    ></canvas>
                </>
            ) : (
                <></>
            )} */}
        </>
    );
}

export default InteractisVideoInterface;
