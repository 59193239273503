import { IcardSwitch } from 'interfaces';
import './style.scss';

function CardToggleButton(prop: IcardSwitch) {
    return (
        <>
            <div
                className="card container-card-custom toggle-button"
                style={{ height: prop.height ? prop.height : 'unset' }}>
                <div className="card-body">
                    <div className="title">{prop.title}</div>
                    <div className="sub-title">{prop.subTitle}</div>
                    {prop.elementToggle}
                </div>
                {prop.preview ? prop.preview : ''}
            </div>
        </>
    );
}

export default CardToggleButton;
