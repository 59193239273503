// @/src/common/refreshToken.js
import mem from 'mem';

import { axiosPublic } from './axios.public';

const refreshTokenFn = async () => {
    try {
        const auth = JSON.parse(sessionStorage.getItem('auth'));
        const user = JSON.parse(sessionStorage.getItem('user'));

        const res = await axiosPublic.post(`/api/v1/authentication/login`, {
            grant_type: 'refresh_token',
            refresh_token: auth?.refresh_token,
            username: user?.email
        });

        const session = res.data;

        if (!session?.access_token) {
            sessionStorage.removeItem('auth');
            sessionStorage.removeItem('user');
        }

        sessionStorage.setItem('auth', JSON.stringify(session));

        return session;
    } catch (error) {
        sessionStorage.removeItem('auth');
        sessionStorage.removeItem('user');
    }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshTokenFn, {
    maxAge
});
