import React, { useEffect, useRef, useContext, useState } from 'react';
import Konva from 'konva';
import './style.scss';

// const useStyles = makeStyles((theme) => ({
//     saveButton: { marginLeft: 2, marginRight: 2, color: "#fff" },
//     button: {
//         marginLeft: 2,
//         marginRight: 2,
//     },
// }));

function WefaceDrawing(props) {
    // const classes = useStyles();
    const stage = useRef();
    const layer = useRef();
    const lastLine = useRef();

    const { isDrawing, setIsDrawing } = props;
    const { statusPaint, setStatusPaint } = props;
    const { positionSalesAgentCurrent, setPositionSalesAgentCurrent } = props;
    const { stageConfig, startLineConfig, playerWidth, playerHeight, playerTransform } = props;
    const { isWaitData, isPlayer } = props;

    const [optionDraw, setOptionDraw] = useState(false);

    var isPaint = false;
    var mode = 'brush';

    useEffect(() => {
        // console.log(playerWidth, playerHeight, isDrawing)
        if (playerWidth && playerHeight && isDrawing) {
            stageConfig.current = {
                container: 'syncit-canvas',
                width: playerWidth,
                height: playerHeight
            };
            stage.current = new Konva.Stage(stageConfig.current);

            layer.current = new Konva.Layer();
            stage.current.add(layer.current);

            stage.current.on('mousedown touchstart', function () {
                startLine();
            });

            stage.current.on('mouseup touchend', function () {
                endLine();
            });

            stage.current.on('mousemove touchmove', function (e) {
                draw(e);
            });
        }
    }, [playerWidth, playerHeight, isDrawing]);

    function startLine() {
        isPaint = true;
        setStatusPaint(true);

        var pos = stage.current.getPointerPosition();
        startLineConfig.current = {
            stroke: '#df4b26',
            strokeWidth: 5,
            globalCompositeOperation: mode === 'brush' ? 'source-over' : 'destination-out',
            lineCap: 'round'
        };

        lastLine.current = new Konva.Line(startLineConfig.current);
        layer.current.add(lastLine.current);

        console.log(layer)
    }

    function endLine() {
        isPaint = false;
        setStatusPaint(false);

        const tween = new Konva.Tween({
            node: lastLine.current,
            easing: Konva.Easings['EaseOut'],
            opacity: 0,
            duration: 1.5
        });

        setTimeout(() => {
            tween.play();
        }, 2000);
    }

    function draw(e) {
        if (!isPaint || !isDrawing) {
            return;
        }

        // prevent scrolling on touch devices
        e.evt.preventDefault();

        const pos = stage.current.getPointerPosition();
        var newPoints = lastLine.current.points().concat([pos.x, pos.y]);
        lastLine.current.points(newPoints);

        setPositionSalesAgentCurrent(newPoints);
    }

    // const handleOptionDraw = () => {
    //     // console.log('optionDraw :', optionDraw);
    //     if (optionDraw == false) {
    //         setOptionDraw(true);
    //     } else {
    //         setOptionDraw(false);
    //     }
    // };

    const handleDrawing = () => {
        // console.log('isDrawing :', isDrawing);
        setIsDrawing(!isDrawing);
        setOptionDraw(false);
    };

    const handleClearDraw = () => {
        setIsDrawing(false);
        setOptionDraw(false);
        setTimeout(() => {
            setIsDrawing(true);
        }, 100);
    };

    return (
        <div
            id="syncit-canvas"
            className={`canvas-drawing ${isDrawing ? 'show' : ''}`}
            style={{ transform: `scale(${playerTransform})`, width: playerWidth, height: playerHeight }}
        />
    );
}

export default WefaceDrawing;
