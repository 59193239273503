import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'components'
import { IserviceCallByAgentId } from 'interfaces';
import { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import './style.scss'
import moment from 'moment';


interface ItooltipPopup {
    toolTipExit : (e:any)=> void;
    toolTipDelCalendarEventIndividual: (e:number)=> void;
    dataAll: any,
    dataIndividual : any
    removeEventIndividual: Function
    serviceCallId : any
    
}

const convertDate = (date:string) => {
    let fdate:string = date;
    let day:string = fdate.split("-")[2];
    let month:string = fdate.split("-")[1];
    let year:string = fdate.split("-")[0];

    return day + "-" + month + "-" + year;
}


const TooltipCalendarEvent = (prop:ItooltipPopup) =>{
    const [startDate, setStartDate] = useState<string>();
    const [startTime, setStartTime] = useState<string>();
    const [endTime, setEndTime] = useState<string>();
    const [clientName, setClientName] = useState<string>();
    const [description, setDescription] = useState<any>();
    const [serviceCallId, setServiceCallId] = useState<number>();

    useEffect(()=>{
        if(prop.dataIndividual){
                   let  data : IserviceCallByAgentId = prop.dataAll.find(x=> x.serviceCallId === prop.dataIndividual.id);
                    setStartTime(data.startTime.split("T")[1].substring(0,5));
                    setEndTime(data.endTime.split("T")[1].substring(0,5));
                    setStartDate(convertDate(data.startTime.split("T")[0]));
                    setClientName(data.clientName);
                    setDescription(data.description? parse(data.description.toString()): "None");
                    setServiceCallId(data.serviceCallId)

                }
    },[prop.dataIndividual])

    return(
        <>
        {
            prop.dataIndividual? 
            <div className="container-tooltip">
                <div className="tooltip-icon-top"></div>
                <div className="tooltip-hearder">
                    <div className="tooltip-icon"> <Icon icon={`calendar-icon`} color={`#6259CE`} size={`24`}/></div>
                    <div className="tooltip-title">{clientName}
                        <span className="tooltip-exit" onClick={prop.toolTipExit}><FontAwesomeIcon icon={faX} size={`1x`} /></span>
                        <div className="tooltip-title-date">
                            {startDate} | {startTime} - {endTime} 
                        </div>
                    </div>
                </div>
                <div className="tooltip-body-content">
                {description}
                </div>
                <div className="tooltip-action-event">
                    <span className="button-icon"> <Icon icon={`trash-Can`} color={`#EC5453`} size={`16`}/></span>
                    <span className="text-btn" onClick={()=> prop.removeEventIndividual(serviceCallId)}>Delete Event</span>
                </div>
            </div>
           :
           ""
           
        }
            
        </>
    )



}
export default TooltipCalendarEvent