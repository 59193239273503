import { ChatConnectionHubContext, ConnectionHubContext } from 'contexts';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { BiSupport } from 'react-icons/bi';
import { FaPaperPlane, FaRobot, FaUser, FaUserAlt } from 'react-icons/fa';
import { BsFileEarmarkText } from 'react-icons/bs';
import { IoAttach, IoPaperPlaneOutline } from 'react-icons/io5';
import { VscHubot } from 'react-icons/vsc';
import { useServices } from 'services';

import './style.scss';
import { MessageType, SESSIONSTORAGE_NAME } from 'utils/constants';
import { useSessionStorage } from 'usehooks-ts';

interface IChatbox {
    connectionHubOn?: any
}

function ChatBox(props: IChatbox) {
    const [connectionHubContext] = useContext(ConnectionHubContext);
    const { connectionHub, connectionHubId } = connectionHubContext;

    const { api } = useServices();

    const [messages, setMessages] = useState([]);
    const [messageBody, setMessageBody] = useState('');
    const [uploading, setUploading] = useState(false);

    const inputFileRef = useRef(null);
    const messageContainerRef = useRef(null);

    // const sessionId = sessionStorage.getItem(SESSIONSTORAGE_NAME.CALL_SESSION_ID);
    const [sessionId] = useSessionStorage(SESSIONSTORAGE_NAME.CALL_SESSION_ID, null);
    useEffect(() => {
        if (connectionHubId || props.connectionHubOn) {
            connectionHub.on('onReceiveMessage', (res) => {
                console.log('onReceiveMessage', res)
                if (res.message?.agent === 2) {
                    appendMessageBody(res.message);
                    return;
                }

                setMessages((messages) => [...messages, res.message]);

                setTimeout(() => {
                    messageContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
                    // playPush();
                }, 100);
            });
        }

        return () => {
            connectionHub.off('onChatMessageRequest');
            // connectionHub.off('onGeoLocation');
            connectionHub.off('onReceiveMessage');
            setMessages([]);
        };
    }, [connectionHubId, props.connectionHubOn]);


    useEffect(() => {
        if (sessionId) {
            api.calling.getChatMessages(sessionId).then((res) => {
                setMessages(res.data);
            });
        }

        return () => {
            setMessages([]);
        };
    }, [sessionId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!messageBody.trim().length) return;

        try {
            appendMessage(messageBody, MessageType.TEXT);
        } catch (err) {
            console.log('error :', err);
        }

        setMessageBody('');
    };

    const triggerAI = async (e) => {
        try {
            appendMessage('AI what do you think?', MessageType.TEXT);
        } catch (err) {
            console.log('error :', err);
        }
    };

    const playPush = () => { };

    const renderMessage = (msg, i) => {
        const isSameUserPrevMsg = messages[i - 1]?.senderId == msg.senderId;
        return (
            <div key={`msg_${i}`} className={`message-wrapper ${msg.agent != 0 ? 'agent' : ''}`}>
                <div
                    className={`message`}
                    style={{ marginBottom: isSameUserPrevMsg || i == 0 ? 0 : 5 }}>
                    <span className="avatar">
                        {msg.agent == 1 ? (
                            <BiSupport color={'#aaa'} />
                        ) : msg.agent == 2 ? (
                            <FaRobot color={'#aaa'} />
                        ) : (
                            <FaUserAlt color={'#aaa'} size={14} />
                        )}
                    </span>
                    {renderMessageBody(msg)}
                </div>
            </div>
        );
    };

    const renderMessageBody = (msg) => {
        switch (msg.messageType) {
            case MessageType.IMAGE:
                return <img src={msg.messageBody} style={{ width: 120, marginLeft: 40, marginBottom: 10 }} />;
                break;
            case MessageType.FILE:
                return (
                    <a href={msg.downloadUrl} className="file" download target={'_blank'}>
                        <BsFileEarmarkText color={`#6259ce`} size={20} />
                        <div dangerouslySetInnerHTML={{ __html: msg.messageBody }}></div>
                    </a>
                );
                break;
            default:
                return <span className="text">{msg.messageBody}</span>;
                break;
        }
    };

    function isImage(extension) {
        const result = /(jpg|jpeg|png|webp|avif|gif|svg)$/.test(extension);
        return result;
    }

    const onFileChangeCapture = (e) => {
        if (e.target.files) {
            setUploading(true);
            const file = e.target.files[0];

            const formData = new FormData();
            formData.append('file', file);

            api.calling
                .uploadFile(formData)
                .then((res) => {
                    const data = res;
                    const extension = data.extension;
                    const isImageType = isImage(extension);
                    const type = isImageType ? MessageType.IMAGE : MessageType.FILE;
                    if (type === MessageType.IMAGE) {
                        appendMessage(data.uri, type);
                    } else {
                        let msgBody = `${data.fileName}<span class='size'>${data.fileSize}</span>`;
                        appendMessage(msgBody, type, data.uri);
                    }
                    setUploading(false);
                })
                .catch((err) => {
                    console.log('upload error', err);
                })
                .finally(() => {
                    inputFileRef.current.value = '';
                });
        }
    };

    const appendMessage = async (messageBody, messageType, downloadUrl = null) => {
        const msg = {
            messageBody: messageBody,
            messageType: messageType,
            downloadUrl: downloadUrl,
            senderId: connectionHubId,
            sessionId: sessionId,
            receiverId: '',
            agent: 1
        };

        if (connectionHub) await connectionHub.send('SendChatMessage', msg);

        setMessages((messages) => [...messages, msg]);

        setTimeout(() => {
            messageContainerRef.current?.scrollIntoView({ behavior: 'smooth' });
            playPush();
        }, 100);
    };

    const appendMessageBody = (message) => {
        setMessageBody(message.messageBody);
        setTimeout(() => {
            messageContainerRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
            });
        }, 100);
    };

    const handleAttach = () => {
        inputFileRef.current.click();
    };

    return (
        <div className="card chat">
            <div className="card-header">
                <h4>
                    <FaUser />
                    Unknown Visitor
                </h4>
            </div>
            <div className="card-body">
                <div className="message-container">
                    <div className={`message-box`}>
                        {messages && messages.length ? messages.map((e, i) => renderMessage(e, i)) : <></>}
                        {uploading ? (
                            <div className={`message-wrapper agent`}>
                                <div className={`message`} style={{ marginBottom: 5 }}>
                                    <span className="text">Uploading ...</span>
                                </div>
                            </div>
                        ) : (
                            <></>
                        )}
                        <div ref={messageContainerRef} />
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <textarea
                            className="form-control"
                            value={messageBody}
                            placeholder={`Enter your message ...`}
                            onChange={(e) => setMessageBody(e.target.value)}></textarea>

                        <input
                            type="file"
                            accept="image/*,application/pdf"
                            ref={inputFileRef}
                            className="d-none"
                            onChangeCapture={onFileChangeCapture}
                        />
                    </div>
                    <div className="form-footer">
                        <button type="button" onClick={handleAttach} className="btn btn-upload">
                            <IoAttach size={24} color="#999" />
                        </button>

                        <div className="d-flex">
                            <button type="button" onClick={triggerAI} className="btn btn-chat-gpt" title="Ask ChatGPT">
                                <VscHubot size={24} color="#FFF" />
                            </button>

                            <button type="submit" className="btn btn-submit">
                                <IoPaperPlaneOutline size={18} color="#FFF" />
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ChatBox;
