import React, { useContext, useEffect, useRef, useState } from 'react';

import { Icon } from '..';
import { IbuttonTools } from '../../interfaces/Ibutton-tools';
import './style.scss';

const ButtonTools = (prop: IbuttonTools) => {
    const handleClick = () => {
        if (prop.onClick) prop.onClick();
    };

    return (
        <>
            <button
                className="btn-live-control"
                type="button"
                style={{ background: prop.bgColor, height: '57.21px' }}
                onClick={handleClick}
            >
                <div className={prop.class}>
                    {prop.customIcon ? prop.customIcon : <Icon icon={prop.icon} size={prop.size} color={prop.color} />}
                </div>
                <span>{prop.title}</span>
            </button>
        </>
    );
};

export default ButtonTools;
