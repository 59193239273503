import { useState, useEffect } from 'react';
import './style.scss';

interface IsetTime {
    title: string;
    focusText: string;
    value: any;
    unitTime: string;
    type: string;
    onChange?: Function
}

function CardSetTime(props: IsetTime) {
    const { value, title, focusText, type, unitTime, onChange } = props;

    return (
        <>
            <div className="card container-card-custom card-time">
                <div className="card-body">
                    <div className="title">
                        {title} <span className="focus-text">{focusText}</span>
                    </div>
                    <div className="input-group">
                        <input
                            name="sec"
                            type={type}
                            className={`input-time`}
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                        />

                        <label htmlFor="unit-time" className="lable-unit">
                            {unitTime}
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardSetTime;
