import { TopContentHeader } from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { useEffect, useState } from 'react';
import './style.scss';
import { useParams } from 'react-router-dom';
import insight from 'services/api/insight.service';
import moment from 'moment';
import SaleAgentSoundRecord from './insight-sale-agent/sound-record';
import SaleAgentTextConversation from './insight-sale-agent/text-conversation';
import ClientSoundRecord from './insight-client/sound-record';
import ClientConvertToText from './insight-client/text-conversation';
import CallDetails from './insight-call-details/call-detail';
import KeyPhiaseClient from './keyPhrases/client-key-phrase';
import SentimentAnalysisClient from './sentiment-analysis-client';

const InsightDetail = (props: any) => {
    const insightService = insight;
    const { id } = useParams();

    const [saleAgentName, setSaleAgentName]: any = useState();
    const [clientName, setClientName]: any = useState();
    const [dateCallRecord, setDateCallRecord]: any = useState();
    const [dateCallRecordCard, setDateCallRecordCard]: any = useState();
    const [startTimeCallRecord, setStartTimeCallRecord]: any = useState();
    const [endTimeCallRecord, setEndTimeCallRecord]: any = useState();

    const [createDateActionQueues]: any = useState(moment().format('LLL'));
    const [updateDateActionQueues]: any = useState(moment().format('LLL'));
    const [callQueuesId, setCallQueuesId]: any = useState();

    const [clientConvertText, setClientConvertText]: any = useState();
    const [salesAgentConvertText, setSalesAgentConvertText]: any = useState();

    const [clientAudioFileId, setClientAudioFileId]: any = useState();
    const [salesAgentAudioFileId, setSalesAgentAudioFileId]: any = useState();

    const [clientStatusRecord, setClientStatusRecord]: any = useState(false);
    const [clientStatusConvertText, setClientStatusConvertText]: any = useState();
    const [clientStatusSentimentAnalysis, setClientStatusSentimentAnalysis]: any = useState(0);

    const [salesAgentStatusRecord, setSalesAgentStatusRecord]: any = useState();
    const [salesAgentStatusConvertText, setSalesAgentStatusConvertText]: any = useState();
    const [salesAgentStatusSentimentAnalysis, setSalesAgentStatusSentimentAnalysis]: any = useState();
    const [clientSentimentAnalysis, setClientSentimentAnalysis]: any = useState();

    const [statusColor, setStatusColor] = useState('#e0e0e0');

    const [seriesChartDonutPositiveClient, setSeriesChartDonutPositiveClient]: any = useState(0);
    const [seriesChartDonutNeutralClient, setSeriesChartDonutNeutralClient]: any = useState(0);
    const [seriesChartDonutNegativeClient, setSeriesChartDonutNegativeClient]: any = useState(0);

    const [seriesChartDonutPositiveSalesAgent, setSeriesChartDonutPositiveSalesAgent]: any = useState(0);
    const [seriesChartDonutNeutralSalesAgent, setSeriesChartDonutNeutralSalesAgent]: any = useState(0);
    const [seriesChartDonutNegativeSalesAgent, setSeriesChartDonutNegativeSalesAgent]: any = useState(0);

    useEffect(() => {
        // console.log('ID : ', id);
        if (id) {
            insightService
                .getCallRecord(id)
                .then((result: any) => {
                    //Show data call detail.
                    setSaleAgentName(result.data.saleAgentName);
                    setClientName(result.data.clientName);
                    setDateCallRecordCard(moment(result.data.startTime).format('DD MMM YYYY'));
                    setDateCallRecord(moment(result.data.startTime).format('DD/MM/YYYY'));
                    setStartTimeCallRecord(moment(result.data.startTime).format('LTS'));
                    setEndTimeCallRecord(moment(result.data.endTime).format('LTS'));

                    //Send queues convert text.
                    setCallQueuesId(result.data.idCallQueues);

                    //Show data convert text.
                    setClientConvertText(result.data.clientConvertText);
                    setSalesAgentConvertText(result.data.salesAgentConvertText);

                    //Set file call record name
                    setClientAudioFileId(result.data.clientAudioFileId);
                    setSalesAgentAudioFileId(result.data.salesAgentAudioFileId);

                    setClientSentimentAnalysis(result.data.clientSentimentAnalysis);

                    //Set donut chart
                    if (result.data.salesAgentSentimentAnalysis) {
                        let sentimentStrSalesAgent: any = result.data.salesAgentSentimentAnalysis;
                        let sentimentObjSalesAgent: any = JSON.parse(sentimentStrSalesAgent);
                        let sentimentPositive: any = parseFloat(sentimentObjSalesAgent.SentimentPositive);
                        let sentimentPositiveResult: any = sentimentPositive * 100;

                        let sentimentNeutral: any = parseFloat(sentimentObjSalesAgent.SentimentNeutral);
                        let sentimentNeutralResult: any = sentimentNeutral * 100;

                        let sentimentNegative: any = parseFloat(sentimentObjSalesAgent.SentimentNegative);
                        let sentimentNegativeResult: any = sentimentNegative * 100;

                        setSeriesChartDonutPositiveSalesAgent(Number(parseFloat(sentimentPositiveResult).toFixed(0)));
                        setSeriesChartDonutNeutralSalesAgent(Number(parseFloat(sentimentNeutralResult).toFixed(0)));
                        setSeriesChartDonutNegativeSalesAgent(Number(parseFloat(sentimentNegativeResult).toFixed(0)));
                    }
                    if (result.data.clientSentimentAnalysis) {
                        let sentimentStrClient = result.data.clientSentimentAnalysis;
                        let sentimentObjClient = JSON.parse(sentimentStrClient);

                        let sentimentPositive: any = parseFloat(sentimentObjClient.SentimentPositive);
                        let sentimentPositiveResult: any = sentimentPositive * 100;

                        let sentimentNeutral: any = parseFloat(sentimentObjClient.SentimentNeutral);
                        let sentimentNeutralResult: any = sentimentNeutral * 100;

                        let sentimentNegative: any = parseFloat(sentimentObjClient.SentimentNegative);
                        let sentimentNegativeResult: any = sentimentNegative * 100;

                        setSeriesChartDonutPositiveClient(Number(parseFloat(sentimentPositiveResult).toFixed(0)));
                        setSeriesChartDonutNeutralClient(Number(parseFloat(sentimentNeutralResult).toFixed(0)));
                        setSeriesChartDonutNegativeClient(Number(parseFloat(sentimentNegativeResult).toFixed(0)));
                    }

                    //Set status
                    if (result.data.clientStatusRecord == 1) {setStatusColor('#1abc9c');}

                    if (result.data.salesAgentStatusRecord == 1) {setStatusColor('#1abc9c');}

                    setClientStatusRecord(result.data.clientStatusRecord);
                    setClientStatusConvertText(result.data.clientStatusConvertText);
                    setClientStatusSentimentAnalysis(result.data.clientStatusSentimentAnalysis);

                    setSalesAgentStatusRecord(result.data.salesAgentStatusRecord);
                    setSalesAgentStatusConvertText(result.data.salesAgentStatusConvertText);
                    setSalesAgentStatusSentimentAnalysis(result.data.salesAgentStatusSentimentAnalysis);

                })
                .catch((err) => {
                    // console.log("error: ", err);
                });
        }
    }, [id, clientStatusSentimentAnalysis, salesAgentStatusSentimentAnalysis]);

    return (
        <>
            <NavbarTop />
            <NavbarLeft />
            <div className="content-page insight-detail">
                <div className="container-fluid">
                    <TopContentHeader
                        titleOfPage={`INSIGHT+`}
                        isSearch={false}
                        placeholderSearch={``}
                        hasButton={false}
                        buttonName={``}
                        hasSymbolButton={false}
                        symbolButton={`+`}
                        functionButton={() => {}}
                    />

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card title" style={{ boxShadow: '0 10px 0 -1px #e4e2f4' }}>
                                <div className="card-body">
                                    <h4>Sale Agent</h4>
                                </div>
                            </div>
                            <SaleAgentSoundRecord
                                callQueuesId={callQueuesId}
                                salesAgentAudioFileId={salesAgentAudioFileId}
                                statusColor={statusColor}
                                salesAgentStatusRecord={salesAgentStatusRecord}
                            />

                            <SaleAgentTextConversation
                                createDateActionQueues={createDateActionQueues}
                                updateDateActionQueues={updateDateActionQueues}
                                callQueuesId={callQueuesId}
                                salesAgentConvertText={salesAgentConvertText}
                                id={id}
                                statusColor={statusColor}
                                salesAgentStatusRecord={salesAgentStatusRecord}
                                salesAgentStatusConvertText={salesAgentStatusConvertText}
                                salesAgentStatusSentimentAnalysis={salesAgentStatusSentimentAnalysis}
                                setSalesAgentStatusSentimentAnalysis={setSalesAgentStatusSentimentAnalysis}
                                setSeriesChartDonutPositiveSalesAgent={setSeriesChartDonutPositiveSalesAgent}
                                setSeriesChartDonutNeutralSalesAgent={setSeriesChartDonutNeutralSalesAgent}
                                setSeriesChartDonutNegativeSalesAgent={setSeriesChartDonutNegativeSalesAgent}
                                clientConvertText={clientConvertText}
                                dateCallRecordCard={dateCallRecordCard}
                            />
                        </div>
                        <div className="col-lg-4">
                            <div className="card title" style={{ boxShadow: '0 10px 0 -1px #ddf3e7' }}>
                                <div className="card-body">
                                    <h4>Client</h4>
                                </div>
                            </div>
                            <ClientSoundRecord
                                callQueuesId={callQueuesId}
                                clientAudioFileId={clientAudioFileId}
                                statusColor={statusColor}
                                clientStatusRecord={clientStatusRecord}
                                dateCallRecordCard={dateCallRecordCard}
                            />

                            <ClientConvertToText
                                createDateActionQueues={createDateActionQueues}
                                updateDateActionQueues={updateDateActionQueues}
                                callQueuesId={callQueuesId}
                                clientConvertText={clientConvertText}
                                id={id}
                                statusColor={statusColor}
                                clientStatusRecord={clientStatusRecord}
                                clientStatusConvertText={clientStatusConvertText}
                                clientStatusSentimentAnalysis={clientStatusSentimentAnalysis}
                                setClientStatusSentimentAnalysis={setClientStatusSentimentAnalysis}
                                setSeriesChartDonutPositive={setSeriesChartDonutPositiveClient}
                                setSeriesChartDonutNeutral={setSeriesChartDonutNeutralClient}
                                setSeriesChartDonutNegative={setSeriesChartDonutNegativeClient}
                                dateCallRecordCard={dateCallRecordCard}
                            />
                        </div>
                        <div className="col-lg-4">
                            <div className="card title" style={{ boxShadow: '0 10px 0 -1px #fbeed5' }}>
                                <div className="card-body">
                                    <h4>Call Details</h4>
                                </div>
                            </div>

                            <CallDetails
                                saleAgentName={saleAgentName}
                                clientName={clientName}
                                dateCallRecord={dateCallRecord}
                                startTimeCallRecord={startTimeCallRecord}
                                endTimeCallRecord={endTimeCallRecord}
                            />

                            <KeyPhiaseClient clientSentimentAnalysis={clientSentimentAnalysis} />
                        </div>
                    </div>

                    {clientStatusSentimentAnalysis ? (
                        <div className="card chart">
                            <div className="card-body">
                                <div className="row">
                                    {/* ---CHART Sentiment Analysis */}

                                    <SentimentAnalysisClient
                                        id={id}
                                        clientStatusSentimentAnalysis={clientStatusSentimentAnalysis}
                                        seriesChartDonutPositiveClient={seriesChartDonutPositiveClient}
                                        seriesChartDonutNeutralClient={seriesChartDonutNeutralClient}
                                        seriesChartDonutNegativeClient={seriesChartDonutNegativeClient}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
};
export default InsightDetail;
