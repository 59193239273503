import React, { useState} from 'react';
import './style.scss';
import { useNavigate,  NavLink } from 'react-router-dom';
import { useServices } from 'services';

const bgImage = `${process.env.PUBLIC_URL}/assets/images/bg-image.jpg`;
const logoImage = `${process.env.PUBLIC_URL}/assets/images/logo-weface-new.png`;

function ForgetPassword() {
    const [username, setUsername] = useState('');
    const navigate = useNavigate();
    const { api } = useServices();

    const forgetPassword = (event: any) => {
        event.preventDefault();
        if (username != '') {
          api.auth.forgotPassword(username)
                .then(result => {
                    setTimeout(() => {
                        navigate('/send-to-email', { replace: true });
                    }, 3000);
                })
                .catch(errors => {
                    console.log('errors', errors);
                });
        } else {
            alert('Please enter your registered email address.');
        }
    };

    return (
        <>
            <div className="wrapper bg-login" style={{ backgroundImage: `url(${bgImage})` }}>
                <section className="login-content">
                    <div className="logo" style={{ backgroundImage: `url(${logoImage})` }}></div>
                    <form onSubmit={forgetPassword}>
                        <div className="forget-password-container">
                            <div className="title-on-top">
                                Forget Your Password
                                <div className="title-description">
                                    Enter your email address below <br />
                                    and we'll send your reset password link
                                </div>
                            </div>
                            <div className="forget-password-email-box-container">
                                <div className="forget-password-email-box">
                                    <input
                                        className="floating-input form-control input-email-to-email"
                                        autoComplete="email"
                                        type="email"
                                        onChange={e => setUsername(e.target.value)}
                                        placeholder="Email"
                                        aria-autocomplete="both"
                                    />
                                </div>
                            </div>
                            <div className="submit-button-container">
                                <button type="submit" className="button-send-email ">
                                    Send to your email
                                </button>
                            </div>
                            <div className="back-to-login-container">
                                <NavLink to={`/login`} className="back-to-login">
                                    Back to Login
                                </NavLink>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </>
    );
}

export default ForgetPassword;
