import ButtonToggle from 'components/button-toggle';
import { SettingContext, UserContext } from 'contexts';
import { IcallSettings } from 'interfaces';
import React, { useContext, useEffect, useState } from 'react';
import { FiLogOut, FiSettings, FiUser } from 'react-icons/fi';
import { NavLink, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { useServices } from 'services';
import { MESSAGES_ALERT, USER_ROLE } from 'utils/constants';
import { headersContentType, replaceMessages, toastProperty } from 'utils/help';

import './style.scss';

function NavbarTop() {
    const [userContext] = useContext(UserContext);
    const [settingContext, setSettingContext] = useContext(SettingContext);
    const { isDisplayWeface } = settingContext;
    const { api } = useServices();
    // const [displayWeFace, setDisplayWeFace] = useState<boolean>(); // => isDisplayWeface

    const { name, userRole, userId, profileUrl } = userContext;
    const [id, setId] = useState<number>(userId);
    const location: string = window.location.pathname;
    const pathUrl: any = location.replace('/', '');
    const { memberId } = useParams();
    const paramId: number = parseInt(memberId);
    const unix = Math.round(+new Date() / 1000);

    useEffect(() => {
        if (pathUrl === 'settings') {
            let element: any = document.getElementById('top-settings');
            element.setAttribute('class', 'iq-sub-card active');
        }
        if (pathUrl === 'users/edituser/' && userId === paramId) {
            let element: any = document.getElementById('top-profile');
            element.setAttribute('class', 'iq-sub-card active');
        }
    }, [paramId]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        (async () => {
            const formData: any = new FormData();
            const isDisplayWeface = !settingContext.isDisplayWeface;
            formData.append('isDisplayWeface', isDisplayWeface);

            await api.callSetting.updateWefaceDisplay(formData, {
                headers: headersContentType()
            });

            const message = isDisplayWeface ? MESSAGES_ALERT.SAVE_VISIBLE : MESSAGES_ALERT.SAVE_HIDE;

            toast.success(replaceMessages(message, `Display WeFace`), toastProperty('top-left', 1000));
        })();
    };

    const handleToggle = (e) => {
        setSettingContext({ ...settingContext, isDisplayWeface: e });
    };

   const handleLogout = () =>{
        api.auth.logout(); 
   }

    return (
        <>
            <ToastContainer />
            <div className="layout-top-nav-bar iq-top-navbar top-nav-bar">
                <div></div>
                <div className="iq-navbar-custom">
                    <nav className="navbar navbar-expand-lg navbar-light p-0">
                        <div className="iq-navbar-logo d-flex align-items-center justify-content-between"></div>
                        <div className="iq-search-bar device-search"></div>
                        <div className="d-flex align-items-center">
                            <button
                                className="navbar-toggler "
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-label="Toggle navigation">
                                <i className="ri-menu-3-line"></i>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto navbar-list align-items-center">
                                    <li className="nav-item nav-icon dropdown">
                                        <div className="custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline">
                                            <div className="custom-switch-inner">
                                                <form onChange={handleSubmit}>
                                                    <div className="container-button-toggle">
                                                        <ButtonToggle
                                                            defaultChecked={isDisplayWeface}
                                                            onToggle={(e) => handleToggle(e)}
                                                        />
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="nav-item nav-icon dropdown">
                                        <a href="#">
                                            <div className="container-profile-topbar-nav">
                                                <p className="full-name">{name}</p>
                                                <p className="role">
                                                    {userRole === USER_ROLE.ADMIN ? 'Admin' : ''}
                                                    {userRole === USER_ROLE.SALES_AGENT ? 'Sale Agent' : ''}
                                                    {userRole === USER_ROLE.SUPERADMIN ? 'Super admin' : ''}
                                                </p>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item nav-icon dropdown caption-content">
                                        <a
                                            href="#"
                                            className="search-toggle dropdown-toggle"
                                            id="dropdownMenuButton4"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                            <div className="user-avatar">
                                                {profileUrl ? (
                                                    <div
                                                        className="user-profile"
                                                        style={{
                                                            backgroundImage: `url(${profileUrl}?v=${unix})`,
                                                            borderRadius: '50%'
                                                        }}></div>
                                                ) : (
                                                    <div className="user-profile"></div>
                                                )}
                                                <span className="user-avatar-online"></span>
                                            </div>
                                        </a>
                                        <div
                                            className="iq-sub-dropdown dropdown-menu"
                                            aria-labelledby="dropdownMenuButton">
                                            <div className="card">
                                                <div className="card-body">
                                                    <NavLink
                                                        to={`/users/edituser/${id}`}
                                                        className="iq-sub-card"
                                                        id="top-profile">
                                                        <FiUser size={16} /> My Profile
                                                    </NavLink>
                                                    <NavLink to="/settings" className="iq-sub-card" id="top-settings">
                                                        <FiSettings size={16} /> Settings
                                                    </NavLink>
                                                    <NavLink
                                                        to="/login"
                                                        className="iq-sub-card"
                                                        id="top-logout"
                                                        onClick={handleLogout}>
                                                        <FiLogOut size={16} /> Logout
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
}
export default NavbarTop;
