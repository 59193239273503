import { axiosPrivate } from 'services/axios.private';

export class ChatApi {
    getVisitorMessages = async (connectionId) => {
        var res = await axiosPrivate.get(`/api/v2/chats/visitor/${connectionId}/messages`).catch((err) => {
            return err;
        });
        return res.data;
    };
}
