import React, { useState, useEffect, useRef, useContext } from 'react';
import { FiMicOff, FiMic, FiMaximize2, FiVideoOff, FiVideo, } from 'react-icons/fi';
import { MdBlurOff, MdCallEnd, MdLensBlur, MdOutlineCameraswitch } from 'react-icons/md';
import moment from 'moment';
import { useStopwatch } from 'react-timer-hook';
import { UserContext } from 'contexts';
import './style.scss';

function OptionWidgetVideo(props) {
    const [userContext, setUserContext] = useContext(UserContext);
    const { setEnableAudio, enableAudio } = props;
    const { setEnableVideoWidget, enableVideoWidget } = props;
    const { callRequest } = props;
    const { setBlurBackgroundSaleAgent, blurBackgroundSaleAgent } = props;
    const { isMinimizeWidgetVideo, setIsMinimizeWidgetVideo } = props;
    const { statusShareScreenClient } = props;
    // const [salesAgentControlShareScreenOfClient, setSalesAgentControlShareScreenOfClient] = useState();
    const [optionVideo, setOptionVideo] = useState(false);
    const [profileUrl, setProfileUrl] = useState("");
    const { salesAgentControlShareScreen, setSalesAgentControlShareScreen } = props;
    const { setSwitchCamera, switchCamera } = props;
    const { setIsMaximizeWidgetVideo, isMaximizeWidgetVideo } = props;
    const { isWaitData, isPlayer ,isVideoCallReady} = props;
    const { controlShareScreen } = props;
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: true });

    const { innerWidth } = props;
    const [styleMarginOptionOfVideoCall, setStyleMarginOptionOfVideoCall] = useState();
    const [styleMarginMinimizeOptionOfVideoCall, setStyleMarginMinimizeOptionOfVideoCall] = useState();
    const [styleTopOptionOfVideoCall, setStyleTopOptionOfVideoCall] = useState();
    const [stylePositionOptionOfVideoCall, setStylePositionOptionOfVideoCall] = useState();
    const [styleWidthOptionOfVideo, setStyleWidthOptionOfVideo] = useState();
    const [styleSizeIconOptionOfVideo, setStyleSizeIconOptionOfVideo] = useState();
    const [stylePaddingOptionOfVideoCall, setStylePaddingOptionOfVideoCall] = useState();
    const [styleDisplaySwitchCamera, setStyleDisplaySwitchCamera] = useState();

    const settingBlurBackground = () => {
        setOptionVideo(!optionVideo);
        setBlurBackgroundSaleAgent(!blurBackgroundSaleAgent);
    };

    const settingSwitchCamera = () => {
        setOptionVideo(optionVideo === false ? true : false);
        setSwitchCamera(switchCamera === false ? true : false);
    };

    const settingMaximizeWidgetVideo = () => {
        setOptionVideo(optionVideo === false ? true : false);
        setIsMaximizeWidgetVideo(isMaximizeWidgetVideo === false ? true : false);
    };

    const settingMinimizeWidgetVideo = () => {
        setOptionVideo(false);
        setIsMinimizeWidgetVideo(isMinimizeWidgetVideo === false ? true : false);
    };

    useEffect(() => {
        // if (isWaitData == true && isPlayer == true) {
        if (isWaitData) {
            start();
        } else {
            pause();
        }
    }, [isWaitData])

    useEffect(() => {
        if (innerWidth <= 640) {
            setStyleDisplaySwitchCamera('unset');
            if (isMaximizeWidgetVideo == true) {
                setStyleMarginOptionOfVideoCall('unset');
                setStylePositionOptionOfVideoCall('unset');
                setStyleWidthOptionOfVideo('23px');
                setStyleSizeIconOptionOfVideo('12px');
                setStyleMarginMinimizeOptionOfVideoCall('unset');
                setStylePaddingOptionOfVideoCall('unset');
            } else {
                setStyleMarginOptionOfVideoCall('');
                setStylePositionOptionOfVideoCall('');
                setStyleWidthOptionOfVideo('');
                setStyleSizeIconOptionOfVideo('12px');
                setStyleMarginMinimizeOptionOfVideoCall('');
                setStylePaddingOptionOfVideoCall('');
            }
        } else {
            setStyleDisplaySwitchCamera('hidden');
            if (isMaximizeWidgetVideo == true) {
                setStyleMarginOptionOfVideoCall('10px');
                setStylePositionOptionOfVideoCall('');
                setStyleTopOptionOfVideoCall('175px');
                setStyleWidthOptionOfVideo('35px');
                setStyleSizeIconOptionOfVideo('16px');
                setStyleMarginMinimizeOptionOfVideoCall('10px 0 0 -10px');
                setStylePaddingOptionOfVideoCall('0 25% 25% 25%');
            } else {
                setStylePositionOptionOfVideoCall('');
                setStyleTopOptionOfVideoCall('');
                setStyleMarginOptionOfVideoCall('');
                setStyleWidthOptionOfVideo('');
                setStyleSizeIconOptionOfVideo('12px');
                setStyleMarginMinimizeOptionOfVideoCall('');
                setStylePaddingOptionOfVideoCall('');
            }
        }
    }, [isMaximizeWidgetVideo, innerWidth]);

    useEffect(() => {
        if (window.innerWidth <= 640) {
            console.log('innerWidth 1', innerWidth);
            setStyleDisplaySwitchCamera('unset');
        } else {
            console.log('innerWidth 2', innerWidth);
            setStyleDisplaySwitchCamera('hidden');
        }
    });

    return (
        <>
            <div className="video-controller">
                <div className="topbar">
                    <div className="timer">
                        {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
                    </div>
                    {/* <div className="expander">
                        <div onClick={() => settingMaximizeWidgetVideo()}>
                            <FiMaximize2 size={14} />
                        </div>
                    </div> */}
                </div>

                <div className="bottom-bar">
                    <div
                        className="btn-icon"
                        onClick={() => { setEnableAudio(enableAudio ? false : true); }}
                    >
                        {enableAudio ? (
                            <FiMic size={14} />
                        ) : (
                            <FiMicOff size={14} />
                        )}
                    </div>
                    <div
                        className="btn-icon"
                        onClick={() => { setEnableVideoWidget(enableVideoWidget ? false : true); }}
                    >
                        {enableVideoWidget ? (
                            <FiVideo size={14} />
                        ) : (
                            <FiVideoOff size={14} />
                        )}
                    </div>
                    <div
                        className="btn-icon"
                        id="btn-bg-blur"
                        onClick={() => { settingBlurBackground(); }}
                        style={{ pointerEvents: isVideoCallReady ? 'auto' : 'none' }}
                    >
                        {blurBackgroundSaleAgent ? (
                            <MdLensBlur size={14} />
                        ) : (
                            <MdBlurOff size={14} />
                        )}
                    </div>
                    <div
                        className="btn-icon"
                        onClick={() => {
                            settingSwitchCamera();
                        }}
                        style={{
                            pointerEvents: isVideoCallReady ? 'auto' : 'none',
                            visibility: styleDisplaySwitchCamera
                        }}>
                        <MdOutlineCameraswitch size={14} />
                    </div>

                    {/* This button will hide because of the design */}
                    <div
                        className="btn-icon end-call d-none"
                        id="btn-endcall"
                        onClick={() => { callRequest('endcall') }}
                    >
                        <MdCallEnd size={14} color={'#fff'} />
                    </div>
                </div>
            </div>
        </>

        // <>
        //     <div
        //         className={`${isMaximizeWidgetVideo == true ? "positionMoreOptionVideo" : ""}`}
        //         style={{
        //             top: styleTopOptionOfVideoCall,
        //             position: stylePositionOptionOfVideoCall,
        //             padding: stylePaddingOptionOfVideoCall
        //         }}
        //     >
        //         <div
        //             className="spaceOptionOfVideoCall_1"
        //             style={{
        //                 margin: styleMarginOptionOfVideoCall
        //             }}
        //         >
        //             <div
        //                 className="buttonFunctionInWidgetVideo"
        //                 onClick={() => { setOptionVideo(optionVideo === false ? true : false); }}
        //                 style={{
        //                     width: styleWidthOptionOfVideo,
        //                     height: styleWidthOptionOfVideo
        //                 }}
        //             >
        //                 <img src='https://weface-assets.hqs.dev/img/more.png' style={{ width: styleSizeIconOptionOfVideo }} />
        //             </div>
        //             {optionVideo == true ? (
        //                 <>
        //                     <div
        //                         className="optionVideo"
        //                         style={{
        //                             top: isMaximizeWidgetVideo == true ? '45px' : '29px',
        //                             width: isMaximizeWidgetVideo == true ? '145px' : '120px',
        //                             padding: isMaximizeWidgetVideo == true ? '6px 10px 6px 10px' : '4px 10px 4px 10px'
        //                         }}
        //                     >
        //                         <div
        //                             className="fontBlurBg"
        //                             onClick={() => { settingBlurBackground(); }}
        //                             style={{
        //                                 fontSize: isMaximizeWidgetVideo == true ? '11px' : '10px'
        //                             }}
        //                         >
        //                             <img src='https://weface-assets.hqs.dev/img/blur-background.png'
        //                                 className="iconBlur"
        //                                 style={{
        //                                     width: isMaximizeWidgetVideo == true ? '14px' : '12px'
        //                                 }}
        //                             />
        //                             <span style={{ color: 'white' }}>Blur background</span>
        //                         </div>
        //                         <hr className="lineInOptionVideo" />
        //                         <div
        //                             className="fontSwitchCamera"
        //                             onClick={() => { settingSwitchCamera(); }}
        //                             style={{
        //                                 fontSize: isMaximizeWidgetVideo == true ? '11px' : '10px'
        //                             }}
        //                         >
        //                             <img src='https://weface-assets.hqs.dev/img/switch-camera.png'
        //                                 className="iconCamere"
        //                                 style={{
        //                                     width: isMaximizeWidgetVideo == true ? '14px' : '12px'
        //                                 }}
        //                             />
        //                             <span style={{ color: 'white' }}>Switch camera</span>
        //                         </div>
        //                         <hr className="lineInOptionVideo" />
        //                         <div
        //                             className="fontMaximize"
        //                             onClick={() => { settingMaximizeWidgetVideo(); }}
        //                             style={{
        //                                 fontSize: isMaximizeWidgetVideo == true ? '11px' : '10px'
        //                             }}
        //                         >
        //                             <img src='https://weface-assets.hqs.dev/img/expand-screen.png' className="iconMaximize" />
        //                             <span style={{ color: 'white' }}>Maximized widget</span>
        //                         </div>
        //                     </div>
        //                 </>
        //             ) : (
        //                 <></>
        //             )}
        //         </div>
        //     </div>
        //     <div
        //         className={`${isMaximizeWidgetVideo == true ? "positionMinimizeVideo" : ""}`}
        //         style={{
        //             top: styleTopOptionOfVideoCall,
        //             position: stylePositionOptionOfVideoCall,
        //             padding: stylePaddingOptionOfVideoCall
        //         }}
        //     >
        //         <div
        //             className="spaceOptionOfVideoCall_2"
        //             style={{
        //                 margin: styleMarginMinimizeOptionOfVideoCall
        //             }}
        //         >
        //             <div
        //                 className="buttonMinimizeWidgetVideoInWidgetVideo"
        //                 onClick={() => { settingMinimizeWidgetVideo(); }}
        //                 style={{
        //                     width: styleWidthOptionOfVideo,
        //                     height: styleWidthOptionOfVideo
        //                 }}
        //             >
        //                 <img src='https://weface-assets.hqs.dev/img/minimize.png' style={{ width: styleSizeIconOptionOfVideo }} />
        //             </div>
        //         </div>
        //     </div>
        //     {isMaximizeWidgetVideo == false ? (
        //         <>
        //             <div className="boxOptionOfVideoCall">

        //                 <div className="timer">
        //                     <span>{hours}:{minutes}:{seconds}</span>
        //                 </div>

        //                 <div className="controls">

        //                     <div
        //                         className="spaceIconInWidgetVideo"
        //                         onClick={() => { setEnableVideoWidget(enableVideoWidget === true ? false : true); }}
        //                     >
        //                         <div className="circleOfOptionInWidgetVideo">
        //                             {enableVideoWidget === false ? (
        //                                 <FiVideoOff className="iconInWidgetVideo sizeIconViseo" />
        //                             ) : (
        //                                 <FiVideo className="iconInWidgetVideo sizeIconViseo" />
        //                             )}
        //                         </div>
        //                     </div>
        //                     <div
        //                         className="spaceIconInWidgetVideo"
        //                         onClick={() => { setEnableAudio(enableAudio === true ? false : true); }}
        //                     >
        //                         <div className="circleOfOptionInWidgetVideo">
        //                             {enableAudio === false ? (
        //                                 <FiMicOff className="iconInWidgetVideo sizeIconViseo" />
        //                             ) : (
        //                                 <FiMic className="iconInWidgetVideo sizeIconViseo" />
        //                             )}
        //                         </div>
        //                     </div>
        //                     <div className="spaceIconInWidgetVideo">
        //                         <div
        //                             className="circleOfOptionInWidgetVideo"
        //                             style={{ cursor: 'no-drop', border: statusShareScreenClient == true ? "2px solid #105b93" : "unset" }}
        //                             // style={{ border: statusShareScreenClient == true ? "2px solid #105b93" : "unset" }}
        //                             onClick={() => { controlShareScreen(); }}
        //                         >
        //                             {/* <img src='https://weface-assets.hqs.dev/img/screen-share.png' style={{ width: '11px' }} /> */}
        //                         </div>
        //                     </div>
        //                     <div
        //                         className="spaceIconInWidgetVideo"
        //                         onClick={() => callRequest('endcall')}
        //                     >
        //                         <div className="endCallButton">
        //                             <img src='https://weface-assets.hqs.dev/img/end-call-small.png' style={{ width: '16px' }} />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </>
        //     ) : (
        //         <></>
        //     )}
        // </>
    );
}

export default OptionWidgetVideo;


