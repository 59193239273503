import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownOnchange, Icon, InputBox, LoadingProgress, TitleOfPage } from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { rolesUser } from 'utils/help';
import { IerrorMessage, Iusers } from 'interfaces';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useServices } from 'services';
import './style.scss';
import { isImageType, replaceMessages, toastProperty, headersContentType } from 'utils/help';
import { toast } from 'react-toastify';
import { UserContext } from 'contexts';
import { IoClose } from 'react-icons/io5';
import { MESSAGES_ALERT } from 'utils/constants';

interface ImanageUser {
    method: string;
}

const UserFormManagement = (prop: ImanageUser) => {
    const [titlePage] = useState<string>(prop.method);
    const [roleUser, setRoleUser] = useState(rolesUser());
    const [profileFile, setProfileFile] = useState<any>(null);
    const [alertOverSizeFile, setAlertOverSizeFile] = useState(null);
    const [profileImageSrc, setProfileImageSrc] = useState<any>(null);
    const [image, setImage] = useState<any>();
    const [firstname, setFirstname] = useState<string>();
    const [lastname, setLastname] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [phoneNumber, setUserPhoneNumber] = useState<string>();
    const [applicationId, setApplicationId] = useState<string>();
    const [displayFirstnameError, setDsplayFirstnameError] = useState(null);
    const [displayLastnameError, setDisplayLastnameError] = useState(null);
    const [displayEmailError, setDisplayEmailError] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [loginContext] = useContext(UserContext);
    const { api } = useServices();
    const { memberId } = useParams();
    const [isRoleReadyOnly, setIsRoleReadyOnly] = useState<boolean>(false);
    const [isEmailReadyOnly, setIsEmailReadyOnly] = useState<boolean>(false);

    const isUpdate = parseInt(memberId) ? true : false;
    const navigate = useNavigate();

    var hostUrl = "";
    if (process.env.NODE_ENV !== 'production') {
        hostUrl = process.env.REACT_APP_API_URL + "/";
    }
    const unix = Math.round(+new Date() / 1000);

    const cleanErrors = () => {
        setDsplayFirstnameError(null);
        setDisplayLastnameError(null);
        setDisplayEmailError(null);
    };

    const validateError = (errors: any) => {
        errors.forEach((error: IerrorMessage) => {
            if (error.propertyName === 'FirstName') {
                setDsplayFirstnameError(error.errorMessage);
            } else if (error.propertyName === 'LastName') {
                setDisplayLastnameError(error.errorMessage);
            } else if (error.propertyName === 'Email') {
                setDisplayEmailError(error.errorMessage);
            }
        });
    };

    const hasImage = () => {
        if (image || profileImageSrc) return true;
        else return false;
    };

    const addNewUserHandle = async (formData: any, userRoleId: number) => {
        setIsLoading(true);
        let res: any = await api.user.createUser(formData, {
            headers: headersContentType(),
            params: {
                hasImage: hasImage(),
                firstname: firstname,
                phoneNumber: phoneNumber,
                lastname: lastname,
                email: email,
                tenantID: loginContext.tenantId,
                userroleId: userRoleId
            }
        });
        let result = res;
        if (result?.errors && result.errors.length > 0) {
            validateError(result.errors);
            setIsLoading(false);
            toast.error(
                replaceMessages(MESSAGES_ALERT.SAVE_FAILED, `The user`),
                toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
            );
        } else {
            setIsLoading(false);
            toast.success(
                replaceMessages(MESSAGES_ALERT.SAVE_SUCCESS, `The user`),
                toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
            );
            setTimeout(() => {
                navigate('/users');
            }, 3000);
        }
    };

    const updateUserHandle = async (formData: any, userRoleId: number) => {
        setIsLoading(true);
        let res: any = await api.user.updateUser(formData, {
            headers: headersContentType(),
            params: {
                id: applicationId,
                hasImage: hasImage(),
                firstname: firstname,
                phoneNumber: phoneNumber,
                lastname: lastname,
                email: email,
                tenantID: loginContext.tenantId,
                userroleId: userRoleId
            }
        });

        let result = res;
        if (result?.errors && result.errors.length > 0) {
            validateError(result.errors);
            setIsLoading(false);
            toast.error(
                replaceMessages(MESSAGES_ALERT.UPDATE_FAILED, `the user`),
                toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
            );
        } else {
            setIsLoading(false);
            toast.success(
                replaceMessages(MESSAGES_ALERT.UPDATE_SUCCESS, `The user`),
                toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
            );
            setTimeout(() => {
                navigate('/users');
            }, 3000);
        }
    };

    const historyBack = (): void => {
        navigate('/users');
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        cleanErrors();
        setIsLoading(true);
        let userRoleId = parseInt(event.target[5].value);
        const formData = new FormData();
        formData.append('avatar', image);
        if (!isUpdate) {
            await addNewUserHandle(formData, userRoleId);
        } else {
            await updateUserHandle(formData, userRoleId);
        }
    };

    const uploadSingleFile = (e: any) => {
        let inputFile = e.target.files[0];
        if (inputFile && isImageType(inputFile.name)) {
            if (inputFile.size > 10000000) {
                //150000 Old
                if (inputFile && inputFile.current) inputFile.current.value = '';
                setAlertOverSizeFile('Your image is oversize, Please can you upload your image again.');
                toast.error(
                    replaceMessages(MESSAGES_ALERT.UPDATE_FAILED, `Your image`),
                    toastProperty('top-right', 1000, false, true, false, true, undefined, 'light')
                );
            } else {
                setImage(e.target.files[0]);
                setAlertOverSizeFile(null);
                setProfileFile(URL.createObjectURL(e.target.files[0]));
            }
        }
    };

    useEffect(() => {
        (async () => {
            let res: any = await api.user.getUser(memberId);
            let results: Iusers = res?.data;
            if (results) {
                setApplicationId(results.appId);
                setFirstname(results.firstname);
                setLastname(results.lastname);
                setEmail(results.username);
                setUserPhoneNumber(results.phoneNumber);
                let validateImage = isImageType(results.imageUrl);
                if (validateImage) {
                    setProfileImageSrc(results.imageUrl);
                }

                let selectedRole: any = [];
                roleUser.forEach((item: any) => {
                    if (item.id === results.userRole) {
                        item.defaultValue = true;
                        selectedRole.push(item);
                    } else {
                        selectedRole.push(item);
                    }
                });
                setRoleUser(selectedRole);
                if (parseInt(memberId) === loginContext.userId) {
                    setIsRoleReadyOnly(true);
                    setIsEmailReadyOnly(true);
                }
            }
        })();
    }, [memberId]);

    return (
        <>
            <NavbarTop />
            <NavbarLeft />
            <LoadingProgress isEnabled={isLoading} />
            <div className="content-page">
                <div className="container-fluid">
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <div className="container-header-manage-new-user">
                            <div className="title-user">
                                <TitleOfPage title={`${titlePage === 'add' ? 'ADD USER' : 'UPDATE USER'}`} />
                            </div>
                            <div>
                                <button type={`submit`} className="btn btn-primary">
                                    <span className="icon">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                    <span className="label">Save</span>
                                </button>
                            </div>
                        </div>

                        <div className="content-form-manage-user">
                            <div className="content-form-manage-user-left">
                                <span className="icon-user">
                                    <Icon color={`#6259CE`} size={22} icon="user-contact" className="" />
                                </span>
                                <span className="icon-text">Contact</span>
                            </div>
                            <div className="content-form-manage-user-right">
                                <div className="table-form-manage-user-label">
                                    <ul className="list-manage-new-user">
                                        <li className="first-row">
                                            <span className="label">Image:</span>{' '}
                                        </li>
                                        <li>
                                            <span>First Name: </span>
                                        </li>
                                        <li>
                                            <span>Last Name: </span>{' '}
                                        </li>
                                        <li>
                                            <span>User Role: </span>{' '}
                                        </li>
                                        <li>
                                            <span>Email: </span>{' '}
                                        </li>
                                        <li>
                                            <span>Mobile: </span>
                                        </li>
                                    </ul>
                                    <div className="footer-table-manage-new-user">
                                        <button type="reset" className="btn btn-light" onClick={historyBack}>
                                            <span className="icon">
                                                <IoClose size={20} />
                                            </span>
                                            <span className="label">Cancel</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="table-form-manage-user-input">
                                    <ul className="list-manage-new-user">
                                        <li className="first-row">
                                            {!profileFile && !profileImageSrc ? (
                                                <div
                                                    className="image-profile"
                                                    style={{
                                                        backgroundImage: `url(/assets/icons/empty-img.png)`
                                                    }}></div>
                                            ) : profileFile ? (
                                                <div
                                                    className="image-profile"
                                                    style={{ backgroundImage: `url(${profileFile})` }}></div>
                                            ) : profileImageSrc ? (
                                                <div
                                                    className="image-profile"
                                                    style={{
                                                        backgroundImage: `url(${hostUrl + profileImageSrc}?v=${unix})`
                                                    }}></div>
                                            ) : (
                                                ''
                                            )}
                                            <input
                                                accept="image/*"
                                                className={`icon-edit`}
                                                id="contained-button-file"
                                                onChange={uploadSingleFile}
                                                type="file"
                                            />
                                            {alertOverSizeFile ? (
                                                <div className="error-message">{alertOverSizeFile}</div>
                                            ) : (
                                                ''
                                            )}
                                        </li>
                                        <li>
                                            <InputBox
                                                typeInput={`text`}
                                                className={``}
                                                placeholder={`First Name*`}
                                                defaultValue={firstname}
                                                fucntionCustom={(e) => setFirstname(e.target.value)}
                                                isRequired={true}
                                                isReadOnly={false}
                                            />
                                            {displayFirstnameError ? (
                                                <div className="error-message">{displayFirstnameError}</div>
                                            ) : (
                                                ''
                                            )}
                                        </li>
                                        <li>
                                            <InputBox
                                                typeInput={`text`}
                                                className={``}
                                                placeholder={`Last Name*`}
                                                defaultValue={lastname}
                                                fucntionCustom={(e) => setLastname(e.target.value)}
                                                isRequired={true}
                                                isReadOnly={false}
                                            />
                                            {displayLastnameError ? (
                                                <div className="error-message">{displayLastnameError}</div>
                                            ) : (
                                                ''
                                            )}
                                        </li>
                                        <li>
                                            <DropdownOnchange
                                                data={roleUser}
                                                border={`1px solid #C5C5C5`} //Property
                                                optionOnChange={(e) => {}}
                                                isReadOnly={isRoleReadyOnly}
                                            />
                                        </li>
                                        <li>
                                            <InputBox
                                                typeInput={`email`}
                                                className={``}
                                                placeholder={`Email*`}
                                                defaultValue={email}
                                                fucntionCustom={(e) => setEmail(e.target.value)}
                                                isRequired={true}
                                                isReadOnly={isEmailReadyOnly}
                                            />
                                            {displayEmailError ? (
                                                <div className="error-message">{displayEmailError}</div>
                                            ) : (
                                                ''
                                            )}
                                        </li>
                                        <li>
                                            <InputBox
                                                typeInput={`text`}
                                                className={``}
                                                placeholder={`+62`}
                                                defaultValue={phoneNumber}
                                                fucntionCustom={(e) => setUserPhoneNumber(e.target.value)}
                                                isRequired={true}
                                                isReadOnly={false}
                                            />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default UserFormManagement;
