import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import insight from 'services/api/insight.service';
import Chart from 'react-apexcharts';
import GaugeChart from 'react-gauge-chart';
import { useServices } from 'services';
import { getScoreRecord } from 'utils/help';
import { IsentimentAnalysisClient } from 'interfaces';



const SentimentAnalysisClient = (props: IsentimentAnalysisClient) => {
    const navigate: any = useNavigate();
    const { api } = useServices();
    const [languageName, setLanguageName]: any = useState();
    const [languageConfidence, setLanguageConfidence]: any = useState();
    const [sentimentResult, setSentimentResult]: any = useState();
    const [sentimentResultScore, setSentimentResultScore]: any = useState();
    const [sentimentResultPercent, setSentimentResultPercent]: any = useState();
    const [sentimentPositive, setSentimentPositive]: any = useState();
    const [sentimentNeutral, setSentimentNeutral]: any = useState();
    const [sentimentNegative, setSentimentNegative]: any = useState();
    const [keyPhrases, setKeyPhrases]: any = useState();
    const [iconSentiment, setIconSentiment]: any = useState();

    useEffect(() => {
        if (props.clientStatusSentimentAnalysis == 2) {
            api.insight
                .getCallRecord(props.id)
                .then((result) => {
                    let sentimentStr = result.data.clientSentimentAnalysis;
                    let sentimentObj = JSON.parse(sentimentStr);
                    let resultCalculate = getScoreRecord(sentimentObj);

                    let resultLanguageConfidence: any = sentimentObj.LanguageConfidence * 100;
                    let resultPositive: any = sentimentObj.SentimentPositive * 100;
                    let resultNeutral: any = sentimentObj.SentimentNeutral * 100;
                    let resultNegative: any = sentimentObj.SentimentNegative * 100;

                    let positive: any = parseFloat(resultPositive).toFixed(0);
                    let neutral: any = parseFloat(resultNeutral).toFixed(0);
                    let negative: any = parseFloat(resultNegative).toFixed(0);

                    setSentimentPositive(positive);
                    setSentimentNeutral(neutral);
                    setSentimentNegative(negative);
                    setLanguageName(sentimentObj.LanguageName);
                    setLanguageConfidence(resultLanguageConfidence);
                    setSentimentResult(resultCalculate.sentimentResult);
                    setSentimentResultScore(resultCalculate.sentimentResultScore);
                    setSentimentResultPercent(resultCalculate.sentimentResultPercent / 100);

                    let arrayKeyPhrases = sentimentObj.KeyPhrases;
                    const reducedKeyPhrases = arrayKeyPhrases.reduce((result, item) => `${result}${item}, `, '');
                    setKeyPhrases(reducedKeyPhrases);
                })
                .catch((err) => {
                    // console.log("error: ", err);
                });
        }
    }, [props.clientStatusSentimentAnalysis]);

    setTimeout(() => {}, 1000);
    const optionsChartDonut: any = {
        chart: {
            type: 'donut'
        },
        series: [0, 0, 0],
        labels: ['Positive', 'Neutral', 'Negative'],
        dataLabels: {
            enabled: false
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    chart: {
                        width: 360
                    },
                    legend: {
                        show: false
                    }
                }
            }
        ],
        colors: ['#1abc9c', '#ffb818', '#e74c3c'],
        tooltip: {
            fillSeriesColor: false
        },
        legend: {
            show: true,
            fontWeight: '700',
            offsetY: 25,
            formatter: function (seriesName, opts) {
                return ['', opts.w.globals.series[opts.seriesIndex] + '%', seriesName];
            },
            markers: {
                width: 10,
                height: 10
            }
        }
    };
    return (
        <>
            <div className="col-lg-6 border-right">
                <h4 className="mb-3">Sentiment Analysis Client</h4>
                <h5>
                    <div className="caption">Language:</div>{' '}
                    <span>
                        {languageName} (Confidence: {languageConfidence + '%'})
                    </span>
                </h5>

                <div className="chart-wrapper">
                    <Chart
                        options={optionsChartDonut}
                        // series={[33, 44, 11]}
                        series={[
                            props.seriesChartDonutPositiveClient,
                            props.seriesChartDonutNeutralClient,
                            props.seriesChartDonutNegativeClient
                        ]}
                        type="donut"
                        width={360}
                        height={180}
                    />
                </div>
            </div>
            <div className="col-lg-6 total-score">
                <h4 className="mb-3">Total Score</h4>
                <h5>
                    <div className="caption">Score:</div> <span>{sentimentResultScore} / 5</span>
                </h5>
                <h5>
                    <div className="caption">Final:</div> <span className="text-warning">{sentimentResult}</span>
                </h5>

                <div className="chart-wrapper">
                    <GaugeChart
                        id="gauge-chart5"
                        style={{ width: 280 }}
                        nrOfLevels={0}
                        arcsLength={[1.67, 1.67, 1.67]}
                        colors={['#e74c3c', '#ffb818', '#1abc9c']}
                        percent={sentimentResultPercent}
                        arcPadding={0.01}
                        hideText={true}
                        arcWidth={0.2}
                    />
                </div>
            </div>
        </>
    );
};
export default SentimentAnalysisClient;
