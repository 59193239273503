import { IcardDropdown, Idropdown } from 'interfaces';
import React, { useState, useContext, useRef, useEffect } from 'react';
import './style.scss';

function CardDropdown(props: IcardDropdown) {

    const {
        border,
        data = [],
        isTitle,
        title,
        subTitle,
        colLg,
        colMd,
        colSm,
        value,
        onChange
    } = props;

    const [selectedValue, setSelectedValue] = useState<any>();
    // const [isTitleValue, setIsTitleValue] = useState<any>(true);
    // const [data, setData] = useState<any>([]);

    const classes = {
        selectOption: {
            border: border,
        },
    };

    useEffect(() => {
        if (data.length > 0) {
            let defaultItem = data.find((x: Idropdown) => x.defaultValue === true);
            if (defaultItem) {
                setSelectedValue(defaultItem.value);
            }
        }
    }, [data]);

    useEffect(() => {
        onChange(selectedValue);
    }, [selectedValue]);

    return (
        <>
            {isTitle ? (
                <div className="card container-card-custom card-dropdown">
                    <div className="card-body">
                        <div className="title">{title}</div>
                        <div className="sub-title">{subTitle}</div>
                        <div
                            className={`col-lg-${colLg} col-md-${colMd} col-sm-${colSm}  mb-3 dropdown-list`}
                        >
                            <select
                                style={classes.selectOption}
                                className="form-control"
                                defaultValue={value}
                                value={selectedValue}
                                onChange={(e: any) => {
                                    setSelectedValue(e.target.value);
                                }}
                            >
                                {data.map((item: Idropdown, index: number) => (
                                    <option key={index} value={item.value}>
                                        {item.text}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            ) : (
                <select
                    className="form-control"
                    style={classes.selectOption}
                    defaultValue={value}
                    value={selectedValue}
                    onChange={e => {
                        setSelectedValue(e.target.value);
                    }}
                >
                    {data && data.map((item: Idropdown, index: number) => (
                        <option key={index} value={item.value}>
                            {item.text}
                        </option>
                    ))}
                </select>
            )}
        </>
    );
}

export default CardDropdown;
