import React from "react";
import { BounceLoader, FadeLoader } from "react-spinners";
import "./style.scss";
import { VscDebugDisconnect } from "react-icons/vsc";

function ClientDisconnected() {

    return (
        <>
            <div className={`user-diconnected container`}>
                <div className="card">
                    <VscDebugDisconnect color={'#aaa'} size={54} />
                    <h3>Customer were disconnected</h3>
                </div>
            </div>

        </>
    );
}

export default ClientDisconnected;


