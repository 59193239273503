import { useState, useEffect } from 'react';

// storageKey: This is the name of our storage that gets used when we retrieve/save our persistent data.
// initialState: This is our default value, but only if the store doesn't exist, otherwise it gets overwritten by the store.
export default (storageKey, initialState) => {

  const [state, setInternalState] = useState(initialState);

  useEffect(() => {
    const data = sessionStorage.getItem(storageKey);

    const storageInBrowser = data && data !== 'undefined' ? JSON.parse(data) : null;

    if (process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // console.log('get persist state', storageKey, storageInBrowser);
    }

    if (storageInBrowser) {
      setInternalState(storageInBrowser);
    }
  }, []);

  // Create a replacement method that will set the state like normal, but that also saves the new state into the store.
  const setState = newState => {
    const data = typeof newState === 'object' ? JSON.stringify(newState) : newState;

    if (process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // console.log('set perist state', typeof newState, typeof data);
    }

    sessionStorage.setItem(storageKey, data);
    setInternalState(newState);
  };

  return [state, setState];
};
