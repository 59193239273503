import { languageSetting, replaceMessages, toastProperty, typeMessagesSetting } from 'utils/help';
import {
    ButtonForm,
    CardColorPicker,
    CardDropdown,
    CardInputText,
    CardInputTextArea,
    CardSetTime,
    CardToggleButton,
    PageSpinner,
    PreviewDesign,
    ToggleButton,
    TopContentHeader
} from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { useContext, useLayoutEffect, useState } from 'react';
import { useServices } from 'services';
import './style.scss';
import { toast } from 'react-toastify';

import { MESSAGES_ALERT } from 'utils/constants';
import { SettingContext } from 'contexts';

const Settings = () => {
    const { api } = useServices();
    const [loading, setLoading] = useState<boolean>(true);
    const [id, setId] = useState<number>(0);
    const [maxWaitTimeBeforeCallSalesAgentsSide, setMaxWaitTimeBeforeCallSalesAgentsSide] = useState<number>(0);
    const [maxWaitTimeBeforeCallClientSide, setMaxWaitTimeBeforeCallClientSide] = useState<number>(0);
    const [maxWaitTimeBeforeServiceCall, setMaxWaitTimeBeforeServiceCall] = useState<number>(0);
    const [isPlaysoundOnCall, setIsPlaysoundOnCall] = useState<boolean>(false);
    const [isSalesAgentCallRecord, setIsSalesAgentCallRecord] = useState<boolean>(false);
    const [isClientCallRecord, setIsClientCallRecord] = useState<boolean>(false);
    const [siteURL, setSiteURL] = useState<string>();
    const [languageConvertText, setLanguageConvertText] = useState('');
    const [languagesConvertText, setLanguagesConvertText] = useState(Array);
    const [inviteMessageType, setInviteMessageType] = useState('');
    const [typeSendMessagesSelected, setTypeSendMessagesSelected] = useState(Array);
    const [displayWeface, setDisplayWeface] = useState<boolean>(false);
    const [isEnabledExperimentMode, setIsEnabledExperimentMode] = useState<boolean>(true);
    const [widgetScript, setWidgetScript] = useState<string>('');
    const [experimentWebSite, setExperimentWebsite] = useState<string>('');
    const [experimentSearchParamName, setExperimentSearchParamName] = useState<string>('');
    const [chatGPTPurposeDescription, setChatGPTPurposeDescription] = useState<string>('');
    const [settingContext , setSettingContext] = useContext(SettingContext)

    const [themeId, setThemeId] = useState<number>(0);
    const [videoCallButtonColor, setVideoCallButtonColor] = useState('');
    const [videoCallButtonTextColor, setVideoCallButtonTextColor] = useState('');
    const [videoCallIconColor, setVideoCallIconColor] = useState('');
    const [aiButtonColor, setAIButtonColor] = useState('');
    const [aiButtonTextColor, setAIButtonTextColor] = useState('');
    const [aiButtonIconColor, setAIButtonIconColor] = useState('');
    const [captionTextColor, setCaptionTextColor] = useState('');
    const [backgroundColor, setBackgroundColor] = useState('');
    const [backgroundUrl, setBackgroundUrl] = useState('');

    let textArea: any = document.getElementById('script-copy');
    let htmlInner: any = null;
    if (textArea) {
        htmlInner = textArea.value;
    }

    const copyContent = async (e: any) => {
        await navigator.clipboard.writeText(htmlInner);
        toast.success(
            replaceMessages(MESSAGES_ALERT.COPY_SUCCESS, `WeFace Script`),
            toastProperty('top-right', 2000, false, true, false, true, undefined, 'light')
        );
    };

    useLayoutEffect(() => {
        setLoading(true);
        // call settings
        let settingRes;
        (async () => {
            settingRes = await api.callSetting.getCallSetting();
            setId(settingRes.id);
            setMaxWaitTimeBeforeCallSalesAgentsSide(settingRes.timeoutNotAnsweringCall);
            setMaxWaitTimeBeforeCallClientSide(settingRes.timeout2);
            setMaxWaitTimeBeforeServiceCall(settingRes.timeout3);
            setIsPlaysoundOnCall(settingRes.isPlaysoundOnCall);
            setIsSalesAgentCallRecord(settingRes.salesAgentCallRecord);
            setIsClientCallRecord(settingRes.clientCallRecord);
            setDisplayWeface(settingRes.isDisplayWeface);
            setIsEnabledExperimentMode(settingRes.experimentMode);
            setWidgetScript(settingRes.widgetScript);
            setExperimentWebsite(settingRes.experimentWebsite);
            setExperimentSearchParamName(settingRes.experimentSearchParamName);
            setChatGPTPurposeDescription(settingRes.chatGPTPurposeDescription);

            let resLanguages: any = languageSetting().map((x: any) => {
                if (x.value === settingRes.languageConvertText) {
                    x.defaultValue = true;
                    return x;
                }
                x.defaultValue = false;
                return x;
            });
            setLanguagesConvertText(resLanguages);
            setLanguageConvertText(settingRes.languageConvertText);
            setSiteURL(settingRes.siteURL);

            let resTypeMessage: any = typeMessagesSetting().map((x: any) => {
                if (x.value === settingRes.typeSendMessages) {
                    x.defaultValue = true;
                    return x;
                }
                x.defaultValue = false;
                return x;
            });
            setTypeSendMessagesSelected(resTypeMessage);
        })();

        // widget theme options
        let themeRes;
        (async () => {
            themeRes = await api.callSetting.getThemeOption();
            setThemeId(themeRes.id);
            setVideoCallButtonColor(themeRes.videoCallButtonColor);
            setVideoCallButtonTextColor(themeRes.videoCallButtonTextColor);
            setVideoCallIconColor(themeRes.videoCallIconColor);
            setAIButtonColor(themeRes.aiButtonColor);
            setAIButtonTextColor(themeRes.aiButtonTextColor);
            setAIButtonIconColor(themeRes.aiButtonIconColor);
            setCaptionTextColor(themeRes.captionTextColor);
            setBackgroundColor(themeRes.backgroundColor);
            setBackgroundUrl(themeRes.backgroundUrl);
        })();

        const loadingInterval = setInterval(() => {
            if (settingRes && themeRes) {
                clearInterval(loadingInterval);
                setLoading(false);
            }
        }, 1000);
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        setLoading(true);

        const settingData = {
            id,
            isDisplayWeface: displayWeface,
            timeoutNotAnsweringCall: +maxWaitTimeBeforeCallSalesAgentsSide,
            timeout2: +maxWaitTimeBeforeCallClientSide,
            timeout3: +maxWaitTimeBeforeServiceCall,
            isPlaysoundOnCall: isPlaysoundOnCall,
            salesAgentCallRecord: isSalesAgentCallRecord,
            clientCallRecord: isClientCallRecord,
            languageConvertText: languageConvertText,
            siteURL: siteURL,
            typeSendMessages: +inviteMessageType,
            chatGPTPurposeDescription: chatGPTPurposeDescription
        };

        await api.callSetting.updateCallSetting(settingData);
        const themeData = {
            themeId,
            videoCallButtonColor,
            videoCallButtonTextColor,
            videoCallIconColor,
            aiButtonColor,
            aiButtonTextColor,
            aiButtonIconColor,
            captionTextColor,
            backgroundColor,
            backgroundUrl
        };

        console.log(themeData);

        toast.success(
            replaceMessages(MESSAGES_ALERT.SAVE_SUCCESS, `Settings`),
            toastProperty('top-right', 2000, false, true, false, true, undefined, 'light')
        );
        setTimeout(async () => {
            setLoading(false);
            const settingRes = await api.callSetting.getCallSetting();
            setSettingContext(settingRes);
        }, 500);
    };

    return (
        <>
            <NavbarTop />
            <NavbarLeft />
            {loading && <PageSpinner />}

            <div className="content-page setting-app">
                <div className="container-fluid">
                    <TopContentHeader
                        titleOfPage={`SETTINGS`}
                        isSearch={false}
                        placeholderSearch={``}
                        hasSymbolButton={false}
                        buttonName={``}
                        symbolButton={``}
                        functionButton={() => { }}
                    />

                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-sm-2">
                                <div className="nav flex-column ">
                                    <a
                                        className="nav-link show active"
                                        data-toggle="pill"
                                        href="#general"
                                        role="tab"
                                        aria-selected="true">
                                        General
                                    </a>
                                    <a
                                        className="nav-link"
                                        data-toggle="pill"
                                        href="#chat"
                                        role="tab"
                                        aria-selected="false">
                                        AI-Assistant
                                    </a>

                                    {/* <a
                                        className="nav-link"
                                        data-toggle="pill"
                                        href="#widget"
                                        role="tab"
                                        aria-selected="false">
                                        Widget
                                    </a> */}
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="tab-content">
                                    <div id="general" className="tab-pane fade active show">
                                        <CardToggleButton
                                            title="Availability"
                                            subTitle="Open the portal for incoming calls"
                                            height={190}
                                            elementToggle={
                                                <ToggleButton
                                                    defaultChecked={displayWeface}
                                                    onToggle={(e) => setDisplayWeface(e)}
                                                />
                                            }
                                            preview={<PreviewDesign cssButtonProperty={null} isShow={displayWeface} />}
                                        />

                                        <CardSetTime
                                            title="Max Wait Time Before Call Is Abandon On"
                                            focusText="Sales Agents Side"
                                            value={maxWaitTimeBeforeCallSalesAgentsSide}
                                            onChange={setMaxWaitTimeBeforeCallSalesAgentsSide}
                                            unitTime={`Seconds`}
                                            type={`number`}
                                        />
                                        <CardSetTime
                                            title="Max Wait Time Before Call Is Abandon On"
                                            focusText="Client Side"
                                            value={maxWaitTimeBeforeCallClientSide}
                                            onChange={setMaxWaitTimeBeforeCallClientSide}
                                            unitTime={`Seconds`}
                                            type={`number`}
                                        />
                                        <CardSetTime
                                            title="Max Wait Time Before Service Call Is Abandon On Client Side"
                                            focusText=""
                                            value={maxWaitTimeBeforeServiceCall}
                                            onChange={setMaxWaitTimeBeforeServiceCall}
                                            unitTime={`Seconds`}
                                            type={`number`}
                                        />
                                        <CardToggleButton
                                            title="Play Sound"
                                            subTitle={`Play Sound for incoming call`}
                                            elementToggle={
                                                <ToggleButton
                                                    defaultChecked={isPlaysoundOnCall}
                                                    onToggle={(e) => setIsPlaysoundOnCall(e)}
                                                />
                                            }
                                            preview={null}
                                        />

                                        <CardToggleButton
                                            title="Recordings For Sales Agent"
                                            subTitle="Enable sound recordings for sales agent"
                                            elementToggle={
                                                <ToggleButton
                                                    defaultChecked={isSalesAgentCallRecord}
                                                    onToggle={(e) => setIsSalesAgentCallRecord(e)}
                                                />
                                            }
                                            preview={null}
                                        />
                                        <CardToggleButton
                                            title="Recordings For Clients"
                                            subTitle="Enable sound recordings for clients"
                                            elementToggle={
                                                <ToggleButton
                                                    defaultChecked={isClientCallRecord}
                                                    onToggle={(e) => setIsClientCallRecord(e)}
                                                />
                                            }
                                            preview={null}
                                        />

                                        <CardDropdown
                                            title="Language Convert Text"
                                            subTitle="Enable sound recordings for clients"
                                            colSm={12}
                                            colMd={6}
                                            colLg={4}
                                            data={languagesConvertText}
                                            value={languageConvertText}
                                            onChange={setLanguageConvertText}
                                            isTitle={true}
                                            border={`1px solid`} //Property
                                        />
                                        <CardInputText
                                            title="WeFace Script"
                                            subTitle="Copy and insert WeFace script"
                                            colSm={12}
                                            colMd={6}
                                            colLg={12}
                                            text={widgetScript}
                                            typeInput={`text`}
                                            isReadOnly={true}
                                            functionClick={(e: any) => copyContent(e)}
                                            setElementId={`script-copy`}
                                        />

                                        <CardInputText
                                            title="Sites Url"
                                            subTitle="URL script location"
                                            colSm={12}
                                            colMd={6}
                                            colLg={4}
                                            text={siteURL}
                                            onChange={value => setSiteURL(value)}
                                            typeInput={`text`}
                                            isReadOnly={false}
                                            setElementId={``}
                                        />
                                        <CardDropdown
                                            title="Message Type For Service Call"
                                            subTitle="Confirmation type"
                                            colSm={12}
                                            colMd={6}
                                            colLg={4}
                                            data={typeSendMessagesSelected}
                                            value={inviteMessageType}
                                            onChange={setInviteMessageType}
                                            isTitle={true}
                                            border={`1px solid`} //Property
                                        />
                                    </div>
                                    <div id="experimental" className="tab-pane fade">
                                        <CardToggleButton
                                            title="Experiment"
                                            subTitle="Open speech-bot searching"
                                            elementToggle={
                                                <ToggleButton
                                                    defaultChecked={isEnabledExperimentMode}
                                                    onToggle={(e) => setIsEnabledExperimentMode(e)}
                                                />
                                            }
                                        />
                                        <CardInputText
                                            title="Website (Experiment)"
                                            subTitle="URL Website for search"
                                            colSm={12}
                                            colMd={6}
                                            colLg={4}
                                            text={experimentWebSite}
                                            typeInput={`text`}
                                            isReadOnly={false}
                                            functionClick={() => { }}
                                            setElementId={``}
                                        />
                                        <CardInputText
                                            title="Search Parameter (Experiment)"
                                            subTitle="Search parameter name"
                                            colSm={12}
                                            colMd={6}
                                            colLg={4}
                                            text={experimentSearchParamName}
                                            typeInput={`text`}
                                            isReadOnly={false}
                                            functionClick={() => { }}
                                            setElementId={``}
                                        />
                                    </div>
                                    <div id="chat" className="tab-pane fade">
                                        <CardInputTextArea
                                            title="Subject for AI "
                                            subTitle="Inform Chat GPT about the following"
                                            colSm={12}
                                            colMd={6}
                                            colLg={12}
                                            text={chatGPTPurposeDescription}
                                            typeInput={`text`}
                                            isReadOnly={false}
                                            setElementId={``}
                                            onChange={value => setChatGPTPurposeDescription(value)}
                                            placeholderText={`Eg. All answers asked within this conversation should be limited to electronics only. If someone askes something totally unrelated, then don't answer with anything outside the domain of electronics`}
                                        />
                                    </div>
                                    <div id="widget" className="tab-pane fade">

                                        {/* Video Call Theme */}

                                        <h6 className="ml-3 text-primary">Video Call Button</h6>

                                        <div className="row">
                                            <div className="col-sm-4">
                                                <CardColorPicker
                                                    color={videoCallButtonColor}
                                                    title="Video Call Button Color"
                                                    subtitle="Select background color for video call button"
                                                    onChange={setVideoCallButtonColor}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <CardColorPicker
                                                    color={videoCallButtonTextColor}
                                                    title="Video Call Button Text Color"
                                                    subtitle="Select color for video call button text"
                                                    onChange={setVideoCallButtonTextColor}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <CardColorPicker
                                                    color={videoCallIconColor}
                                                    title="Video Call Icons Color"
                                                    subtitle="Select color for video call button icons"
                                                    onChange={setVideoCallIconColor}
                                                />
                                            </div>
                                        </div>

                                        {/* AI Assistant Theme */}

                                        <h6 className="ml-3 text-primary">AI Assistant Button</h6>

                                        <div className="row">
                                            <div className="col-sm-4">
                                                <CardColorPicker
                                                    color={aiButtonColor}
                                                    title="AI Assistant Button Color"
                                                    subtitle="Select background color for AI Assistant button"
                                                    onChange={setAIButtonColor}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <CardColorPicker
                                                    color={aiButtonTextColor}
                                                    title="AI Assistant Button Text Color"
                                                    subtitle="Select color for ai assistant button text"
                                                    onChange={setAIButtonTextColor}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                <CardColorPicker
                                                    color={aiButtonIconColor}
                                                    title="AI Assistant Icons Color"
                                                    subtitle="Select color for ai assistant button icons"
                                                    onChange={setAIButtonIconColor}
                                                />
                                            </div>
                                        </div>

                                        {/* More */}

                                        <h6 className="ml-3 text-primary">More Settings</h6>

                                        <div className="row">
                                            <div className="col-sm-4">

                                                {/* Caption text theme */}
                                                <CardColorPicker
                                                    color={captionTextColor}
                                                    title="Caption Text Color"
                                                    subtitle="Select color for the caption text"
                                                    onChange={setCaptionTextColor}
                                                />
                                            </div>
                                            <div className="col-sm-4">
                                                {/* Background color */}
                                                <CardColorPicker
                                                    color={backgroundColor}
                                                    title="Main Background Color"
                                                    subtitle="Select color for the widget background"
                                                    onChange={setBackgroundColor}
                                                />
                                            </div>
                                            <div className="col-sm-4">

                                            </div>
                                        </div>



                                        <div style={{ height: '200px' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container-submit">
                            <div className="container-left"></div>
                            <div className="container-right">
                                <ButtonForm
                                    title="Submit"
                                    className=""
                                    clickFunction={(e) => { }}
                                    isDisabled={false}
                                    typeBtn={`submit`}
                                />
                                {` `}
                                <ButtonForm
                                    title="Cancel"
                                    className=""
                                    clickFunction={(e) => { }}
                                    isDisabled={false}
                                    typeBtn={`reset`}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Settings;
