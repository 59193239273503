import moment from 'moment';
import { useEffect, useState } from 'react';
import {useParams } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { IoDownloadOutline } from 'react-icons/io5';
import { useServices } from 'services';
import { IsaleAgentSoundRecord } from 'interfaces';
import { toast } from 'react-toastify';
import { replaceMessages, toastProperty } from 'utils/help';
import { MESSAGES_ALERT } from 'utils/constants';

const SaleAgentSoundRecord = (props: IsaleAgentSoundRecord) => {
    const { id } = useParams();
    const { api } = useServices();
    const [dateCallRecordCard, setDateCallRecordCard]: any = useState();
    const handleDownload = async (event: any) => {
        event.preventDefault();
        api.insight
            .downloadSoundFile(props.callQueuesId, props.salesAgentAudioFileId)
            .then((res: any) => {
                fileDownload(res.data, props.salesAgentAudioFileId + '.mkv');
            })
            .catch((err) => {
                alertMessageError("Sale Agent");
            });
    };

    useEffect(() => {
        if (id) {
            api.insight
                .getCallRecord(id)
                .then((result: any) => {
                    setDateCallRecordCard(moment(result.data.startTime).format('DD MMM  YYYY'));
                })
                .catch((err) => {
                    alertMessageError("Sale Agent");
                });
        }
    }, [id]);

    const alertMessageError = (userType : string) =>{
        toast.error(
            replaceMessages(MESSAGES_ALERT.DOWNLOAD_SOUND_FAILED, userType),
            toastProperty('top-left', 2000, false, true, false, true, undefined, 'light')
        );
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <h4>Sales Agent Sound Record</h4>
                    <span>Record Date: {props.salesAgentStatusRecord != 0 ? dateCallRecordCard : '-'} </span>
                    <p>Download sound record</p>
                    <button
                        className="btn btn-primary"
                        onClick={handleDownload}
                        disabled={props.salesAgentStatusRecord != 0 ? false : true}>
                        <IoDownloadOutline size={18} />
                        Download
                    </button>
                </div>
            </div>
        </>
    );
};

export default SaleAgentSoundRecord;
