import { useEffect, useState } from 'react';
import './style.scss'
import ResponsivePagination from 'react-responsive-pagination';


interface Ipagination {
    pageNumber : number;
    totalpages : number;
    onPageChanged :any;
    position : any;

}

const PaginationResponsiveCustom = (prop:Ipagination) =>{
 
    return (
        <>
        <div style={{textAlign : prop.position}}>
          <ResponsivePagination
                current={prop.pageNumber}
                total={prop.totalpages}
                onPageChange={prop.onPageChanged}
            />
        </div>
        </>
    )

}

export default PaginationResponsiveCustom