import { useState } from 'react';
import './style.scss';
import { useNavigate, NavLink } from 'react-router-dom';
import { useServices } from 'services';
import queryString from 'query-string';
import { replaceMessages, toastProperty } from 'utils/help';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { MESSAGES_ALERT } from 'utils/constants';

const bgImage = `${process.env.PUBLIC_URL}/assets/images/bg-image.jpg`;
const logoImage = `${process.env.PUBLIC_URL}/assets/images/logo-weface.png`;

function ComfirmPassword() {
    
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const parsedqs: any = queryString.parse(window.location.search);
    const code: string = parsedqs.code.replaceAll(' ', '+');
    const navigate = useNavigate();
    const { api } = useServices();
    const updatePassword = (event: any) => {
        event.preventDefault();
        if (confirmPassword === newPassword) {
            api.auth
                .resetPassword(confirmPassword, parsedqs.email, code)
                .then((result) => {
                  console.log("result", result);
                  let getResultError = result?.response?.data.errors
                    if (getResultError?.length === 0 || !getResultError) {
                        toast.success(
                            replaceMessages(MESSAGES_ALERT.SAVE_SUCCESS, `new password`),
                            toastProperty('top-right', 3000, false, true, false, true, undefined, 'light')
                        );
                        setTimeout(() => {
                            navigate('/update-password', { replace: true });
                        }, 3000);
                    } else {
                      getResultError.forEach((item: any) => {
                            toast.error(
                                replaceMessages(
                                    MESSAGES_ALERT.SAVE_FAILED,
                                    `new password owing to ${item}`
                                ),
                                toastProperty('top-right', 5000, false, true, false, true, undefined, 'light')
                            );
                        });
                    }
                })
                .catch((errors) => {
                    console.log('errors', errors);
                });
        } else {
            alert('Your passwords do not match, could you please enter a new password?');
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="wrapper bg-login" style={{ backgroundImage: `url(${bgImage})` }}>
                <section className="login-content">
                    <div className="logo" style={{ backgroundImage: `url(${logoImage})` }}></div>

                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="card-container">
                                <div className="card auth-card">
                                    <div className="card-body p-0">
                                        <div className="d-flex align-items-center auth-content">
                                            <div className="col-lg-12 align-self-center">
                                                <div className="title-text-top-container">
                                                    <h3 className="mb-5 title-text-top">Enter a New Password</h3>
                                                    <form onSubmit={updatePassword}>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="floating-label form-group">
                                                                    <input
                                                                        className="floating-input form-control input-email-to-email"
                                                                        type="password"
                                                                        // onChange={e => setUsername(e.target.value)}
                                                                        placeholder="Desired Password"
                                                                        value={newPassword}
                                                                        onChange={(e) => setNewPassword(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="floating-label form-group">
                                                                    <input
                                                                        className="floating-input form-control input-email-to-email"
                                                                        type="password"
                                                                        // onChange={e => setUsername(e.target.value)}
                                                                        placeholder="Comfirm Password"
                                                                        value={confirmPassword}
                                                                        onChange={(e) =>
                                                                            setConfirmPassword(e.target.value)
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <button type="submit" className="button-update-password ">
                                                            Update Password
                                                        </button>
                                                        <div className="mt-3 text-center">
                                                            <NavLink to={`/login`} className="back-to-login">
                                                                Back to Login
                                                            </NavLink>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default ComfirmPassword;
