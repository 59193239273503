import React from 'react';
import './style.scss';

import { NavLink } from 'react-router-dom';
import CardSuggest from 'components/card-suggest';

const bgImage = `${process.env.PUBLIC_URL}/assets/images/bg-image.jpg`;
const logoImage = `${process.env.PUBLIC_URL}/assets/images/logo-weface.png`;

function Suggest(prop: any) {
    return (
        <>
            <div className="wrapper bg-login" style={{ backgroundImage: `url(${bgImage})` }}>
                <section className="login-content">
                    <div className="logo" style={{ backgroundImage: `url(${logoImage})` }}></div>

                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="card-container">
                                <div className="card auth-card">
                                    <div className="card-body p-0">
                                        <div className="d-flex align-items-center auth-content">
                                            <div className="col-lg-12 align-self-center">
                                                <div className="title-text-top-container">
                                                    {prop.event === 'update' ? (
                                                        <CardSuggest
                                                            title="Forget Your Password"
                                                            image="check.png"
                                                            caption="Your password has been sucessfully reset? You can now log in with your new password."
                                                        />
                                                    ) : (
                                                        <CardSuggest
                                                            title="Forget Your Password"
                                                            image="check.png"
                                                            caption="We've just sent instructions to reset your password, please check your email"
                                                        />
                                                    )}

                                                    <div className="mt-3 text-center">
                                                        <NavLink
                                                            to={`/login`}
                                                            className="back-to-login"
                                                        >
                                                            Back to Login
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Suggest;
