
import RingTone from 'components/interactis/sounds/happy-bells.mp3';
import { useEffect, useRef, useState } from 'react';

export const useAudio = () => {
    // const [sound] = useState(typeof Audio !== 'undefined' && new Audio(RingTone));
    const sound = useRef(typeof Audio !== 'undefined' && new Audio(RingTone));

    useEffect(() => {
        sound.current.loop = true;
        sound.current.volume = 0.5;
        sound.current.currentTime = 0;
    }, []);

    // function playRingTone() {
    //     console.log('play ring tone', sound);
    //     sound.current.loop = true;
    //     sound.current.volume = 0.5;
    //     sound.current.currentTime = 0;
    //     sound.current.play();
    // }

    // function pauseRingTone() {
    //     console.log('try to pause ring tone', sound)
    //     sound.current.loop = false;
    //     sound.current.currentTime = 0;
    //     sound.current.pause();
    // }

    // return { playRingTone, pauseRingTone };
    return sound.current;
};
