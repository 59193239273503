import Icon from 'components/icon-moon';
import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { UserContext } from 'contexts';
import './style.scss';
import { USER_ROLE } from 'utils/constants';

const logoImage = `${process.env.PUBLIC_URL}/assets/images/weface-logo.png`;

function NavbarLeft(prop: any) {
    const [userContext] = useContext(UserContext);
    const {userRole} = userContext;

    const location: string = window.location.pathname;
    const pathUrl: any = location.replace('/', '');
    const [colorHome, setColorHome] = useState('#d3d3d3');
    const [colorLive, setColorLive] = useState('#d3d3d3');
    const [colorCalendar, setColorCalendar] = useState('#d3d3d3');
    const [colorInsight, setColorInsight] = useState('#d3d3d3');
    const [colorTriggers, setColorTriggers] = useState('#d3d3d3');
    const [colorSettings, setColorSettings] = useState('#d3d3d3');
    const [colorSupport, setColorSupport] = useState('#d3d3d3');
    const [colorUsers, setColorUsers] = useState('#d3d3d3');

    useEffect(() => {
        if (!pathUrl || pathUrl.includes('dashboard')) {
            let element: any = document.getElementById('dashboard');
            setColorHome('#6259ce');
            element?.setAttribute('class', 'container-menu-left active');
        }
        if (pathUrl.includes('live')) {
            let element: any = document.getElementById('live');
            setColorLive('#6259ce');
            element?.setAttribute('class', 'container-menu-left active');
        }
        if (pathUrl.includes('users')) {
            let element: any = document.getElementById('users');
            setColorUsers('#6259ce');
            element?.setAttribute('class', 'container-menu-left active');
        }
        if (pathUrl.includes('calendar-schedule')) {
            let element: any = document.getElementById('calendar-schedule');
            setColorCalendar('#6259ce');
            element?.setAttribute('class', 'container-menu-left active');
        }
        if (pathUrl.includes('insight')) {
            let element: any = document.getElementById('insight');
            setColorInsight('#6259ce');
            element?.setAttribute('class', 'container-menu-left active');
        }
        if (pathUrl.includes('triggers')) {
            let element: any = document.getElementById('triggers');
            setColorTriggers('#6259ce');
            element?.setAttribute('class', 'container-menu-left active');
        }
        if (pathUrl.includes('settings')) {
            let element: any = document.getElementById('settings');
            setColorSettings('#6259ce');
            element?.setAttribute('class', 'container-menu-left active');
        }
    }, [colorHome, colorLive, colorCalendar, colorUsers, colorInsight, colorTriggers, colorSettings, colorSupport]);

    return (
        <>
            <div className="iq-sidebar  sidebar-default ">
                <div className="warpmenu-icon">
                    <img src={logoImage} className="icon-weface" alt="logo" />
                </div>
                <hr className="warpmenu-icon-hr" />
                <div className="data-scrollbar" data-scroll="1">
                    <nav className="iq-sidebar-menu">
                        <ul id="iq-sidebar-toggle" className="iq-menu">
                            <li className={`container-menu-left`} id="dashboard">
                                <NavLink to="/">
                                    <Icon color={colorHome} size={35} icon="home" className="icon-dashboard " />
                                    <div className="caption-menu-left ">
                                        <p className="container-menu-left-text">Home</p>
                                    </div>
                                </NavLink>
                            </li>
                            <li className={`container-menu-left `} id="live">
                                <NavLink to="/live">
                                    <Icon color={`${colorLive}`} size={35} icon="live" />
                                    <div className="caption-menu-left ">
                                        <p className="container-menu-left-text">live</p>
                                    </div>
                                </NavLink>
                            </li>
                            {USER_ROLE.SUPERADMIN === userRole || USER_ROLE.ADMIN === userRole ? (
                                <li className="" id="users">
                                    <NavLink to="/users">
                                        <Icon color={`${colorUsers}`} size={35} icon="users" />
                                        <div className="caption-menu-left ">
                                            <p className="container-menu-left-text">Users</p>
                                        </div>
                                    </NavLink>
                                </li>
                            ) : (
                                ''
                            )}

                            <li className="" id="calendar-schedule">
                                <NavLink to="/calendar-schedule">
                                    <Icon color={`${colorCalendar}`} size={35} icon="calendar" />
                                    <div className="caption-menu-left ">
                                        <p className="container-menu-left-text">Calendar</p>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="" id="insight">
                                <NavLink to="/insight">
                                    <Icon color={`${colorInsight}`} size={35} icon="pie" />
                                    <div className="caption-menu-left ">
                                        <p className="container-menu-left-text">Insight+</p>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="" id="triggers">
                                <NavLink to="/triggers">
                                    <Icon color={`${colorTriggers}`} size={35} icon="bell" />
                                    <div className="caption-menu-left ">
                                        <p className="container-menu-left-text">Triggers</p>
                                    </div>
                                </NavLink>
                            </li>
                            <li className="" id="settings">
                                <NavLink to="/settings">
                                    <Icon color={`${colorSettings}`} size={35} icon="setting" />
                                    <div className="caption-menu-left ">
                                        <p className="container-menu-left-text">Settings</p>
                                    </div>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    );
}
export default NavbarLeft;
