import moment from 'moment';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useServices } from 'services';
import { IclientTextConversation } from 'interfaces';
import ViewTextCallRecord from '../../insight-view-text';
import { PageSpinner } from 'components';
import { replaceMessages, toastProperty } from 'utils/help';
import { toast } from 'react-toastify';
import { MESSAGES_ALERT, USER_TYPE } from 'utils/constants';

const ClientConvertToText = (props: IclientTextConversation) => {
    const { api } = useServices();

    const [showModalViewTextCallRecord, setShowModalViewTextCallRecord]: any = useState(false);
    const [loadingConvertText, setLoadingConvertText]: any = useState(false);
    const [loadingSentiment, setLoadingSentiment]: any = useState(false);
    const [dateCallRecordCard, setDateCallRecordCard]: any = useState();
    const [buttonStatusConvertText, setButtonStatusConvertText]: any = useState(true);
    const [buttonStatusViewText, setButtonStatusViewText]: any = useState(true);
    const [buttonStatusSentiment, setButtonStatusSentiment]: any = useState(true);
    const [statusConvertText, setStatusConvertText]: any = useState();
    const [statusSentiment, setStatusSentiment]: any = useState();

    const handleViewTextCallRecord = () => {
        setShowModalViewTextCallRecord(true);
    };
    const handleModalClose = () => {
        setShowModalViewTextCallRecord(false);
    };

    useEffect(() => {
        if (props.clientStatusRecord != 0 && props.clientStatusConvertText == 0) {
            setButtonStatusConvertText(false);
        }
        if (props.clientStatusConvertText == 1 || statusConvertText == 1) {
            setLoadingConvertText(true);
            setButtonStatusConvertText(true);
            setButtonStatusViewText(true);
            setButtonStatusSentiment(true);
        }
        if (props.clientStatusConvertText == 2 || statusConvertText == 2) {
            setLoadingConvertText(false);
            setButtonStatusConvertText(true);
            setButtonStatusViewText(false);
            if (props.clientStatusSentimentAnalysis == 0) {
                setButtonStatusSentiment(false);
            }
        }
        if (props.clientStatusConvertText == 4) {
            setLoadingConvertText(false);
            setButtonStatusConvertText(false);
        }
        if (props.clientStatusConvertText != 4 && statusConvertText == 4) {
            setLoadingConvertText(false);
            setButtonStatusConvertText(false);
            alertMessageError('Client');
        }

        if (props.clientStatusConvertText == 0 || props.clientStatusConvertText == 1) {
            const interval = setInterval(() => {
                api.insight
                    .getCallRecord(props.id)
                    .then((result) => {
                        setDateCallRecordCard(moment(result.data.startTime).format('DD MMM YYYY'));
                        setStatusConvertText(result.data.clientStatusConvertText);
                        if (result.data.clientStatusConvertText == 2) {
                            setLoadingConvertText(false);
                            setButtonStatusConvertText(true);
                            setButtonStatusViewText(false);
                            if (result.data.clientStatusSentimentAnalysis == 0) {
                                setButtonStatusSentiment(false);
                            }
                            clearInterval(interval);
                        }
                        if (result.data.clientStatusConvertText == 4) {
                            setLoadingConvertText(false);
                        }
                    })
                    .catch((err) => {
                        // console.log("error: ", err);
                    });
            }, 5000);
            if (statusConvertText == 2) {
                clearInterval(interval);
            }
        }
    }, [loadingConvertText, statusConvertText, props.clientStatusConvertText]);

    useEffect(() => {
        if (props.clientStatusConvertText == 2 && props.clientStatusSentimentAnalysis == 0) {
            setButtonStatusSentiment(false);
        }
        if (props.clientStatusSentimentAnalysis == 1 || statusSentiment == 1) {
            setLoadingSentiment(true);
            setButtonStatusSentiment(true);
        }
        if (props.clientStatusSentimentAnalysis == 2 || statusSentiment == 2) {
            setLoadingSentiment(false);
            setButtonStatusSentiment(true);
        }
        if (props.clientStatusSentimentAnalysis == 4) {
            setLoadingSentiment(false);
            setButtonStatusSentiment(false);
        }
        if (props.clientStatusSentimentAnalysis != 4 && statusSentiment == 4) {
            setLoadingSentiment(false);
            setButtonStatusSentiment(false);
            alertMessageError('Client');
        }

        if (props.clientStatusSentimentAnalysis == 0 || props.clientStatusSentimentAnalysis == 1) {
            const interval = setInterval(() => {
                api.insight.getCallRecord(props.id).then((result) => {
                    setStatusSentiment(result.data.clientStatusSentimentAnalysis);
                    if (result.data.clientStatusSentimentAnalysis == 1) {
                        setButtonStatusSentiment(true);
                    }
                    if (result.data.clientStatusSentimentAnalysis == 2) {
                        clearInterval(interval);
                        setLoadingSentiment(false);
                        setButtonStatusSentiment(true);
                        props.setClientStatusSentimentAnalysis(result.data.clientStatusSentimentAnalysis);
                    }
                    if (result.data.clientStatusSentimentAnalysis == 4) {
                        setLoadingSentiment(false);
                    }
                });
            }, 5000);
            if (statusSentiment === 2) {
                clearInterval(interval);
            }
        }
    }, [loadingSentiment, statusSentiment, props.clientStatusSentimentAnalysis]);

    const handleConvertText = async (event: any) => {
        if (props.clientStatusConvertText == 4 && statusConvertText == 4) {
            alertMessageError('Client');
            setLoadingConvertText(false);
        } else {
            setLoadingConvertText(true);
            event.preventDefault();

            let valueQueuesObj = {
                CallQueuesId: props.callQueuesId,
                UsersType: USER_TYPE.CLIENT
            };
            let valueQueues = JSON.stringify(valueQueuesObj);
            let zero: string = '0';
            const formData = new FormData();
            formData.append('CreatedDate', props.createDateActionQueues);
            formData.append('UpdatedDate', props.updateDateActionQueues);
            formData.append('Action', zero);
            formData.append('CallQueuesId', props.callQueuesId);
            formData.append('Value', valueQueues);
            formData.append('Status', zero);

            await api.insight
                .actionQueues(formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .catch(() => {
                    alertMessageError('Client');
                });
        }
    };

    const handleSentiment = async (event: any) => {
        if (props.clientStatusSentimentAnalysis === 4 && statusSentiment === 4) {
            alertMessageError('Client');
            setLoadingSentiment(false);
            setLoadingConvertText(false);
        } else {
            setLoadingSentiment(true);
            event.preventDefault();

            let valueQueuesObj = {
                CallQueuesId: props.callQueuesId,
                UsersType: 0
            };
            let valueQueues = JSON.stringify(valueQueuesObj);

            const formData = new FormData();
            let zero: any = 0;
            let first: any = 1;
            formData.append('CreatedDate', props.createDateActionQueues);
            formData.append('UpdatedDate', props.updateDateActionQueues);
            formData.append('Action', first);
            formData.append('CallQueuesId', props.callQueuesId);
            formData.append('Value', valueQueues);
            formData.append('Status', zero);

            await api.insight.actionQueues(formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).catch(()=>{
                alertMessageErrorSentimentAnalysis("Client");
            });
        }
    };

    const alertMessageError = (userType: string) => {
        toast.error(
            replaceMessages(MESSAGES_ALERT.CONVERT_SOUND_FAILED, userType),
            toastProperty('top-left', 2000, false, true, false, true, undefined, 'light')
        );
    };

    const alertMessageErrorSentimentAnalysis = (userType: string) => {
        toast.error(
            replaceMessages(MESSAGES_ALERT.ANALYSIS_SENTIMENT_FAILED, userType),
            toastProperty('top-left', 2000, false, true, false, true, undefined, 'light')
        );
    };

    return (
        <>
            {loadingConvertText && <PageSpinner />}
            {loadingSentiment && <PageSpinner />}
            <div className="card">
                <div className="card-body">
                    <h4>Client Text Conversation</h4>
                    <span>Record Date: {dateCallRecordCard === 'invalid date' ? '-' : dateCallRecordCard} </span>
                    <p>Download text conversation</p>
                    <div className="button-group">
                        <button
                            className="btn btn-primary btn-sm"
                            disabled={buttonStatusConvertText}
                            onClick={handleConvertText}>
                            Convert
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            disabled={buttonStatusViewText}
                            onClick={handleViewTextCallRecord}>
                            View
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            disabled={buttonStatusSentiment}
                            onClick={handleSentiment}>
                            Analysis
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                show={showModalViewTextCallRecord}
                onHide={() => setShowModalViewTextCallRecord(false)}
                animation={false}
                onClose={handleModalClose}>
                <ViewTextCallRecord clientConvertText={props.clientConvertText} salesAgentConvertText={null} />
            </Modal>
        </>
    );
};

export default ClientConvertToText;
