import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as process from 'process';

const divRoot: any = document.getElementById('root');
const root: any = ReactDOM.createRoot(divRoot);

// (window as any).global = window;
// (window as any).process = process;
// (window as any).Buffer = [];

root.render(
    // <React.StrictMode>
    <App />
    //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
