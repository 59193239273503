import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TitleOfPage } from 'components';

import './style.scss';

interface ItopContentHeader {
    titleOfPage?: string | '';
    isSearch?: boolean | '';
    placeholderSearch?: string | '';
    functionForSearch?: (e: any) => void | '';
    hasButton?: boolean | '';
    buttonName?: string | '';
    hasSymbolButton?: boolean | '';
    symbolButton?: any | '';
    functionButton?: (e: any) => void | '';
    isButtonSubmitType? : boolean;
}

const TopContentHeader = (prop: ItopContentHeader) => {
    const classes = {
        search: {
            display: prop.isSearch ? 'block' : 'none'
        },
        button: {
            display: prop.hasButton ? 'block' : 'none'
        },
        symbol: {
            display: prop.hasSymbolButton ? 'block' : 'none'
        }
    };

    return (
        <>
            <div className="container-header">
                <div className="title-panel">
                    <TitleOfPage title={prop.titleOfPage} />
                </div>
                <div className="search-data" style={classes.search}>
                    <input
                        id="search-contact"
                        type="text"
                        className="search-data-input"
                        placeholder={prop.placeholderSearch}
                        onKeyUp={(e: any) => prop.functionForSearch(e)}
                    />
                    <span className="input-icons">
                        <FontAwesomeIcon icon={faSearch} size="1x" />
                    </span>
                </div>
                <div style={classes.button}>
                    {
                        prop.isButtonSubmitType?
                        <button form="myform" className="btn btn-primary" type='submit'>
                            <span className="icon" style={classes.symbol}>
                                {prop.symbolButton}
                            </span>
                            <span className="label">{prop.buttonName}</span>
                        </button>
                        :
                        <button className="btn btn-primary" onClick={(e) => prop.functionButton(e)}>
                            <span className="icon" style={classes.symbol}>
                                {prop.symbolButton}
                            </span>
                            <span className="label">{prop.buttonName}</span>
                        </button>
                    }
                </div>
            </div>
        </>
    );
};

export default TopContentHeader;
