import InteractisVideo from 'components/interactis/InteractisVideo';
import {
    CallContext,
    ConnectionHubContext,
    ChatConnectionHubContext,
    LoginContext,
    SettingContext,
    TranslationContext,
    UserContext
} from 'contexts';
import AcceptCall from 'pages/call';
import ComfirmPassword from 'pages/confirm-password';
import CustomComponents from 'pages/custom-components';
import Dashboard from 'pages/dashboard';
import ForgetPassword from 'pages/forget-password';
import Insight from 'pages/insight';
import Login from 'pages/login';
import Settings from 'pages/settings';
import Suggest from 'pages/suggest';
import Users from 'pages/users';
import CalendarSchedule from 'pages/calendar';
import usePersistState from 'utils/persistState';
import InsightDetail from 'pages/insight/insight-detail';
import { useServices } from 'services';
import { Iusers } from 'interfaces';
import { ProtectedRoute } from 'protected.route';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UserFormManagement } from 'components';
import './App.scss';
import Trigger from 'pages/trigger';
import TriggerDetail from 'pages/trigger/trigger-detail';

function App() {
    const [loginContext, setLoginContext] = usePersistState('auth', {});
    const [userContext, setUserContext] = usePersistState('user', {});
    const [callContext, setCallContext] = usePersistState('call', {});
    const [translationContext, setTranslationContext] = usePersistState('translation', {});
    const [settingContext, setSettingContext] = useState({});
    const [connectionHubContext, setConnectionHubContext] = useState(true);
    const { api } = useServices();

    useEffect(() => {
        let language: string = document.documentElement.lang;
        (async () => {
            let res = await api.localization.getLocalizations(language);
            let resDict = res.dictionary;
            setTranslationContext(resDict)
        })();
    }, [])

    return (
        <div className="App">
            <BrowserRouter>
                <TranslationContext.Provider value={[translationContext, setTranslationContext]}>
                    <LoginContext.Provider value={[loginContext, setLoginContext]}>
                        <UserContext.Provider value={[userContext, setUserContext]}>
                            <ConnectionHubContext.Provider value={[connectionHubContext, setConnectionHubContext]}>
                                <SettingContext.Provider value={[settingContext, setSettingContext]}>
                                    <CallContext.Provider value={[callContext, setCallContext]}>
                                        <InteractisVideo />

                                        <Routes>
                                            {/* Public Route */}
                                            <Route path="/login" element={<Login />} />
                                            <Route path="/update-password" element={<Suggest event={`update`} />} />
                                            <Route path="/send-to-email" element={<Suggest event={`email`} />} />
                                            <Route path="/forgot-password" element={<ForgetPassword />} />
                                            <Route path="/resetpassword" element={<ComfirmPassword />} />
                                            {/* Projected Route */}
                                            <Route path="/" element={<ProtectedRoute />}>
                                                <Route path="/" element={<Dashboard />} />
                                                <Route path="/live">
                                                    <Route index element={<AcceptCall />} />
                                                    <Route path=":id" element={<AcceptCall />} />
                                                </Route>
                                                <Route path="/components" element={<CustomComponents />} />
                                                <Route path="/calendar-schedule" element={<CalendarSchedule />} />
                                                <Route path="/settings" element={<Settings />} />
                                                <Route path="/users">
                                                    <Route index element={<Users />} />
                                                    <Route path="addnewuser" element={<UserFormManagement method={`add`} />} />
                                                    <Route path="edituser/:memberId/" element={<UserFormManagement method={`update`} />} />
                                                </Route>
                                                <Route path="/calendar-schedule" element={<CalendarSchedule />} />
                                                <Route path="/insight">
                                                    <Route index element={<Insight />} />
                                                    <Route path=":id" element={<InsightDetail />} />
                                                </Route>
                                                <Route path="/triggers">
                                                    <Route index element={<Trigger />} />
                                                    <Route path="create" element={<TriggerDetail method={`add`} />} />
                                                    <Route path="update/:triggerId" element={<TriggerDetail method={`update`} />} />
                                                </Route>
                                            </Route>
                                            {/* <Route path="*" element={<NoMatch />} /> */}
                                        </Routes>
                                    </CallContext.Provider>
                                </SettingContext.Provider>
                            </ConnectionHubContext.Provider>
                        </UserContext.Provider>
                    </LoginContext.Provider>
                </TranslationContext.Provider>
            </BrowserRouter>
        </div>
    );
}

export default App;
