import { IinputTextArea } from 'interfaces';
import './style.scss';

const InputTextArea = (prop: IinputTextArea) => {
    return (
        <>
            <textarea
                placeholder={`${prop.placeholder}`}
                defaultValue={prop.defaultValue}
                onKeyDown={prop.fucntionCustom}
                className={`text-area ${prop.className}`}
                readOnly={prop.isReadOnly}
                required={prop.isRequired}></textarea>
        </>
    );
};
export default InputTextArea;
