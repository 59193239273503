import { useEffect, useState } from 'react';
import './style.scss';

interface Props {
    items?: any;
    onChange?: Function;
    extraPadding?: boolean;
}

const ButtonGroup: React.FC<Props> = ({ items, onChange, extraPadding }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [radioChanged, setRadioChanged] = useState<string>("radio changed")

    const handleChange = index => {
        setSelectedIndex(index);
        onChange(items[index]);
    };

    const customStyles = {
        extraPadding: {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
    };

    return (
        <div className="button-group">
            {items.map((e, i) => (
                <div
                    key={i}
                    className={selectedIndex === i ? 'item selected' : 'item'}
                    style={extraPadding ? customStyles.extraPadding : {}}
                    onClick={() => handleChange(i)}
                >
                    <input
                        type="radio"
                        checked={selectedIndex === i}
                        onChange={() => 'radio changed'}
                    />
                    <label>{e.text}</label>
                </div>
            ))}
        </div>
    );
};

export default ButtonGroup;
