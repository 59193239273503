import { useRef, useState } from 'react';

export default function useTextEditor() {
    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['clean']
        ]
    };

    const formats = 
        [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image'
          ]
    

    return { modules, formats };
}
