import { axiosPrivate } from 'services/axios.private';

class untilService {
    // getCallSettings = async () => {
    //     try {
    //         var result = await axiosPrivate.get('/api/v1/setting/query/get-call-settings');
    //         sessionStorage.setItem('reconnectWhenReload', result.data.isRememberCallWhenReloadPage);
    //         sessionStorage.setItem('playSound', result.data.isPlaysoundOnCall);
    //         sessionStorage.setItem('timeoutSaleAgent', result.data.timeoutNotAnsweringCall * 1000);
    //         sessionStorage.setItem('timeoutClient', result.data.timeout2 * 1000);
    //         sessionStorage.setItem('timeoutServiceCall', result.data.timeout3 * 1000);

    //         return result;
    //     } catch (error) {
    //         // console.log('ERROR on get call settings : ', error);
    //     }
    // };

    loadCallSummary = async hubConnection => {
        if (hubConnection) await hubConnection.send('SendCallSummary');
    };

    checkAuthentication = (connectionHub, authenticationContext, connectionHubId, saleAgentId) => {
        if (authenticationContext != null) {
            const addToSalesAgentGroup = async () => {
                if (connectionHub)
                    await connectionHub.send('AddToSalesAgentsGroup', connectionHubId, saleAgentId);
            };
            addToSalesAgentGroup();
        }
        return authenticationContext;
    };

    initiatePermissions = async (
        authenticationContext,
        setCallControl,
        setSaleAgentId,
        setUsersId,
        setUserContext,
    ) => {
        if (authenticationContext) {
            const res = await axiosPrivate.get('/api/v2/frontend/me');
            const user = res.data;

            setSaleAgentId(user.userId); //id by table Users not have admin id.
            setUsersId(user.id); //id by table AspNetUsers.
            setUserContext(user);
            // console.log('Error :', error);
        } else {
            setCallControl('readytocall');
        }
    };

    getIceServer = async () => {
        var res = await axiosPrivate.get('/api/v1/webrtc/get-webRTC-server-configuration');
        return res;
    };

    getStaticIceServer = async () => {
        var res = await axiosPrivate.get('/api/v1/webrtc/get-webRTC-static-server-configuration');
        return res.data;
    };

    getQueueIndex = async (connectionHubId, setGetUserWaitingQueueIndex) => {
        try {
            var result = await axiosPrivate.get(
                '/api/v1/frontend/getUserWaitingQueueIndex?userStartCallId=' + connectionHubId,
            );
            setGetUserWaitingQueueIndex(result.data);
        } catch (error) {
            console.error('ERROR on get user waiting queue index : ', error);
        }
    };

    stopVideoStream = (videoRef, wait, retries, counter) => {
        if (!videoRef.current) {
            if (counter <= retries)
                setTimeout(() => {
                    ++counter;
                    this.stopVideoStream(videoRef, wait, retries, counter);
                }, wait);
        } else {
            videoRef.current.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    };

    initiateUserMediaLocalStream = async (switchCamera = false, video = true, audio = true) => {
        try {
            let facingMode;
            if (video == true) {
                if (switchCamera == true) {
                    facingMode = { facingMode: { exact: 'environment' } };
                } else {
                    facingMode = { facingMode: 'user' };
                }
            } else {
                facingMode = false;
            }
            const stream = await navigator.mediaDevices?.getUserMedia({
                video: facingMode,
                audio: audio,
            });

            return stream;
        } catch (exception) {
            console.error(exception);
        }
    };

    getServiceCallByMinutes = async minutes => {
        var data = await axiosPrivate.get(
            '/api/v1/service-call/query/service-calls-by-minutes?Minutes=' + minutes,
        );
        return data;
    };

    getServiceCallByKey = async key => {
        var data = await axiosPrivate.get(
            '/api/v1/service-call/query/get-service-call-by-key?Key=' + key,
        );
        return data;
    };

    cancelServiceCall = serviceCallId => {
        return axiosPrivate.post(
            '/api/v1/service-call/command/cancel-service-call-by-id',
            serviceCallId,
        );
    };

    uploadAudio = blob => {
        return axiosPrivate.post('/api/v1/callRecord/command/uploadAudio', blob);
    };

    getCallInvitationByKey = async secretCode => {
        var data = await axiosPrivate.get(
            '/api/v1/invitation-call/query/GetCallInvitationByKey?secretCode=' + secretCode,
        );
        return data;
    };
}

export default new untilService();
