import { Ibadges } from 'interfaces';
import React from 'react';

import '../../assets/scss/_badge.scss';
import './style.scss';

const Badge: React.FC<Ibadges> = props => {
    const useStyles = {
        containerBadge: {
            background: props.bgColor,
            fontSize: props.fontSize || 12,
            color: props.textColor,
            width: '95px',
            height: '24px',
            fontWeight: 700,
            fontFamily: 'Open Sans',
            padding: '2px 4px',
            borderRadius: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            border: props.borderProperty === '' ? '0' : props.borderProperty,
            margin:' 0 auto',
        },
    };

    return (
        <>
            <div style={useStyles.containerBadge} >{props.title}</div>
        </>
    );
};

export default Badge;
