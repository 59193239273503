import { useEffect, useState } from 'react';

export const useContactVisitor = () => {
    const [contactType, setContactType] = useState(null);
    const [visitorHubId, setVisitorHubId] = useState(null);
    const [calling, setCalling] = useState(false);
    const [chatRequest, setChatRequest] = useState(false);

    const handleCancelRequest = () => {
        setVisitorHubId(null);
        setContactType(null);
        setCalling(null);
    };

    const handleVideoCall = (e: any) => {
        // console.log(props)
        const element = e.target;
        if (
            element.tagName.toLowerCase() === 'button' ||
            element.parentNode.tagName.toLowerCase() === 'button' ||
            element.parentNode.parentNode.tagName.toLowerCase() === 'button'
        ) {
            let buttonElement;

            if (element.tagName.toLowerCase() === 'button') buttonElement = element;
            else if (element.parentNode.tagName.toLowerCase() === 'button') buttonElement = element.parentNode;
            else if (element.parentNode.parentNode.tagName.toLowerCase() === 'button')
                buttonElement = element.parentNode.parentNode;

            setVisitorHubId(buttonElement.dataset.hubid);

            if (buttonElement.className.indexOf('btn-video-call') !== -1) {
                setContactType('video-call');
                setCalling(true);
            }
        }
    };

    const handleChat = (e: any) => {
        // console.log(props)
        const element = e.target;
        if (
            element.tagName.toLowerCase() === 'button' ||
            element.parentNode.tagName.toLowerCase() === 'button' ||
            element.parentNode.parentNode.tagName.toLowerCase() === 'button'
        ) {
            let buttonElement;

            if (element.tagName.toLowerCase() === 'button') buttonElement = element;
            else if (element.parentNode.tagName.toLowerCase() === 'button') buttonElement = element.parentNode;
            else if (element.parentNode.parentNode.tagName.toLowerCase() === 'button')
                buttonElement = element.parentNode.parentNode;

            setVisitorHubId(buttonElement.dataset.hubid);

            if (buttonElement.className.indexOf('btn-chat') !== -1) {
                setContactType('chat');
                setChatRequest(true);
            }
        }
        // else {
        //     setContactType('screen-player');
        // }
    };

    return {
        contactType,
        visitorHubId,
        handleVideoCall,
        handleChat,
        calling,
        setCalling,
        chatRequest,
        setChatRequest,
        handleCancelRequest
    };
};
