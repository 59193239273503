import { axiosPrivate } from 'services/axios.private';

export class ServiceCallsApi {
    getServiceCallsCalendar = async (startTime: any, endTime: any, salesAgentId: any) => {
        const res = await axiosPrivate
            .get('/api/v1/service-call/query/service-calls-calendar', {
                params: {
                    StartDate: startTime,
                    EndDate: endTime,
                    SaleAgentId: salesAgentId
                }
            })
            .catch((err) => {
                return err;
            });

        return res.data;
    };

    getAllServiceCallsCalendar = async (
        startTime: any,
        endTime: any,
        searchKeyWord: string | null,
        page: number,
        pageSize: number
    ) => {
        const res = await axiosPrivate
            .get('/api/v1/service-call/query/all-service-calls', {
                params: {
                    StartDate: startTime,
                    EndDate: endTime,
                    Search: searchKeyWord,
                    Page: page,
                    pageSize: pageSize
                }
            })
            .catch((err) => {
                return err;
            });

        return res.data;
    };

    getSalesAgentList = async () => {
        const res: any = await axiosPrivate.get('/api/v1/user/query/sale-agents-dropdown-items').catch((err) => {
            return err;
        });

        return res.data;
    };

    cancelServiceCall = async (formData: any) => {
        const res = await axiosPrivate
            .post('/api/v1/service-call/command/cancel-service-call/', formData)
            .catch((err) => {
                return err;
            });
        return res.data;
    };
    createServiceCall = async (formData: any, params: any) => {
        const res = await axiosPrivate
            .post('/api/v1/service-call/command/book-service-call', formData, params)
            .catch((err) => {
                return err;
            });
        return res.data;
    };

    updateServiceCall = async (formData: any, params: any) => {
        const res = await axiosPrivate
            .post('/api/v1/service-call/command/update-service-call', formData, params)
            .catch((err) => {
                return err;
            });
        return res.data;
    };
}
export default new ServiceCallsApi();
