import moment from 'moment';
import { useEffect, useState } from 'react';

interface ItimerVisitorCurrent {
    lastSeenTime: any;
}

const TimerVisitorCurrent = (prop: ItimerVisitorCurrent) => {
    const [lastSeenTime, setLastSeenTime] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            let now = new Date();
            var days = moment(now).diff(prop.lastSeenTime, 'days');
            var hours = moment(now).diff(prop.lastSeenTime, 'hours') % 24;
            var minutes = moment(now).diff(prop.lastSeenTime, 'minutes') % 60;
            var second = moment(now).diff(prop.lastSeenTime, 'second') % 60;
            var duration =
                (days > 0 ? days + 'd ' : '') +
                (hours > 0 ? hours + 'h ' : '') +
                (minutes > 0 ? minutes + 'm ' : '') +
                (second > 0 ? second + 's ' : '00s');
            setLastSeenTime(duration);
        }, 1000);
        return () => clearInterval(interval);
    }, [lastSeenTime]);

    return <>{lastSeenTime}</>;
};
export default TimerVisitorCurrent;
