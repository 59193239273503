import { axiosPrivate } from 'services/axios.private';

export class TriggerApi {
    getTriggerData = async (page: any, sortDirection: any, sortColumn: any, pageSize: any, search: string) => {
        const res: any = await axiosPrivate.get(`/api/v2/triggers/query/trigger/all`, {
            params: {
                page: page,
                sortDirection: sortDirection,
                sortColumn: sortColumn,
                pageSize: pageSize,
                search: search
            }
        });

        return res.data.data;
    };

    getTriggerDetail = async (id: string | number) => {
        const res: any = await axiosPrivate.get(`/api/v2/triggers/query/editTrigger/${id}`);
        return res.data;
    };

    saveTrigger = async (formData: any, params: any) => {
        const res: any = await axiosPrivate.post('/api/v2/triggers/command/createTrigger', formData, params);
        return res.data;
    };
    updateTrigger = async (id: any, formData: any, params: any) => {
        const res: any = await axiosPrivate.put(`/api/v2/triggers/command/updateTrigger/${id}`, formData, params);
        return res.data;
    };

    deleteTrigger = async (id: any) => {
        const res: any = await axiosPrivate.delete('/api/v2/triggers/command/deleteTrigger/' + id);
        return res.data;
    };

    uploadImageSingle = async (formData: any, params: any) => {
        const res: any =  await axiosPrivate.post(`/api/v2/triggers/command/upload`, formData, params);
        return res.data;
    };

    updateActivate = async (id: any, formData: any, params: any) => {
        const res: any = await axiosPrivate.put('/api/v2/triggers/command/activate/' + id, formData, params);
        return res.data;
    };
}
