import { HubConnectionBuilder } from '@microsoft/signalr';
import { ChatConnectionHubContext, ConnectionHubContext, LoginContext, SettingContext, UserContext } from 'contexts';
import moment from 'moment';
import DeleteDialog from 'partials/delete-dialog';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from 'services/api.service';
import Peer from 'simple-peer';
import Cookies from 'universal-cookie';

import InteractisAlert from '../interactis/InteractisAlert';
import InteractisUploadRecordAudio from '../interactis/InteractisUploadRecordAudio';
import InteractisVideoInterface from '../interactis/InteractisVideoInterface';
import SilenceSound from '../interactis/sounds/1-second-of-silence.mp3';
import SoundAlert from '../interactis/sounds/happy-bells.mp3';
import utilService from '../interactis/untils/interactisService';
import ChatBox from './ChatBox';
import InteractisComingCall from './InteractisComingCall';
import OptionWidgetVideo from './OptionWidgetVideo';
import WaitingProcessLoadData from './WaitingProcessLoadData';
import { appInsights } from './appInsights';
import './style.scss';
import { useServices } from 'services';
import { CALL_STATUS, SESSIONSTORAGE_NAME } from 'utils/constants';
import { useSessionStorage } from 'usehooks-ts';

// export function useRouter() {
//     const params = useParams();
//     const location = useLocation();
//     const navigate = useNavigate();
//     const match = useRouteMatch();
//     return useMemo(() => {
//         return {
//             push: navigate,
//             replace: history.replace,
//             pathname: location.pathname,
//             query: {
//                 ...queryString.parse(location.search),
//                 ...params,
//             },
//             match,
//             location,
//             history,
//         };
//     }, [params, match, location, history]);
// }

var waitForClientCallTimeout = [];
var waitForSignalTimeout = [];
var waitForClientReconnect = [];
var waitForClientReconnectError = [];
var waitForSalesAgentReconnect = [];
var waitForAcceptCallForReconnect = [];
var waitForAcceptCall = [];

function InteractisVideo(props) {
    // const router = useRouter();
    const navigate = useNavigate();
    const [userContext, setUserContext] = useContext(UserContext);
    const [settingContext, setSettingContext] = useContext(SettingContext);
    const [loginContext] = useContext(LoginContext);
    const [connectionHubContext, setConnectionHubContext] = useContext(ConnectionHubContext);
    const { permissions } = userContext;

    const { api } = useServices();

    const [isVideoContainerVisible, setIsVideoContainerVisible] = useState(false);
    
    const [connectionHub, setConnectionHub] = useState(null);
    const [connectionHubId, setConnectionHubID] = useState(null);

    const [signalData, setSignalData] = useState();
    const [callControl, setCallControl] = useState();
    const [callingStatus, setCallingStatus] = useState(CALL_STATUS.CALLING);
    // const [permissions, setPermissions] = useState(false);
    const [callTransaction, setCallTransaction] = useState(false);
    const [controlMedia, setControlMedia] = useState(false);
    const [enableAudio, setEnableAudio] = useState(true);
    const [enableVideoWidget, setEnableVideoWidget] = useState(true);
    const [switchCamera, setSwitchCamera] = useState(false);
    const [isMaximizeWidgetVideo, setIsMaximizeWidgetVideo] = useState(false);
    const localStream = useRef(null);
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
    const userSignalData = useRef(null);
    const peerSaleAgent = useRef(null);
    const cookies = new Cookies();
    const isBlur = useRef(null);
    const localCanvasRef = useRef(null);
    const audioStreamRef = useRef(null);
    const switchCameraRef = useRef(null);
    const blurbgLoopStart = useRef(null);
    const localStreamCanvas = useRef(null);
    const mediaRecorder = useRef(null);

    const [getUserWaitingQueueIndex, setGetUserWaitingQueueIndex] = useState(null);
    const [saleAgentId, setSaleAgentId] = useState();
    const [UsersId, setUsersId] = useState();
    const [serviceCall, setServiceCall] = useState(null);
    const [isOpenAlert, setIsOpenAlert] = useState(false);
    const [callType, setCallType] = useState(null);
    const [isPlaySound, setIsPlaySound] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [sound] = useState(typeof Audio !== 'undefined' && new Audio(SoundAlert));
    const [salesAgentCallRecord, setSalesAgentCallRecord] = useState(false);
    const [isIFrame, setIsIFrame] = useState(false);
    const [connectionHubIdStartCall, setConnectionHubIdStartCall] = useState();
    const [connectionHubIdAcceptCall, setConnectionHubIdAcceptCall] = useState();
    const [userStartCallId, setUserStartCallId] = useState();
    const [blurBackgroundSaleAgent, setBlurBackgroundSaleAgent] = useState(false);
    const [videoRefHeightClientRect, setVideoRefHeightClientRect] = useState();
    const [disabledDraggable, setDisableDraggable] = useState();
    const [isMinimizeWidgetVideo, setIsMinimizeWidgetVideo] = useState(false);
    const [isWaitData, setIsWaitData] = useState(false);
    const [isPlayer, setIsPlayer] = useState(false);
    // const [connectionHubAdminDrawerId, setConnectionHubAdminDrawerId] = useState(null);
    const [pendingDeleteAction, setPendingDeleteAction] = React.useState({
        show: false,
        id: '0'
    });
    const [statusShareScreenClient, setStatusShareScreenClient] = useState();
    const [profileUrl, setProfileUrl] = useState('');
    const [salesAgentControlShareScreen, setSalesAgentControlShareScreen] = useState(false);
    const [ipAddressOfClient, setIpAddressOfClient] = useState();
    const [cityOfClient, setCityOfClient] = useState();
    const [countryNameOfClient, setCountryNameOfClient] = useState();
    const [displayReplayer, setDisplayReplayer] = useState();
    const [innerWidth, setInnerWidth] = useState();
    const [styleOverflowForSpaceVideo, setStyleOverflowForSpaceVideo] = useState();
    const [styleMarginTopForShareScreen, setStyleMarginTopForShareScreen] = useState();
    const [stylePositionVideo, setStylePositionVideo] = useState();

    const [callStatus, setCallStatus] = useSessionStorage(SESSIONSTORAGE_NAME.CALL_STATUS, null);
    const [startCallTime, setStartCallTime] = useSessionStorage(SESSIONSTORAGE_NAME.START_CALL_TIME, null);
    const [callSessionID, setCallSessionID] = useSessionStorage(SESSIONSTORAGE_NAME.CALL_SESSION_ID, null);
    // const [userAcceptCallID, setUserAcceptCallID] = useSessionStorage(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_ID, null);
    const [isCanvasRun, setIsCanVasRun] = useState(false)
    const [isVideoCallReady, setIsVideoCallReady] = useState(false)

    useEffect(() => {
        if (isWaitData == false || isPlayer == false) {
            setEnableAudio(false);
        } else {
            setEnableAudio(true);
            setIsVideoCallReady(true)
        }
    }, [isWaitData, isPlayer]);

    useEffect(() => {
        if (connectionHub) {
            connectionHub.on('onStatusPlayer', (result) => {
                if (result.isPlayer) {
                    setIsPlayer(true);
                    appInsights.trackTrace({ message: 'Sales agent player = true.' });
                }
            });
        }
    }, [connectionHub, isPlayer]);

    useEffect(() => {
        if (isWaitData == false || isPlayer == false) {
            setIsMinimizeWidgetVideo(true);
        } else {
            setIsMinimizeWidgetVideo(false);
        }
    }, [isWaitData, isPlayer]);

    useEffect(() => {
        if (innerWidth <= 640) {
            setDisableDraggable(true);
        } else {
            if (isMaximizeWidgetVideo) {
                setDisableDraggable(true);
            } else {
                setDisableDraggable(false);
            }
        }
    }, [innerWidth, isMaximizeWidgetVideo]);

    useEffect(() => {
        api.callSetting.getCallSetting().then((res) => {
            setSettingContext(res);
        });
    }, []);

    useEffect(() => {
        if (isPlaySound) {
            playing ? soundPlay() : soundPause();
        }
    }, [playing]);

    const soundPlay = () => {
        sound.loop = true;
        sound.volume = 0.5;
        sound.play();
    };

    const soundPause = () => {
        sound.loop = false;
        sound.pause();
        sound.currentTime = 0;
    };

    const clearAllTimeout = () => {
        waitForClientCallTimeout.forEach((item) => {
            clearTimeout(item);
        });
        waitForClientReconnect.forEach((item) => {
            clearTimeout(item);
        });
        waitForClientReconnectError.forEach((item) => {
            clearTimeout(item);
        });
        waitForSalesAgentReconnect.forEach((item) => {
            clearTimeout(item);
        });
        waitForAcceptCallForReconnect.forEach((item) => {
            clearTimeout(item);
        });
        waitForAcceptCall.forEach((item) => {
            clearTimeout(item);
        });
    };

    function handleMinimizeWidgetVideo(e) {
        setIsMinimizeWidgetVideo(isMinimizeWidgetVideo === false ? true : false);
    }

    function handCloseIFrame(e) {
        setIsIFrame(false);
    }

    const handleCloseAlert = () => {
        setIsOpenAlert(false);
        setServiceCall(null);
    };

    const getServiceCall = (minutes) => {
        utilService.getServiceCallByMinutes(minutes).then((result) => {
            if (result.data != null) {
                if (result.data.items) {
                    if (result.data.items.length > 0) {
                        if (callType !== 'ServiceCall') {
                            setServiceCall(result.data.items[0]);
                            // console.log('at', moment().format('HH:mm'));
                        }
                    } else {
                        // console.log('no service call', moment().format('HH:mm'));
                    }
                }
            }
        });
    };

    const cancelServiceCall = () => {
        utilService
            .cancelServiceCall({
                serviceCallId: serviceCall.serviceCallId
            })
            .then((result) => {
                // console.log('cancel service call :', result);
            })
            .catch((err) => {
                // console.log("error: ", err);
            });
    };

    const confirmCancelServiceCall = (event) => {
        setPendingDeleteAction({ show: true, id: event.currentTarget.dataset.tag });
    };

    useEffect(() => {
        if (serviceCall) {
            setIsOpenAlert(true);
        }
    }, [serviceCall]);

    useEffect(() => {
        if (permissions && saleAgentId) {
            let minutes = 5;
            getServiceCall(minutes);
            let fiveMinutes = minutes * 60 * 1000;
            let now = moment();
            let untilNextFiveMinuteMark = fiveMinutes - (now % fiveMinutes);
            setTimeout(() => {
                getServiceCall(minutes);
                setInterval(() => {
                    getServiceCall(minutes);
                }, fiveMinutes);
            }, untilNextFiveMinuteMark);
        }
    }, [saleAgentId]);

    useEffect(() => {
        if (permissions && saleAgentId && connectionHubId && connectionHub) {
            connectionHub.send('AddToSalesAgentsGroup', connectionHubId, saleAgentId);
        }
    }, [permissions, saleAgentId, connectionHubId, connectionHub]);

    useEffect(() => {
        const setCallStatusDafault = async () => {
            await sessionStorage.setItem(SESSIONSTORAGE_NAME.CALL_STATUS, null);
        };
        if (sessionStorage.getItem(SESSIONSTORAGE_NAME.CALL_STATUS) != null) {
            setCallStatusDafault();
        }

        if (!loginContext.access_token) {
            return;
        }
        utilService.initiatePermissions(
            loginContext.access_token,
            setCallControl,
            setSaleAgentId,
            setUsersId,
            setUserContext
        );

        setIsVideoContainerVisible(false);

        const hubConnection = new HubConnectionBuilder()
            .withUrl(`${API_URL}/hub/interactic`, {
                accessTokenFactory: () => loginContext.access_token
            })
            .withAutomaticReconnect()
            .build();

        hubConnection.start().then((a) => {
            hubConnection
                .invoke('getconnectionid')
                .then((connectionid) => {
                    setConnectionHubID(connectionid);
                    console.log('connectionid of sales agent :', connectionid);

                    if (
                        !utilService.checkAuthentication(
                            hubConnection,
                            loginContext.access_token,
                            connectionid,
                            saleAgentId
                        )
                    ) {
                        hubConnection.on('WaitingQueueUpdated', (data) => {
                            setGetUserWaitingQueueIndex(data.queueIndex);
                            // utilService.getQueueIndex(connectionid, setGetUserWaitingQueueIndex);
                        });
                    }

                    if (loginContext.access_token != null) {
                        hubConnection.invoke('GetTotalUserComingCall').then((result) => {
                            if (result > 0) {
                                setCallControl('comingcall');
                                setStartCallTime(new Date().toLocaleString());
                            }
                        });
                    }

                    hubConnection.onreconnected((connectionId) => {
                        window.location.reload(false);
                    });

                    hubConnection.on('AcceptedCallRequest', (res) => {
                        clearAllTimeout();
                        sessionStorage.setItem(SESSIONSTORAGE_NAME.CALL_STATUS, CALL_STATUS.READY);
                        setStartCallTime(new Date().toLocaleString());
                        setCallSessionID(res.callSessionID);
                        setCallingStatus(CALL_STATUS.READY);
                        setCallTransaction(res);
                        setUserStartCallId(res.userStartCallID);

                        navigate(`/live/${res.callSessionID}`);
                    });

                    hubConnection.on('WaitingQueueUpdated', (data) => {
                        setGetUserWaitingQueueIndex(data.queueIndex);
                        // utilService.getQueueIndex(connectionid, setGetUserWaitingQueueIndex);
                    });

                    hubConnection.on('UserSignalData', (data) => {
                        appInsights.trackTrace({ message: 'Sales agent signal data.' });
                        userSignalData.current = data.signalData;
                    });

                    setConnectionHubContext({
                        connectionHub: hubConnection,
                        connectionHubId: connectionid
                    });
                    setConnectionHub(hubConnection);
                })
                .catch((error) => {
                    console.error('error', error);
                    appInsights.trackException({ exception: error });
                });
        });

        // chat connection hub

        const chatHubConnection = new HubConnectionBuilder()
            .withUrl(`${API_URL}/hub/chat`, {
                accessTokenFactory: () => loginContext.access_token
            })
            .withAutomaticReconnect()
            .build();

        // utilService.getCallSettings().then((result) => {
        //     setIsPlaySound(result.data.isPlaysoundOnCall);
        //     setSalesAgentCallRecord(result.data.salesAgentCallRecord);
        // });
        setIsPlaySound(settingContext.isPlaySound);
        setSalesAgentCallRecord(settingContext.salesAgentCallRecord);

        return function cleanup() {
            navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
                mediaStream.getTracks().forEach((track) => {
                    track.stop();
                });
            });

            localStream.current = null;
            localVideoRef.current = null;
            remoteVideoRef.current = null;
            userSignalData.current = null;
            peerSaleAgent.current = null;
            isBlur.current = null;
            localCanvasRef.current = null;
            audioStreamRef.current = null;
            switchCameraRef.current = null;
            blurbgLoopStart.current = null;
            localStreamCanvas.current = null;
            mediaRecorder.current = null;
        };
    }, [loginContext]);

    useEffect(() => {
        if (connectionHub) {
            connectionHub.on('ComingCall', (result) => {
                // console.log('in coming call', result);
                // console.log('coming call type :', result.callType);
                const setComingCall = () => {
                    var callStatusNow = sessionStorage.getItem(SESSIONSTORAGE_NAME.CALL_STATUS);
                    // console.log('check service call 1 :', callStatusNow);
                    if (callStatusNow != CALL_STATUS.READY) {
                        // console.log('check service call 2 :', result.totalUserComingCall);
                        if (result.totalUserComingCall > 0) {
                            // console.log('check service call 3 :', result.callType, result.callType === 'ServiceCall' ? 'comingcallfromcode' : 'comingcall');
                            setCallControl(result.callType === 'ServiceCall' ? 'comingcallfromcode' : 'comingcall');
                            if (isPlaySound) {
                                setPlaying(true);
                            }
                            setCallType(result.callType);
                            sessionStorage.setItem(SESSIONSTORAGE_NAME.CALL_STATUS, CALL_STATUS.COMINGCALL);
                            setStartCallTime(new Date().toLocaleString());

                            // waitForClientCallTimeout.push(setTimeout(() => {
                            //     setCallControl('endcall');
                            // }, sessionStorage.getItem('timeoutSaleAgent')));
                        } else {
                            // console.log('check service call 4 :', result.totalUserComingCall);
                            if (isPlaySound) {
                                setPlaying(false);
                            }
                            setCallControl('endcall');
                        }
                    }
                };

                // console.log('check call type', result.callType);
                if (result.callType == 'ServiceCall') {
                    if (result.userId == saleAgentId) {
                        setComingCall();
                    }
                } else if (result.callType == 'InvitationCall') {
                    utilService
                        .getCallInvitationByKey(result.invitationCode)
                        .then((resultInvitationCall) => {
                            let invitationSalesAgentId = resultInvitationCall.data.data.salesAgentId;
                            if (invitationSalesAgentId == userContext.userId) {
                                setComingCall();
                            }
                        })
                        .catch((err) => {
                            // console.log("error: ", err);
                        });
                } else if (result.callType == 'ContactVisitor') {
                    // console.log('invitationSalesAgentId', result.invitationSalesAgentId, userContext.userId);
                    if (result.invitationSalesAgentId == userContext.userId) {
                        setComingCall();
                    }
                } else {
                    setComingCall();
                }
            });
        }
    }, [connectionHub, saleAgentId, userContext]);

    useEffect(() => {
        if (connectionHub) {
            connectionHub.on('StatusrrwebClient', (result) => {
                setStatusShareScreenClient(result.isIFrame);
                if (result.isIFrame) {
                    handCloseIFrame();
                }
            });
        }
    }, [connectionHub]);

    useEffect(() => {
        switch (callControl) {
            case 'endcall':
                var callstatus = sessionStorage.getItem(SESSIONSTORAGE_NAME.CALL_STATUS);
                if (callstatus != CALL_STATUS.COMINGCALL) utilService.stopVideoStream(localStream, 200, 25, 0);

                if (isPlaySound) setPlaying(false);

                setCallTransaction(null);
                setSignalData(null);
                sessionStorage.removeItem('connectionHubIdAcceptCall');
                setCallType(null);
                setIsIFrame(false);
                utilService.initiatePermissions(
                    loginContext.access_token,
                    setCallControl,
                    setSaleAgentId,
                    setUsersId,
                    setUserContext
                );
                sessionStorage.removeItem(SESSIONSTORAGE_NAME.CALL_STATUS);
                sessionStorage.removeItem(SESSIONSTORAGE_NAME.CALL_SESSION_ID);
                sessionStorage.removeItem(SESSIONSTORAGE_NAME.START_CALL_TIME);
                sessionStorage.removeItem(SESSIONSTORAGE_NAME.CALL_STATUS);
                sessionStorage.removeItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_ID);
                sessionStorage.removeItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_IFRAME);

                clearAllTimeout();
                break;
            default:
                break;
        }
        utilService.loadCallSummary(connectionHub);
    }, [callControl]);

    useEffect(() => {
        if (signalData && callTransaction) {
            const SendCallSignalDataToAcceptCall = async () => {
                // console.log('in send call singnal data start call.');
                if (connectionHub) {
                    await connectionHub.send(
                        'SendCallSignalData',
                        callTransaction.userAcceptCallID,
                        JSON.stringify(signalData),
                        callTransaction.callSessionID
                    );
                    setSignalData(null);
                }
            };

            const SendCallSignalDataToStartCall = async () => {
                sessionStorage.setItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_ID, callTransaction.userAcceptCallID);
                // console.log('in send call singnal data accept call.');
                if (connectionHub) {
                    await connectionHub.send(
                        'SendCallSignalData',
                        callTransaction.userStartCallID,
                        JSON.stringify(signalData),
                        callTransaction.callSessionID
                    );
                    setSignalData(null);
                }
            };

            if (callTransaction.userStartCallID == connectionHubId) {
                SendCallSignalDataToAcceptCall();
            }
            if (callTransaction.userAcceptCallID == connectionHubId) {
                SendCallSignalDataToStartCall();
            }
        }
    }, [signalData, callTransaction]);

    useEffect(() => {
        if (connectionHub) {
            connectionHub.on('onConnectionId', (result) => {
                if (result.connectionId2) {
                    localStorage.setItem('connectionHubIdStartCall', result.connectionId1);
                    localStorage.setItem('connectionHubIdAcceptCall', result.connectionId2);
                    setConnectionHubIdStartCall(result.connectionId1);
                    setConnectionHubIdAcceptCall(result.connectionId2);
                }
            });
        }
    }, [connectionHub, connectionHubId, connectionHubIdStartCall, connectionHubIdAcceptCall]);

    useEffect(() => {
        if (connectionHub) {
            if (connectionHubIdStartCall == connectionHubId && connectionHubIdAcceptCall) {
                const SendMessageCallStatusToAccepCall = async () => {
                    try {
                        await connectionHub.send(
                            'SendMessageCallStatusToAccepCall',
                            connectionHubIdAcceptCall,
                            callControl
                        );
                    } catch (err) {
                        // console.log('error :', err);
                    }
                };
                SendMessageCallStatusToAccepCall();
            }
        }
    }, [connectionHub, connectionHubId, callControl, connectionHubIdStartCall, connectionHubIdAcceptCall]);

    useEffect(() => {
        if (
            localStorage.getItem('signalData') &&
            callTransaction &&
            localStorage.getItem('connectionHubIdAcceptCall')
        ) {
            if (callTransaction.userAcceptCallID == connectionHubId) {
                const sendMessageCheckAccepCall = async () => {
                    try {
                        await connectionHub.send(
                            'SendMessageAccepCall',
                            localStorage.getItem('connectionHubIdAcceptCall'),
                            userStartCallId,
                            callTransaction.userAcceptCallID,
                            callTransaction.callSessionID
                        );
                    } catch (err) {
                        // console.log('error :', err);
                    }
                };

                if (localStorage.getItem('connectionHubIdAcceptCall')) {
                    sendMessageCheckAccepCall();
                }
            }
        }
    }, [signalData, callTransaction, connectionHub, connectionHubId, connectionHubIdAcceptCall, userStartCallId]);

    const controlShareScreen = () => {
        setSalesAgentControlShareScreen(salesAgentControlShareScreen === false ? true : false);

        const sendMessageSalesAgentControlShareScreen = async () => {
            try {
                // console.log('salesAgentControlShareScreen ', salesAgentControlShareScreen);
                await connectionHub.send(
                    'SendStatusSalesAgentControlShareScreen',
                    connectionHubId,
                    salesAgentControlShareScreen
                );
            } catch (err) {
                // console.log('error :', err);
            }
        };

        if (connectionHub) {
            sendMessageSalesAgentControlShareScreen();
        }
    };

    // useEffect(() => {
    //     const sendMessageSalesAgentControlShareScreen = async () => {
    //         try {
    //             console.log('salesAgentControlShareScreen ', salesAgentControlShareScreen);
    //             await connectionHub.send('SendStatusSalesAgentControlShareScreen', connectionHubId, salesAgentControlShareScreen);
    //         }
    //         catch (err) {
    //             console.log('error :', err);
    //         }
    //     };

    //     if (connectionHub) {
    //         sendMessageSalesAgentControlShareScreen();
    //     }
    // }, [connectionHub, salesAgentControlShareScreen]);

    useEffect(() => {
        if (connectionHub) {
            (async () => {
                try {
                    await connectionHub.send(
                        'SendStatusIsWaitData',
                        connectionHubId,
                        userStartCallId,
                        isWaitData,
                        isPlayer
                    );
                } catch (err) {
                    // console.log('error :', err);
                }
            })();
        }
    }, [connectionHub, isWaitData, isPlayer]);

    useEffect(() => {
        const sendMessageCheckWaitDataToAcceptCall = async () => {
            try {
                await connectionHub.send(
                    'SendMessageWaitData',
                    localStorage.getItem('connectionHubIdAcceptCall'),
                    isWaitData,
                    isPlayer
                );
            } catch (err) {
                // console.log('error :', err);
            }
        };

        if (connectionHub && localStorage.getItem('connectionHubIdAcceptCall')) {
            sendMessageCheckWaitDataToAcceptCall();
        }
    }, [connectionHub, userStartCallId, isWaitData, isPlayer]);

    useEffect(() => {
        if (localStream.current != null) stopAudioOnly();
        function stopAudioOnly() {
            localStream.current.getAudioTracks().forEach(function (track) {
                track.enabled = enableAudio;
            });
        }
    }, [enableAudio]);

    useEffect(() => {
        if (localStream.current != null) stopVideoOnly();
        function stopVideoOnly() {
            localStream.current.getVideoTracks().forEach(function (track) {
                track.enabled = enableVideoWidget;
            });
        }
    }, [enableVideoWidget]);

    const replaceTrackStream = async (locStream) => {
        peerSaleAgent.current.replaceTrack(
            localStream.current.getVideoTracks()[0],
            locStream.getVideoTracks()[0],
            localStream.current
        );
        localStream.current.addTrack(locStream.getVideoTracks()[0]);
    };

    useEffect(() => {
        if (enableVideoWidget && localStream.current != null) {
            wefaceSwitchCamera();
        }
    }, [switchCamera]);

    function wefaceSwitchCamera() {
        const stopStream = async () => {
            await localStream.current.getTracks().forEach((track) => {
                track.stop();
            });
        };
        stopStream();

        utilService.initiateUserMediaLocalStream(switchCamera, enableVideoWidget).then(async (locStream) => {
            switchCameraRef.current = locStream;
            if (locStream) {
                let videohtml = localVideoRef.current;
                if (videohtml != null) {
                    if ('srcObject' in videohtml) {
                        videohtml.srcObject = locStream;
                        await replaceTrackStream(locStream);

                        // var localStreamCanvas = localCanvasRef.current.captureStream(10);
                        // await replaceTrackStream(localStreamCanvas);
                    } else {
                        await replaceTrackStream(locStream);

                        var localStreamCanvas = localCanvasRef.current.captureStream(10);
                        await replaceTrackStream(localStreamCanvas);
                        videohtml.src = window.URL.createObjectURL(localStreamCanvas);
                    }
                }
            }
        });
    }

    const ReplaceBlurVideoToCurrentTrack = async () => {
        let videohtml = localVideoRef.current;
        if (videohtml != null) {
            if ('srcObject' in videohtml) {
                var localStreamCanvas = localCanvasRef.current.captureStream(35);
                await replaceTrackStream(localStreamCanvas);
            }
        }
    }

    useEffect(() => {
        if (callControl != 'startcall') {
            clearInterval(blurbgLoopStart.current);
        } else {
            isBlur.current = blurBackgroundSaleAgent;
            clearInterval(blurbgLoopStart.current);

            if (!isCanvasRun) {
                try {
                    localVideoRef.current.srcObject = localStream.current;
                    localVideoRef.current.play();
                    localVideoRef.current.addEventListener('loadeddata', () => {
                        loadCanvasStart();
                        setIsCanVasRun(true)
                    });
                } catch (err) {
                    alert(`Following error occurred: ${err}`);
                }
            }
            ReplaceBlurVideoToCurrentTrack();
        }

    }, [blurBackgroundSaleAgent]);


    const loadCanvasStart = async () => {
        const options = {
            multiplier: 0.75,
            stride: 16,
            quantBytes: 2
        };
        const net = await bodyPix.load(options);
        perform(net);
    };


    async function perform(net) {
        while (localVideoRef.current) {
            const segmentation = await net.segmentPerson(localVideoRef.current);

            const backgroundBlurAmount = isBlur.current ? 8 : 0;
            const edgeBlurAmount = isBlur.current ? 20 : 0;
            const flipHorizontal = false;

            bodyPix.drawBokehEffect(
                localCanvasRef.current,
                localVideoRef.current,
                segmentation,
                backgroundBlurAmount,
                edgeBlurAmount,
                flipHorizontal
            );
        }
    }


    const clearSessionStorage = () => {
        sessionStorage.removeItem(SESSIONSTORAGE_NAME.CALL_STATUS);
        sessionStorage.removeItem(SESSIONSTORAGE_NAME.CALL_SESSION_ID);
        sessionStorage.removeItem(SESSIONSTORAGE_NAME.USER_ACCEPT_CALL_IFRAME);
        sessionStorage.removeItem(SESSIONSTORAGE_NAME.CALL_STATUS);
    };

    const acceptCall = async (myHub) => {
        appInsights.trackTrace({ message: 'Sales agent accept call ID : ' + connectionHubId });

        clearAllTimeout();
        setControlMedia(false);
        setEnableAudio(true);
        setEnableVideoWidget(true);

        const iceserverdata = await utilService.getStaticIceServer();

        const iceServers = [{ urls: iceserverdata.data.stunURI }];

        iceserverdata.data.turnServers.forEach((item) => {
            // console.log('added turn server', item);
            iceServers.push({
                urls: item.uris,
                username: item.username,
                credential: item.password
            });
        });

        utilService
            .initiateUserMediaLocalStream(switchCamera)
            .then(async (locStream) => {
                console.log('initiate user media locall stream', 'time: ' + moment().format('HH:mm:ss'));
                appInsights.trackTrace({ message: 'Sales agent stream.' });
                localStream.current = locStream;
                const peer2 = new Peer({
                    initiator: false,
                    trickle: false,
                    config: {
                        iceServers: iceServers
                    }
                });
                peerSaleAgent.current = peer2;
                peerSaleAgent.current.addStream(localStream.current);
                // console.log('check peer current in weface', peerSaleAgent.current);

                // if (localVideoRef.current == null) {
                //     setInterval(() => {
                //         console.log('check video ref', localVideoRef.current);
                //     }, 2000);
                // }

                let video = localVideoRef.current;
                let i = 0;

                if (video != null) {
                    if ('srcObject' in video) {
                        video.srcObject = localStream.current;
                    } else {
                        video.src = window.URL.createObjectURL(localStream.current);
                    }
                } else {
                    // alert("Video of a agent cannot use 'in' operator to search for 'srcObject' in null");
                }
                // console.log('Sales agent set video', video, 'time: ' + moment().format('HH:mm:ss'));

                if (userSignalData.current) {
                    if (i == 0) peerSaleAgent.current.signal(userSignalData.current);
                    i++;
                } else {
                    myHub.on('UserSignalData', (data) => {
                        // console.log('Sales agent signal data.', data, 'time: ' + moment().format('HH:mm:ss'));
                        appInsights.trackTrace({ message: 'Sales agent signal data.' });
                        if (i == 0) peerSaleAgent.current.signal(data.signalData);
                        i++;
                    });
                }

                peerSaleAgent.current.on('signal', (data) => {
                    waitForSignalTimeout.forEach((item) => {
                        clearTimeout(item);
                    });
                    setSignalData(data);
                    localStorage.setItem('signalData', data);
                    // console.log('peer on signal', data, 'time: ' + moment().format('HH:mm:ss'));
                });

                peerSaleAgent.current.on('stream', async (stream) => {
                    // console.log('peer on stream', 'time: ' + moment().format('HH:mm:ss'));
                    appInsights.trackTrace({ message: 'Sales agent stream succeed.' });
                    clearAllTimeout();
                    // sessionStorage.setItem(SESSIONSTORAGE_NAME.CALL_STATUS, CALL_STATUS.CALLING);
                    setCallStatus(CALL_STATUS.CALLING);
                    setCallingStatus(CALL_STATUS.READY);
                    setControlMedia(true);

                    let video2;
                    let videoRef;

                    if (!remoteVideoRef.current) {
                        videoRef = setInterval(async () => {
                            if (remoteVideoRef.current) {
                                video2 = await remoteVideoRef.current;
                                clearInterval(canvasRef);
                            }
                        }, 100);
                    } else {
                        video2 = await remoteVideoRef.current;
                    }

                    if (video2) {
                        if ('srcObject' in video2) {
                            video2.srcObject = stream;
                        } else {
                            video2.src = window.URL.createObjectURL(stream);
                        }
                    } else {
                        // alert("Video of a client cannot use 'in' operator to search for 'srcObject' in null");
                    }

                    if (salesAgentCallRecord) {
                        mediaRecorder.current.start();
                        // console.log('MediaRecorder start', salesAgentCallRecord);
                    }

                    setIsWaitData(true);
                });

                // try {
                //     await loadCanvasStart(false, false, 'startCall');
                // } catch (ex) {
                //     console.log('error load canvas :', ex);
                // }

                let canvasRef;
                if (!localCanvasRef.current) {
                    canvasRef = setInterval(async () => {
                        if (localCanvasRef.current) {
                            localStreamCanvas.current = await localCanvasRef.current.captureStream(35);
                            clearInterval(canvasRef);
                            setupVideoAndAudio(myHub);
                        }
                    }, 100);
                } else {
                    clearInterval(canvasRef);
                    localStreamCanvas.current = await localCanvasRef.current.captureStream(35);
                    setupVideoAndAudio(myHub);
                }
            })
            .catch((error) => {
                console.error('error', error);
                appInsights.trackException({ exception: error });
            });
    };

    async function setupVideoAndAudio(myHub) {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioStreamRef.current = audioStream;
        const canvas_track = localStreamCanvas.current.getVideoTracks()[0];
        const mic_track = audioStream.getAudioTracks()[0];
        let mergedStream = new MediaStream([canvas_track, mic_track]);

        mediaRecorder.current = new MediaRecorder(mergedStream);
        let chunks = [];
        mediaRecorder.current.ondataavailable = async (e) => {
            chunks.push(e.data);
        };

        mediaRecorder.current.onstop = (e) => {
            // console.log('MediaRecorder onstop', mediaRecorder.state);
            let blob = new Blob(chunks, { type: 'audio/ogg; codecs=opus' });
            chunks = [];

            const formData = new FormData();
            formData.append('blob', blob);
            formData.append('callSessionID', callSessionID);
            formData.append('connectionHubId', connectionHubId);

            setCallControl('recordAudio');
            utilService.uploadAudio(formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then((result) => {
                setCallControl('endcall');
                if (result.errors) {
                    // console.log('error : ', result.errors);
                }
            });
        };

        peerSaleAgent.current.on('close', () => {
            // console.log('close sale agent');
            setIsWaitData(false);
            setIsPlayer(false);
            setIsMaximizeWidgetVideo(false);
            waitForSignalTimeout.forEach((item) => {
                clearTimeout(item);
            });
            waitForSalesAgentReconnect.push(
                setTimeout(() => {
                    clearSessionStorage();
                    setCallControl('endcall');
                }, sessionStorage.getItem('timeoutSaleAgent'))
            );

            if (mediaRecorder.current.state != 'inactive') {
                mediaRecorder.current.stop();
            }

            navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
                mediaStream.getTracks().forEach((track) => {
                    track.stop();
                });
            });
            audioStream.getTracks().forEach((track) => {
                track.stop();
            });
            if (blurbgLoopStart.current) {
                clearInterval(blurbgLoopStart.current);
            }
        });

        peerSaleAgent.current.on('error', (err) => {
            console.error('error', err);
            waitForSignalTimeout.forEach((item) => {
                clearTimeout(item);
            });
        });

        myHub.on('EndCallRequest', (message) => {
            // temporary solution
            window.location.reload();

            setIsWaitData(false);
            setIsPlayer(false);
            setIsMaximizeWidgetVideo(false);
            appInsights.trackTrace({ message: 'Sale agent end call.' });

            clearSessionStorage();
            // navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((mediaStream) => {
            //     mediaStream.getTracks().forEach((track) => {
            //         track.stop();
            //     });
            // });
            audioStream.getTracks().forEach((track) => {
                track.stop();
            });

            if (switchCameraRef.current != null) {
                switchCameraRef.current.getTracks().forEach((track) => {
                    track.stop();
                });
            }

            if (blurbgLoopStart.current != null) {
                clearInterval(blurbgLoopStart.current);
            }

            if (peerSaleAgent.current != undefined) {
                peerSaleAgent.current.destroy();
                // console.log('Sale agent clear peer succeed');
            }

            setCallControl('endcall');
            setCallTransaction(null);
            setSignalData(null);
            clearAllTimeout();
            userSignalData.current = null;

            if (mediaRecorder.current.state != 'inactive') {
                mediaRecorder.current.stop();
            }


        });
    }

    useEffect(() => {
        if (callControl == 'startcall' && userStartCallId) {
            (async () => {
                try {
                    if (connectionHub)
                        await connectionHub.send(
                            'SendStatusSalesAgentAcceptCall',
                            connectionHubId,
                            userStartCallId,
                            true,
                            userContext && userContext.name
                                ? userContext.name === ''
                                    ? userContext.email
                                    : userContext.name
                                : '',
                            profileUrl
                        );
                } catch (err) {
                    // console.log('error :', err);
                }
            })();
        }
    }, [connectionHub, callControl, userStartCallId, userContext, profileUrl]);

    const callRequest = async (event) => {
        switch (event) {
            case 'acceptcall':
                clearAllTimeout();
                await setCallControl('startcall');
                if (isPlaySound) {
                    setPlaying(false);
                }

                await acceptCall(connectionHub);
                await acceptCallerStream();

                break;
            case 'endcall':
                window.location.reload();

                setIsIFrame(false);
                clearAllTimeout();
                setCallControl('endcall');
                setIsPlayer(false);
                if (callType === 'ServiceCall') {
                    handleCloseAlert();
                }
                if (connectionHub && callStatus == CALL_STATUS.CALLING) {
                    if (callSessionID) {
                        await connectionHub.send('SendEndCallRequest', callSessionID);
                    } else {
                        await connectionHub.send('SendCancelCallRequest', connectionHubId);
                    }
                    return;
                } else if (callstatus == CALL_STATUS.WAIT) {
                    setCallControl('endcall');
                    // sessionStorage.setItem(SESSIONSTORAGE_NAME.CALL_STATUS, CALL_STATUS.READY);
                    sessionStorage.setItem(SESSIONSTORAGE_NAME.CALL_STATUS, null);
                } else if (callstatus == null) {
                    await connectionHub.send('SendCancelCallRequest', connectionHubId);
                }

                audioStreamRef.current?.getTracks().forEach((track) => {
                    track.stop();
                });
                localVideoRef.current?.getTracks().forEach((track) => {
                    track.stop();
                });

                break;
            case 'dismiss':
                setCallControl('endcall');
                if (isPlaySound) {
                    setPlaying(false);
                }
                break;
            default:
                break;
        }
    };

    const acceptCallerStream = async () => {
        if (loginContext.access_token != null) {
            await connectionHub.send('AcceptCallRequest', connectionHubId, loginContext.access_token, UsersId);
        }
    };

    useEffect(() => {
        if (window.location.pathname != '/live') {
            setDisplayReplayer('none');
        } else {
            setDisplayReplayer();
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (isMaximizeWidgetVideo) {
            document.getElementsByClassName('interactis-video-handle')[0].style.transform = 'unset';
        }
    }, [isMaximizeWidgetVideo]);

    useEffect(() => {
        if (innerWidth <= 640) {
            setStyleMarginTopForShareScreen('25px');
            if (isMaximizeWidgetVideo) {
                setStyleOverflowForSpaceVideo('hidden');
                if (isMinimizeWidgetVideo) {
                    setStylePositionVideo('fixed');
                } else {
                    setStylePositionVideo('');
                }
            } else {
                setStyleOverflowForSpaceVideo('');
                if (isMinimizeWidgetVideo) {
                    setStylePositionVideo('fixed');
                } else {
                    setStylePositionVideo('');
                }
            }
        } else {
            setStyleMarginTopForShareScreen('');
            setStyleOverflowForSpaceVideo('');
            setStylePositionVideo('');
        }
    }, [innerWidth, isMaximizeWidgetVideo, isMinimizeWidgetVideo]);

    switch (callControl) {
        case 'comingcall':
            return (
                <>
                    {permissions?.canAcceptCall ? (
                        <InteractisComingCall callRequest={callRequest} enableVideo={true} enableAudio={true} />
                    ) : (
                        <></>
                    )}
                </>
            );
            break;
        case 'startcall':
            return (
                <>
                    {isIFrame ? (
                        <>
                            <div className="p-px" onClick={() => handCloseIFrame()}>
                                <div
                                    className="interactis-button-rrweb-stop"
                                    onClick={() => handCloseIFrame()}
                                    title="Stop screen sharing"></div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {/* <Draggable handle=".interactis-video-handle " disabled={disabledDraggable} > */}
                    <div>
                        <div
                            className="interactis-video-handle widgetVideo_1"
                            style={{
                                // visibility: isMinimizeWidgetVideo ? 'hidden' : 'unset',
                                // right: isMaximizeWidgetVideo ? '0' : '0',
                                // left: isMaximizeWidgetVideo ? 'calc(50% - 0px)' : 'calc(100% - 375px)',
                                top: isMaximizeWidgetVideo ? 'unset' : '',
                                position: stylePositionVideo
                            }}>
                            <div className="video-container">
                                <div className="remote-video">
                                    <InteractisVideoInterface
                                        typeUser="client"
                                        VideoRef={remoteVideoRef}
                                        controlAudio={false}
                                        muted={false}
                                        webcamEnabled={callingStatus}
                                        videoSalesAgent={false}
                                        isMaximizeWidgetVideo={isMaximizeWidgetVideo}
                                        setIsMaximizeWidgetVideo={setIsMaximizeWidgetVideo}
                                        innerWidth={innerWidth}
                                    />
                                </div>
                                <div className="local-video">
                                    <InteractisVideoInterface
                                        typeUser="salesAgent"
                                        VideoRef={localVideoRef}
                                        controlAudio={controlMedia}
                                        setEnableAudio={setEnableAudio}
                                        enableAudio={enableAudio}
                                        setEnableVideoWidget={setEnableVideoWidget}
                                        enableVideoWidget={enableVideoWidget}
                                        muted={true}
                                        webcamEnabled={'ready'}
                                        callRequest={callRequest}
                                        statusEndCall={true}
                                        setSwitchCamera={setSwitchCamera}
                                        switchCamera={switchCamera}
                                        CanvasRef={localCanvasRef}
                                        setBlurBackgroundSaleAgent={setBlurBackgroundSaleAgent}
                                        blurBackgroundSaleAgent={blurBackgroundSaleAgent}
                                        videoRefHeightClientRect={videoRefHeightClientRect}
                                        videoSalesAgent={true}
                                        isMinimizeWidgetVideo={isMinimizeWidgetVideo}
                                        setIsMinimizeWidgetVideo={setIsMinimizeWidgetVideo}
                                        isWaitData={isWaitData}
                                        isPlayer={isPlayer}
                                        isMaximizeWidgetVideo={isMaximizeWidgetVideo}
                                        setIsMaximizeWidgetVideo={setIsMaximizeWidgetVideo}
                                        innerWidth={innerWidth}
                                    />
                                </div>
                                <OptionWidgetVideo
                                    setEnableAudio={setEnableAudio}
                                    enableAudio={enableAudio}
                                    setEnableVideoWidget={setEnableVideoWidget}
                                    enableVideoWidget={enableVideoWidget}
                                    callRequest={callRequest}
                                    setBlurBackgroundSaleAgent={setBlurBackgroundSaleAgent}
                                    blurBackgroundSaleAgent={blurBackgroundSaleAgent}
                                    setIsMinimizeWidgetVideo={setIsMinimizeWidgetVideo}
                                    isMinimizeWidgetVideo={isMinimizeWidgetVideo}
                                    statusShareScreenClient={statusShareScreenClient}
                                    setSalesAgentControlShareScreen={setSalesAgentControlShareScreen}
                                    salesAgentControlShareScreen={salesAgentControlShareScreen}
                                    setSwitchCamera={setSwitchCamera}
                                    switchCamera={switchCamera}
                                    isWaitData={isWaitData}
                                    isPlayer={isPlayer}
                                    isVideoCallReady={isVideoCallReady}
                                    controlShareScreen={controlShareScreen}
                                    isMaximizeWidgetVideo={isMaximizeWidgetVideo}
                                    setIsMaximizeWidgetVideo={setIsMaximizeWidgetVideo}
                                    innerWidth={innerWidth}
                                />
                            </div>
                        </div>

                        <ChatBox connectionHubOn={acceptCall} />
                    </div>
                    <div className=" relative h_72 sm:hidden"></div>
                    {permissions && isOpenAlert && callType != 'ServiceCall' && (
                        <InteractisAlert
                            serviceCall={serviceCall}
                            handleCloseAlert={handleCloseAlert}
                            acceptCall={() => callRequest('acceptcall')}
                            callControl={callControl}
                            confirmCancelServiceCall={confirmCancelServiceCall}
                        />
                    )}

                    {isWaitData && isPlayer && isMinimizeWidgetVideo ? (
                        <>
                            <div
                                className="blockClassRecordrrweb sm:block interactis_button_minimizeWidgetVideo"
                                title="Minimize"
                                onClick={() => handleMinimizeWidgetVideo()}
                                style={{ right: isIFrame ? '25px' : '110px' }}></div>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            );
            break;
        case 'comingcallfromcode':
            return (
                <>
                    {permissions?.canAcceptCall ? (
                        <InteractisAlert
                            serviceCall={serviceCall}
                            handleCloseAlert={handleCloseAlert}
                            acceptCall={() => callRequest('acceptcall')}
                            callControl={callControl}
                            confirmCancelServiceCall={confirmCancelServiceCall}
                        />
                    ) : (
                        <></>
                    )}
                </>
            );
        case 'recordAudio':
            return <InteractisUploadRecordAudio />;
        default:
            break;
    }

    return (
        <>
            {permissions ? (
                <>
                    {isOpenAlert && callType != 'ServiceCall' && (
                        <InteractisAlert
                            serviceCall={serviceCall}
                            handleCloseAlert={handleCloseAlert}
                            acceptCall={() => callRequest('acceptcall')}
                            callControl={callControl}
                            confirmCancelServiceCall={confirmCancelServiceCall}
                        />
                    )}
                    <iframe src={SilenceSound} allow="autoplay" id="audio" style={{ display: 'none' }}></iframe>

                    {/* <DeleteDialog
                        action={pendingDeleteAction}
                        handleDelete={cancelServiceCall}
                        title="Cancel Service Call?"
                        body="Are you sure you want to cancel this service call with all its data? Once cancel it cannot be undone."
                    /> */}
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default InteractisVideo;
