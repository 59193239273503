import moment from 'moment';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ViewTextCallRecord from '../../insight-view-text';
import { useServices } from 'services';
import { IsaleAgentTextConversation } from 'interfaces';
import { PageSpinner } from 'components';
import { toast } from 'react-toastify';
import { replaceMessages, toastProperty } from 'utils/help';
import { MESSAGES_ALERT, USER_TYPE } from 'utils/constants';

const SaleAgentConvertToText = (props: IsaleAgentTextConversation) => {
    const { api } = useServices();

    const [dateCallRecordCard, setDateCallRecordCard]: any = useState(
        moment(props.dateCallRecordCard).format('DD MMM  YYYY')
    );
    const [showModalViewTextCallRecord, setShowModalViewTextCallRecord]: any = useState(false);
    const [loadingConvertText, setLoadingConvertText]: any = useState(false);
    const [loadingSentiment, setLoadingSentiment]: any = useState(false);

    const [buttonStatusConvertText, setButtonStatusConvertText]: any = useState(true);
    const [buttonStatusViewText, setButtonStatusViewText]: any = useState(true);
    const [buttonStatusSentiment, setButtonStatusSentiment]: any = useState(true);

    const [statusConvertText, setStatusConvertText]: any = useState();
    const [statusSentiment, setStatusSentiment]: any = useState();

    const handleViewTextCallRecord = () => {
        setShowModalViewTextCallRecord(true);
    };
    const handleModalClose = () => {
        setShowModalViewTextCallRecord(false);
    };

    useEffect(() => {
        if (props.salesAgentStatusRecord != 0 && props.salesAgentStatusConvertText == 0) {
            setButtonStatusConvertText(false);
        }
        if (props.salesAgentStatusConvertText == 1 || statusConvertText == 1) {
            setLoadingConvertText(true);
            setButtonStatusConvertText(true);
            setButtonStatusViewText(true);
            setButtonStatusSentiment(true);
        }
        if (props.salesAgentStatusConvertText == 2 || statusConvertText == 2) {
            setLoadingConvertText(false);
            setButtonStatusConvertText(true);
            setButtonStatusViewText(false);
            if (props.salesAgentStatusSentimentAnalysis == 0) {
                setButtonStatusSentiment(false);
            }
        }
        if (props.salesAgentStatusConvertText == 4) {
            setLoadingConvertText(false);
            setButtonStatusConvertText(false);
        }
        if (props.salesAgentStatusConvertText != 4 && statusConvertText == 4) {
            setLoadingConvertText(false);
            setButtonStatusConvertText(false);
            alertMessageError('Sale Agent');
        }

        if (props.salesAgentStatusConvertText == 0 || props.salesAgentStatusConvertText == 1) {
            const interval = setInterval(() => {
                api.insight.getCallRecord(props.id).then((result) => {
                    setStatusConvertText(result.data.salesAgentStatusConvertText);
                    if (result.data.salesAgentStatusConvertText == 2) {
                        setLoadingConvertText(false);
                        setButtonStatusConvertText(true);
                        setButtonStatusViewText(false);
                        if (result.data.salesAgentStatusSentimentAnalysis == 0) {
                            setButtonStatusSentiment(false);
                        }
                        clearInterval(interval);
                    }
                    if (result.data.salesAgentStatusConvertText == 4) {
                        setLoadingConvertText(false);
                    }
                    if (result.data.salesAgentStatusConvertText == 1) {
                        setLoadingConvertText(false);
                    }
                });
            }, 5000);
            if (statusConvertText == 2) {
                clearInterval(interval);
            }
        }
    }, [loadingConvertText, statusConvertText, props.salesAgentStatusConvertText]);

    useEffect(() => {
        if (props.salesAgentStatusConvertText == 2 && props.salesAgentStatusSentimentAnalysis == 0) {
            setButtonStatusSentiment(false);
        }
        if (props.salesAgentStatusSentimentAnalysis == 1 || statusSentiment == 1) {
            setLoadingSentiment(false);
            setButtonStatusSentiment(false);
        }
        if (props.salesAgentStatusSentimentAnalysis == 2 || statusSentiment == 2) {
            setLoadingSentiment(false);
            setButtonStatusSentiment(true);
        }
        if (props.salesAgentStatusSentimentAnalysis == 4) {
            setLoadingSentiment(false);
            setButtonStatusSentiment(false);
        }
        if (props.salesAgentStatusSentimentAnalysis != 4 && statusSentiment == 4) {
            setLoadingSentiment(false);
            setButtonStatusSentiment(false);
            alertMessageError('Sale Agent');
        }
        if (props.salesAgentStatusSentimentAnalysis == 0 || props.salesAgentStatusSentimentAnalysis == 1) {
            const interval = setInterval(() => {
                api.insight.getCallRecord(props.id).then((result: any) => {
                    setStatusSentiment(result.data.salesAgentStatusSentimentAnalysis);
                    if (result.data.salesAgentStatusSentimentAnalysis == 1) {
                        setButtonStatusSentiment(true);
                    }
                    if (result.data.salesAgentStatusSentimentAnalysis == 2) {
                        clearInterval(interval);
                        setLoadingSentiment(false);
                        setButtonStatusSentiment(true);
                        props.setSalesAgentStatusSentimentAnalysis(result.data.salesAgentStatusSentimentAnalysis);

                        let sentimentStr = result.data.salesAgentSentimentAnalysis;
                        let sentimentObj = JSON.parse(sentimentStr);

                        let sentimentPositive: any = parseFloat(sentimentObj.SentimentPositive);
                        let sentimentPositiveResult: any = sentimentPositive * 100;

                        let sentimentNeutral: any = parseFloat(sentimentObj.SentimentNeutral);
                        let sentimentNeutralResult: any = sentimentNeutral * 100;

                        let sentimentNegative: any = parseFloat(sentimentObj.SentimentNegative);
                        let sentimentNegativeResult: any = sentimentNegative * 100;

                        props.setSeriesChartDonutPositiveSalesAgent(
                            Number(parseFloat(sentimentPositiveResult).toFixed(0))
                        );
                        props.setSeriesChartDonutNeutralSalesAgent(
                            Number(parseFloat(sentimentNeutralResult).toFixed(0))
                        );
                        props.setSeriesChartDonutNegativeSalesAgent(
                            Number(parseFloat(sentimentNegativeResult).toFixed(0))
                        );
                    }
                    if (result.data.salesAgentStatusSentimentAnalysis == 4) {
                        setLoadingSentiment(false);
                    }
                });
            }, 5000);
            if (statusSentiment == 2) {
                clearInterval(interval);
            }
        }
    }, [loadingSentiment, statusSentiment, props.salesAgentStatusSentimentAnalysis]);

    const handleConvertText = async (event: any) => {
        if (props.salesAgentStatusConvertText == 4 && statusConvertText == 4) {
            alertMessageError('Sale Agent');
            setLoadingConvertText(false);
        } else {
            setLoadingConvertText(true);
            event.preventDefault();

            let valueQueuesObj = {
                CallQueuesId: props.callQueuesId,
                UsersType: USER_TYPE.SALES_AGENT
            };
            let valueQueues = JSON.stringify(valueQueuesObj);
            let zero: any = 0;
            const formData = new FormData();
            formData.append('CreatedDate', props.createDateActionQueues);
            formData.append('UpdatedDate', props.updateDateActionQueues);
            formData.append('Action', zero);
            formData.append('CallQueuesId', props.callQueuesId);
            formData.append('Value', valueQueues);
            formData.append('Status', zero);

            await api.insight
                .actionQueues(formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .catch((err) => {
                    alertMessageError('Sale Agent');
                });
        }
    };

    const alertMessageError = (userType: string) => {
        toast.error(
            replaceMessages(MESSAGES_ALERT.CONVERT_SOUND_FAILED, userType),
            toastProperty('top-left', 2000, false, true, false, true, undefined, 'light')
        );
    };

    return (
        <>
            {loadingConvertText && <PageSpinner />}
            {loadingSentiment && <PageSpinner />}
            <div className="card">
                <div className="card-body">
                    <h4>Sales Agent Text Conversation</h4>
                    <span>Record Date: {dateCallRecordCard === 'invalid date' ? '-' : dateCallRecordCard} </span>
                    <p>Download text conversation</p>

                    <div className="button-group">
                        <button
                            className="btn btn-primary btn-sm"
                            disabled={buttonStatusConvertText}
                            onClick={handleConvertText}>
                            Convert
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            disabled={buttonStatusViewText}
                            onClick={handleViewTextCallRecord}>
                            View
                        </button>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            disabled={buttonStatusSentiment}
                            onClick={handleSentiment}>
                            Analysis
                        </button> */}
                    </div>
                </div>
            </div>
            <Modal
                show={showModalViewTextCallRecord}
                onHide={() => setShowModalViewTextCallRecord(false)}
                animation={false}
                onClose={handleModalClose}>
                <ViewTextCallRecord clientConvertText={null} salesAgentConvertText={props.salesAgentConvertText} />
            </Modal>
        </>
    );
};

export default SaleAgentConvertToText;
