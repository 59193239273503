import { IbuttonSwitch } from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import './style.scss'
import { generateUUID } from 'utils/help';
const ButtonToggle = (prop: IbuttonSwitch) => {
    const getIsChecked = prop.defaultChecked ? true : false;
    const [checked, setChecked] = useState<boolean>(getIsChecked);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const resultChecked = useRef<HTMLInputElement>(null)

    const key = generateUUID();

    useEffect(()=>{
            setChecked(getIsChecked);
            setIsLoading(false);
    },[getIsChecked]);

    useEffect(()=>{
        prop.onToggle(checked);
     },[checked]);
  
    return (
        <div className={`custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline button-toggle ${isLoading ? 'd-none' : ''}`}>
            <div className="custom-switch-inner">
                <input
                    type="checkbox"
                    className={`custom-control-input bg-dark `}
                    id={`customSwitch-${key}`}
                    onChange={()=> setChecked(!checked)}
                    checked ={checked}
                    ref={resultChecked}
                />
                <label
                    className="custom-control-label"
                    htmlFor={`customSwitch-${key}`}
                    // data-on-label={prop.onLabel ? prop.onLabel : ''}
                    // data-off-label={prop.offLabel ? prop.offLabel : ''}
                ></label>
            </div>
            <p className="lable-switch-off-on" data-on-label="On" data-off-label="Off"></p>
        </div>
    );
};

export default ButtonToggle;


// import { IbuttonSwitch } from 'interfaces';
// import { useCallback, useEffect, useRef, useState } from 'react';
// import './style.scss';
// import { generateUUID } from 'utils/help';

// const ButtonToggle = (props: IbuttonSwitch) => {
//     const { initialValue, onLabel, offLabel, onToggle } = props;

//     const [isChecked, setIsChecked] = useState<boolean>();
//     const [isLoading, setIsLoading] = useState<boolean>(true);

//     const key = generateUUID();

//     const handleToggle = () => {
//         console.log('handleToggle', !isChecked)
//         setIsChecked(!isChecked);
//         onToggle(!isChecked); // Call the onToggle prop to update the state in the parent component
//     };


//     useEffect(() => {
//         if (initialValue !== undefined) {
//             setIsLoading(false);
//             setIsChecked(initialValue);
//         }
//     }, [initialValue]);

//     return (
//         <div
//             className={`custom-control custom-switch custom-switch-text custom-switch-color custom-control-inline button-toggle ${isLoading ? 'd-none' : ''}`}>
//             <div className="custom-switch-inner">
//                 {` initialValue: ` +  JSON.stringify(initialValue)}
//                 {' isChecked: ' + JSON.stringify(isChecked)}
//                 <input
//                     type="checkbox"
//                     className={`custom-control-input bg-dark `}
//                     id={`customSwitch-${key}`}
//                     checked={isChecked}
//                     onChange={handleToggle}
//                 />
//                 <label
//                     className="custom-control-label"
//                     htmlFor={`customSwitch-${key}`}
//                     data-on-label={onLabel ? onLabel : ''}
//                     data-off-label={offLabel ? offLabel : ''}></label>
//             </div>
//             <p className="lable-switch-off-on" data-on-label="On" data-off-label="Off"></p>
//         </div>
//     );
// };

// export default ButtonToggle;
