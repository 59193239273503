
interface ICallDetails {
    saleAgentName : string;
    clientName : string;
    dateCallRecord : string;
    startTimeCallRecord : string;
    endTimeCallRecord : string;
}
const CallDetails = (props: ICallDetails) =>{

    return (
        <>
        <div className="card">
            <div className="card-body">
                <table className="table-call-info">
                    <tbody>
                        <tr>
                            <td>Sales Agent:</td>
                            <td>{props.saleAgentName}</td>
                        </tr>
                        <tr>
                            <td>Client Name:</td>
                            <td>{props.clientName}</td>
                        </tr>
                        <tr>
                            <td>Date:</td>
                            <td>{props.dateCallRecord}</td>
                        </tr>
                        <tr>
                            <td>Start Time:</td>
                            <td>{props.startTimeCallRecord !== "Invalid date" ? props.startTimeCallRecord : "-"}</td>
                        </tr>
                        <tr>
                            <td>End Time:</td>
                            <td>{props.endTimeCallRecord !== "Invalid date"? props.endTimeCallRecord : "-"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </>
    )
}

export default CallDetails;