import { ButtonGroup } from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { useState } from 'react';
import { randomStr } from 'utils/help';

const CustomComponents: React.FC = () => {
    const [items] = useState([
        { text: 'item 1' },
        { text: 'item 2' },
        { text: 'item 3' },
        { text: 'item 4' },
    ]);

    const onChange = item => {
        console.log('selected item', item)
    }

    return (
        <div>
            <NavbarTop />
            <NavbarLeft />
            <div className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card">
                                <div className="card-body">
                                    <ButtonGroup items={items} onChange={onChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CustomComponents;
