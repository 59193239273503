enum TriggerPosition {
    LEFT = 0,
    LEFT_UP = 1,
    RIGHT = 2,
    RIGHT_UP = 3
}

const baseColors = ['#c5c5c5', '#28c66f', '#ffab00', '#ec5453', '#6259ce'];

export { TriggerPosition, baseColors };
